import "amazon-connect-streams";
import "amazon-connect-chatjs";
import "amazon-connect-taskjs";

import {
  CONTACT_STATE_MAP,
  CCP_STATES,
  CCP_STATES_INDEX,
  LOG_PREFIX,
  STOP_SESSION,
  CCP_INSTANCE,
} from "../../constants/constants";

import { Call } from "../models/call";
import { subscribeToAgentEvents } from "./agent";
import { subscribeToContactEvents } from "./contact";
import { Voicemail } from "../models/voicemail";
import { getAwsRegion, getCCPUrl } from "../../config";
import Emitter from "../../emitter";
import { CCP_STATE, CONNECT_STREAMS_DIV_ID, STOP_CCP } from "../constants";
import { TELEMETRY_INFO, TELEMETRY_ERROR, INIT_CCP, FAIL_SESSION } from "../../constants/events";
import { addCCPProfile } from "../../api/session-api";
const lp = LOG_PREFIX + " [handlers.session.ts]: ";

class AmazonConnectSession {
  agent?: any;
  contact?: any;
  currCCPstate: number = -1;
  call: Call | null = null;
  voicemail: Voicemail | null = null;
  static instance: any;
  ccpUrl: string = "";
  region: string = "";
  instanceAlias: string = "";

  constructor() {
    this.initiateCcpSession = this.initiateCcpSession.bind(this);
    this.terminatCcpSession = this.terminatCcpSession.bind(this);
    this.getCall = this.getCall.bind(this);
    this.setCall = this.setCall.bind(this);
    this.getVoicemail = this.getVoicemail.bind(this);
    this.setVoicemail = this.setVoicemail.bind(this);
    this.logoutFromConnect = this.logoutFromConnect.bind(this);
    this.terminatCcpInstanceSession = this.terminatCcpInstanceSession.bind(this);
    this.updateCCPstateToBg = this.updateCCPstateToBg.bind(this);
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new AmazonConnectSession();
    }
    return this.instance;
  }

  async initiateCcpSession(params: { ccpUrl: string; region: string, instanceAlias: any }) {
    this.ccpUrl = params.ccpUrl;
    this.region = params.region;
    this.instanceAlias = params.instanceAlias;
    console.log(
      lp +
        "ccp start event called, current ccp state is = " +
        CCP_STATES_INDEX[this.currCCPstate]
    );
    if (this.currCCPstate !== CCP_STATES.CONNECTED) {
      this.updateCCPstateToBg(CCP_STATES.OPEN);
      // initialize the ccp
      try {
        const containerDiv = document.getElementById(CONNECT_STREAMS_DIV_ID) as HTMLElement;
        console.log(
          lp + "Container div for initiating ccp connection ",
          containerDiv
        );
        if (
          ![CCP_STATES.CONNECTING, CCP_STATES.CONNECTED].includes(
            this.currCCPstate
          )
        ) {
          await addCCPProfile();
          this.updateCCPstateToBg(CCP_STATES.CONNECTING);

          connect.core.initCCP(containerDiv, {
            ccpUrl: params.ccpUrl || getCCPUrl(), // REQUIRED
            loginPopup: false, // optional, defaults to `true`
            loginPopupAutoClose: true, // optional, defaults to `false`
            region: params.region || getAwsRegion(), // REQUIRED for `CHAT`, optional otherwise
            softphone: {
              // optional, defaults below apply if not provided
              allowFramedSoftphone: true, // optional, defaults to false
              disableRingtone: true, // optional, defaults to false
              //ringtoneUrl: "./ringtone.mp3" // optional, defaults to CCP’s default ringtone if a falsy value is set
            },
            chat: {
              disableRingtone: true,
            },
            task: {
              disableRingtone: true,
            },
            ccpAckTimeout: 5000, //optional, defaults to 3000 (ms)
            ccpSynTimeout: 3000, //optional, defaults to 1000 (ms)
            ccpLoadTimeout: 10000, //optional, defaults to 5000 (ms)
          });

          connect.core.onInitialized(() => {
            console.log(lp + "authorization succeeded!");
            this.updateCCPstateToBg(CCP_STATES.CONNECTED);
            this.updateCurrentCCPInstanceToBg(params.instanceAlias)
          });
          connect.core.onAuthFail(() => {
            console.log(lp + "Error!!!!!: Session expired! Need to login");
            this.updateCCPstateToBg(CCP_STATES.DISCONNECTED);
          });
          connect.core.onAuthorizeRetriesExhausted(() => {
            console.log(
              lp +
                "Error!!!!!: We have run out of retries to reload the CCP Iframe"
            );
            this.updateCCPstateToBg(CCP_STATES.CLOSED);
          });
          connect.core.onAccessDenied(() => {
            console.log(lp + "Error!!!!!: Access denied");
            this.updateCCPstateToBg(CCP_STATES.CLOSED);
          });
          connect.core.onIframeRetriesExhausted(() => {
            console.log(
              lp +
                "Error!!!!!: We have run out of retries to reload the CCP Iframe"
            );
            console.log(lp + "Logging out due to expired cookies");
            this.updateCCPstateToBg(CCP_STATES.CLOSED);
            //TODO: remove this later for now adding this so that it doesn't stuck
            Emitter.emit(FAIL_SESSION, "Session expired! Need to login");
          });
          connect.core.onCTIAuthorizeRetriesExhausted(() => {
            console.log(
              lp +
                "Error!!!!!: We have failed CTI API authorization multiple times and we are out of retries"
            );
            this.updateCCPstateToBg(CCP_STATES.CLOSED);
          });
          connect.contact(subscribeToContactEvents);
          connect.agent(subscribeToAgentEvents);
        } else {
          console.log(
            lp +
              "ccp start event called, But current ccp state is already in progress = " +
              CCP_STATES_INDEX[this.currCCPstate]
          );
        }
      } catch (error: any) {
        console.log(
          lp + "Error in starting ccp, Error: " + error.message,
          error
        );
        console.log(
          lp +
            "Error in starting ccp, state is " +
            CCP_STATES_INDEX[this.currCCPstate]
        );
        this.updateCCPstateToBg(CCP_STATES.CLOSED);
        Emitter.emit(TELEMETRY_ERROR, {
          type: "notif",
          chan: TELEMETRY_INFO,
          body: {
            type: INIT_CCP,
            info:
              lp +
              "Error in starting ccp, state is " +
              CCP_STATES_INDEX[this.currCCPstate],
          },
        });
      }
    } else {
      this.updateCCPstateToBg(this.currCCPstate);
    }
  }

  terminatCcpSession() {
    connect.core.terminate();
    this.logoutFromConnect();
  }
  terminatCcpInstanceSession() {
    this.terminatCcpSession();
    const containerDiv = document.getElementById(CONNECT_STREAMS_DIV_ID) as HTMLElement;
    var iframe = containerDiv.firstElementChild; // assumes there's nothing else in the container
    console.log(lp + "iframe", iframe);
    if (iframe) {
      containerDiv.removeChild(iframe);
    }
    const ccpIframe = document.querySelector('iframe[title="Amazon Connect CCP"]');
    if (ccpIframe) {
        ccpIframe.parentNode?.removeChild(ccpIframe);
        console.log('CCP iframe removed successfully');
    } else {
        console.log('No CCP iframe found');
    }
    this.updateCCPstateToBg(CCP_STATES.CLOSED);
  }

  logoutFromConnect() {
    fetch(
      `${
        (this.ccpUrl ||
        getCCPUrl()).replace("ccp-v2", "logout")
      }`,
      {
        credentials: "include",
        mode: "no-cors",
      }
    )
      .then(() => {
        console.log(lp + "Logout successfull from connect");
      })
      .catch((error) =>
        console.log(lp + "Error in loggin out from connect", error)
      );
  }
  updateCCPstateToBg(status: number, currentCcpInstance?: string) {
    this.currCCPstate = status;
    Emitter.emit(CCP_STATE, { status: status, currentCcpInstance: currentCcpInstance});
  }
  updateCurrentCCPInstanceToBg(instanceAlias: string) {
    Emitter.emit(CCP_INSTANCE, { body: {currentCcpInstance: instanceAlias } });
  }
  getCall() {
    return this.call;
  }
  setCall(call: any) {
    //Below ignore duplicate call ended events
    if (call.self.state === CONTACT_STATE_MAP.ENDED && !this.call?.id) return;

    if (call.self.state === CONTACT_STATE_MAP.ENDED) {
      this.call = null;
    } else {
      this.call = call;
    }
  }
  getVoicemail() {
    return this.voicemail;
  }
  setVoicemail(voicemail: any) {
    //Below ignore duplicate call ended events
    if (voicemail.self.state === CONTACT_STATE_MAP.ENDED && !this.voicemail?.id)
      return;

    if (voicemail.self.state === CONTACT_STATE_MAP.ENDED) {
      this.voicemail = null;
    } else {
      this.voicemail = voicemail;
    }
  }
}
export const connectSession = AmazonConnectSession.getInstance();

/**
 * Register default Events for Call
 */
export const registerSessionHandlers = () => {
  Emitter.on(STOP_CCP, connectSession.terminatCcpInstanceSession);
  Emitter.on(INIT_CCP, connectSession.initiateCcpSession);
};
