export const CONTENT_SCRIPT_NAME = "contentscript";
export const WT_CONTENT_SCRIPT_NAME = "wt_contentscript";
export const AIC_CCP_CLIENT_SCRIPT = "aic_ccp_client_script";
export const CRM_SCRIPT_NAME = "crm-injector";
export const AGENT_AFTER_CALL_WORK_STATE_ID = "after_call_work_id";
export const NETWORK_DISCONNECT_RECONNECT =
  "Network error trying to reconnect...";
export const NETWORK_DISCONNECT_LOGOUT = "Network error trying to reconnect...";
export const AIL_HEALTH_MSG =
  "Ensure that VPN and internet connection is stable";
export const AIL_HEALTH_TITLE = "Connection problem";
export const LOGOUT_ERROR_MSG =
  "There is a network issue, please close your browser and relogin after 60 sec.";
export const LOGOUT_ERROR_TITLE = "Network error";
export const ATTACHMENT_DOWNLOAD_SUCCESS =
  "Attachment has been successfully downloaded";
export const ATTACHMENT_DOWNLOAD_FAILED = "Failed to download attachment";
export const ATTACHMENT_SEND_SUCCESS = "Attachment has been sent successfully";
export const ATTACHMENT_SEND_FAILED = "Attachment has failed to sent";
export const SDK_ERROR =
  "There is a network issue, please close your browser and relogin after 60 sec.";
export const CALL = "CALL";
export const CHAT = "CHAT";
export const CALLBACK = "CALLBACK";
export const MESSAGE = "MESSAGE";
export const EMAIL = "EMAIL";
export const DEFAULT_WRAPUP_CODE = "7fb334b0-0e9e-11e4-9191-0800200c9a66";
export const ALLOWED_CONV_MEDIA_TYPES = {
  VOICE: "VOICE",
  CHAT: "CHAT",
  CASE: "CASE",
};
export const FETCH_ABORT_TIMEOUT = 5000;
export const WINDOW_CLOSE_DURING_CALL_MSG =
  "Call was disconnected Due to Network Disconnect!";
export const WINDOW_CLOSE_DURING_CALL_TITLE = "Call dropped!";
export const MISSING_GROUP_MSG =
  "You do not have correct permissions, please contact Adobe Service Desk";
export const MISSING_GROUP_TITLE = "Group not assigned";
export const WEBRTC_CONNECTED_MSG = "You are now ready to make/receive calls.";
export const TRANSFER_CHAT_FAILED =
  "Chat Transfer Unsuccessful: Please try again or contact support for assistance.";
export const VOICE_NETWORK_DISCONNECT =
  "Call functionality is down due to Network/VPN disconnect. Once Network/VPN is back, AIC will reconnect automatically.";
export const OFFSCREEN_DOCUMENT_PATH = "index-offscreen.html";
export const API_CHANGE_AGENT_STATE = "api.changeAgentState";
export const AMAZON_CONNECT_ACTIVE = true;
export const SESSION_TIMER_WARNING = 36000;
export const CCP_STATES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
  CONNECTED: 4,
  DISCONNECTED: 5,
};

export const MESSAGE_TARGETS = {
  OFFSCREEN: "offscreen",
  UI: "ui",
  BG: "bg",
  AIL: "AIL",
};

export const AGENT_STATUS_LIST = {
  ON_QUEUE: "On Queue",
  AVAILABLE: "Available",
  OFFLINE: "Offline",
};

export const COOKIE_AWS = [
  "JSESSIONID",
  "lily-auth-prod-pdx",
  "lily-auth-exp",
  "lily-user-info",
  "lily-auth-refresh-prod-pdx",
  "lily-redirect-uri",
  "lily-user-info",
];

export const COUNTRY_NAME_CODE_MAP: { [key: string]: string } = {
  Afghanistan: "AF",
  Albania: "AL",
  Algeria: "DZ",
  Andorra: "AD",
  Angola: "AO",
  "Antigua and Barbuda": "AG",
  Argentina: "AR",
  Armenia: "AM",
  Australia: "AU",
  Austria: "AT",
  Azerbaijan: "AZ",
  Bahamas: "BS",
  Bahrain: "BH",
  Bangladesh: "BD",
  Barbados: "BB",
  Belarus: "BY",
  Belgium: "BE",
  Belize: "BZ",
  Benin: "BJ",
  Bhutan: "BT",
  Bolivia: "BO",
  "Bosnia and Herzegovina": "BA",
  Botswana: "BW",
  Brazil: "BR",
  "Brunei Darussalam": "BN",
  Bulgaria: "BG",
  "Burkina Faso": "BF",
  Burundi: "BI",
  "Cabo Verde": "CV",
  Cambodia: "KH",
  Cameroon: "CM",
  Canada: "CA",
  "Central African Republic": "CF",
  Chad: "TD",
  Chile: "CL",
  China: "CN",
  Colombia: "CO",
  Comoros: "KM",
  "Congo (Congo-Brazzaville)": "CG",
  "Congo (Democratic Republic of the Congo)": "CD",
  "Costa Rica": "CR",
  Croatia: "HR",
  Cuba: "CU",
  Cyprus: "CY",
  "Czechia (Czech Republic)": "CZ",
  Denmark: "DK",
  Djibouti: "DJ",
  Dominica: "DM",
  "Dominican Republic": "DO",
  Ecuador: "EC",
  Egypt: "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  Eritrea: "ER",
  Estonia: "EE",
  Ethiopia: "ET",
  Fiji: "FJ",
  Finland: "FI",
  France: "FR",
  Gabon: "GA",
  Gambia: "GM",
  Georgia: "GE",
  Germany: "DE",
  Ghana: "GH",
  Greece: "GR",
  Grenada: "GD",
  Guatemala: "GT",
  Guinea: "GN",
  "Guinea-Bissau": "GW",
  Guyana: "GY",
  Haiti: "HT",
  Honduras: "HN",
  Hungary: "HU",
  Iceland: "IS",
  India: "IN",
  Indonesia: "ID",
  Iran: "IR",
  Iraq: "IQ",
  Ireland: "IE",
  Israel: "IL",
  Italy: "IT",
  Jamaica: "JM",
  Japan: "JP",
  Jordan: "JO",
  Kazakhstan: "KZ",
  Kenya: "KE",
  Kiribati: "KI",
  "Korea (North)": "KP",
  "Korea (South)": "KR",
  Kuwait: "KW",
  Kyrgyzstan: "KG",
  Laos: "LA",
  Latvia: "LV",
  Lebanon: "LB",
  Lesotho: "LS",
  Liberia: "LR",
  Libya: "LY",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  Madagascar: "MG",
  Malawi: "MW",
  Malaysia: "MY",
  Maldives: "MV",
  Mali: "ML",
  Malta: "MT",
  "Marshall Islands": "MH",
  Mauritania: "MR",
  Mauritius: "MU",
  Mexico: "MX",
  Micronesia: "FM",
  Moldova: "MD",
  Monaco: "MC",
  Mongolia: "MN",
  Montenegro: "ME",
  Morocco: "MA",
  Mozambique: "MZ",
  "Myanmar (formerly Burma)": "MM",
  Namibia: "NA",
  Nauru: "NR",
  Nepal: "NP",
  Netherlands: "NL",
  "New Zealand": "NZ",
  Nicaragua: "NI",
  Niger: "NE",
  Nigeria: "NG",
  "North Macedonia (formerly Macedonia)": "MK",
  Norway: "NO",
  Oman: "OM",
  Pakistan: "PK",
  Palau: "PW",
  Panama: "PA",
  "Papua New Guinea": "PG",
  Paraguay: "PY",
  Peru: "PE",
  Philippines: "PH",
  Poland: "PL",
  Portugal: "PT",
  Qatar: "QA",
  Romania: "RO",
  Russia: "RU",
  Rwanda: "RW",
  "Saint Kitts and Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Vincent and the Grenadines": "VC",
  Samoa: "WS",
  "San Marino": "SM",
  "Sao Tome and Principe": "ST",
  "Saudi Arabia": "SA",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  "Sierra Leone": "SL",
  Singapore: "SG",
  Slovakia: "SK",
  Slovenia: "SI",
  "Solomon Islands": "SB",
  Somalia: "SO",
  "South Africa": "ZA",
  "South Sudan": "SS",
  Spain: "ES",
  "Sri Lanka": "LK",
  Sudan: "SD",
  Suriname: "SR",
  Sweden: "SE",
  Switzerland: "CH",
  Syria: "SY",
  Taiwan: "TW",
  Tajikistan: "TJ",
  Tanzania: "TZ",
  Thailand: "TH",
  "Timor-Leste": "TL",
  Togo: "TG",
  Tonga: "TO",
  "Trinidad and Tobago": "TT",
  Tunisia: "TN",
  Turkey: "TR",
  Turkmenistan: "TM",
  Tuvalu: "TV",
  Uganda: "UG",
  Ukraine: "UA",
  "United Arab Emirates": "AE",
  "United Kingdom": "GB",
  "United States": "US",
  Uruguay: "UY",
  Uzbekistan: "UZ",
  Vanuatu: "VU",
  "Vatican City": "VA",
  Venezuela: "VE",
  Vietnam: "VN",
  Yemen: "YE",
  Zambia: "ZM",
  Zimbabwe: "ZW",
};
