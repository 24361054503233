import { useState, useEffect } from "react";
import {
  ActionButton,
  Avatar,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Flex,
  Heading,
  Text,
  View,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import Emitter from "../../../emitter";
import {
  API_CALL_ANSWER,
  API_CALL_REJECT,
  CALL_STATES,
} from "../../../constants/constants";
import { RootState } from "../../../store/storeConfig";
import "../style.css";
import { CallIcon, CallRinging, CallReject } from "../../common/Icons";
import { BusinessAttributes } from "../../../types/agent.type";

const CallAcceptDialog = () => {
  const [isOpen, setOpen] = useState(true);
  const call = useSelector((state: RootState) => state.call);
  const agent = useSelector((state: RootState) => state.agent);

  let callCustomerAttributes = call?.customer?.attributes || {};
  let agentBusinessAttributes = agent?.businessAttributes || [];

  useEffect(() => {
    if (call && call?.id && call?.self?.direction !== "OUTBOUND") {
      setOpen(true);
    }
  }, [call?.id]);

  const rejectCallHandler = () => {
    Emitter.emit(API_CALL_REJECT, {
      body: {
        conversationId: call.self?.id,
      },
    });
    setOpen(false);
  };

  const pickupCallHandler = () => {
    Emitter.emit(API_CALL_ANSWER, {
      body: {
        conversationId: call.self?.id,
      },
    });
    setOpen(false);
  };

  // 1. use filter to compare agent attributes with call attributes. Get matching attributes as output.
  let matchingAttributes = agentBusinessAttributes.filter(function (
    entry: BusinessAttributes
  ) {
    if (
      entry.attributeName.toLowerCase() === "notes" &&
      callCustomerAttributes.hasOwnProperty(entry.attributeName)
      // && callCustomerAttributes[entry.attributeName]
    ) {
      return false;
    }

    return callCustomerAttributes.hasOwnProperty(entry.attributeName);
  });

  let finalArray = [];
  for (let i = 0; i < matchingAttributes?.length; i += 3) {
    const attributes = [matchingAttributes[i], matchingAttributes[i + 1]];

    finalArray.push(
      <View
        key={`attribute-group-${i}`}
        UNSAFE_className="call-incoming-attributes"
      >
        <Flex direction="row" wrap="wrap" gap={40}>
          {attributes.map((attribute, index) =>
            attribute ? (
              <Flex
                direction="column"
                key={`attribute-${i + index}`}
                UNSAFE_style={{ flex: 1, minWidth: "45%", maxWidth: "45%" }}
              >
                <Text
                  UNSAFE_className="call-content-heading"
                  UNSAFE_style={{
                    wordBreak: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {displayInProperFormat(attribute.attributeName)}
                </Text>

                <Text
                  UNSAFE_className="call-content-descritpion"
                  UNSAFE_style={{
                    wordBreak: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {callCustomerAttributes[attribute.attributeName]}
                </Text>
              </Flex>
            ) : null
          )}
        </Flex>
      </View>
    );
  }

  /**
   * for displaying label in "Label Text" format.
   *
   */
  function displayInProperFormat(inputString: string) {
    if (inputString) {
      // replace all _ and - with space
      inputString = inputString.replace(/_/g, " ").replace(/-/g, " ").trim();
      // split with space and convert to desired output
      let inputStringArray = inputString.split(" ");
      inputStringArray = inputStringArray.map(function (nameEntry) {
        return (
          nameEntry.charAt(0).toUpperCase() + nameEntry.slice(1).toLowerCase()
        );
      });
      // join the array with space.
      return inputStringArray.join(" ");
    } else {
      return "";
    }
  }

  return (
    <>
      {isOpen &&
        call?.self?.state === CALL_STATES.RINGING &&
        call?.self?.direction !== "OUTBOUND" &&
        !call?.isSilentMonitor && (
          <DialogTrigger type="modal" mobileType="modal" defaultOpen={true}>
            <ActionButton isHidden isQuiet></ActionButton>
            {() => (
              <Dialog size={"S"} UNSAFE_className="call-incoming-dialog">
                <Heading level={4}>
                  <Flex
                    direction={"row"}
                    gap={"size-100"}
                    alignItems={"center"}
                  >
                    <Text UNSAFE_className="call-heading-icon">
                      <CallRinging />
                    </Text>
                    <Text UNSAFE_className="call-heading-text">
                      New incoming call
                    </Text>
                  </Flex>
                </Heading>
                <Divider
                  marginBottom={"size-0"}
                  UNSAFE_className="call-incoming-divider"
                />
                <Content maxWidth={"100vw"} marginTop={"15px"}>
                  <Content>
                    <Flex direction={"column"} gap={"size-200"} width={"100%"}>
                      <Flex direction={"row"} width={"100%"}>
                        <Flex direction={"column"} width={"45%"}>
                          <Flex>
                            <Avatar
                              src="https://i.imgur.com/kJOwAdv.png"
                              alt="default Adobe avatar"
                              UNSAFE_className="call-avatar"
                            />
                            <Flex direction={"column"}>
                              <Text UNSAFE_className="call-content-phone-heading">
                                {call?.customer?.attributes?.Customer_ANI ||
                                  call?.customer?.displayName ||
                                  call?.customer?.phoneNumber ||
                                  "-"}
                              </Text>
                              <Text UNSAFE_className="call-content-email-descritpion">
                                -
                              </Text>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                      <View>{finalArray}</View>
                    </Flex>
                  </Content>
                </Content>
                <ButtonGroup>
                  <Button
                    variant="accent"
                    UNSAFE_className="call-accept-btn"
                    onPress={pickupCallHandler}
                    autoFocus={false}
                  >
                    <CallIcon fill="007A4D" />
                    <Text>Accept</Text>
                  </Button>
                  <Button
                    UNSAFE_className="call-reject-btn"
                    variant="negative"
                    onPress={rejectCallHandler}
                    autoFocus={false}
                  >
                    <CallReject />
                    <Text>Reject</Text>
                  </Button>
                </ButtonGroup>
              </Dialog>
            )}
          </DialogTrigger>
        )}
    </>
  );
};

export default CallAcceptDialog;
