import { useMemo } from "react";
import { Flex, View } from "@adobe/react-spectrum";
import { Text } from "@react-spectrum/text";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/storeConfig";
import "./style.css";
import { fixJson, formatTimestamp, getInitialsAndColor } from "../../../utils";
import { BusinessAttributes } from "../../../types/agent.type";

const CallDetails = () => {
  const call = useSelector((state: RootState) => state.call);
  const agent = useSelector((state: RootState) => state.agent);

  let callCustomerAttributes = call?.customer?.attributes || {};
  let agentBusinessAttributes = agent?.businessAttributes || [];
  let notesPresent = false;
  let notesPropertySpelling = "";

  const { initials, backgroundColor } = useMemo(
    () => getInitialsAndColor("N A"),
    [call?.customer?.attributes?.Username]
  );

  // 1. use filter to compare agent attributes with call attributes. Get matching attributes as output.
  let matchingAttributes = agentBusinessAttributes.filter(function (
    entry: BusinessAttributes
  ) {
    if (
      entry.attributeName.toLowerCase() === "notes" &&
      callCustomerAttributes.hasOwnProperty(entry.attributeName)
      // && callCustomerAttributes[entry.attributeName]
    ) {
      notesPresent = true;
      notesPropertySpelling = entry.attributeName;
      return false;
    }

    return callCustomerAttributes.hasOwnProperty(entry.attributeName);
  });

  let finalArray = [];
  for (let i = 0; i < matchingAttributes?.length; i += 3) {
    const attributes = [
      matchingAttributes[i],
      matchingAttributes[i + 1],
      matchingAttributes[i + 2],
    ];

    finalArray.push(
      <View key={`attribute-group-${i}`} marginTop={23}>
        <Flex direction="row" wrap="wrap" gap={16}>
          {attributes.map((attribute, index) =>
            attribute ? (
              <Flex
                direction="column"
                key={`attribute-${i + index}`}
                UNSAFE_style={{ flex: 1, minWidth: "31%", maxWidth: "31%" }} // Ensures equal width
              >
                <Text
                  UNSAFE_className="call-detail-title"
                  UNSAFE_style={{
                    wordBreak: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {displayInProperFormat(attribute.attributeName)}
                </Text>
                {displayInProperFormat(attribute.attributeName) ===
                "Aic Web Contact Monitored History" ? (
                  <Text
                    UNSAFE_className="call-detail-description"
                    UNSAFE_style={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    {fixJson(
                      callCustomerAttributes[attribute.attributeName]
                    ).map((detail: any, idx: number) => (
                      <Flex
                        justifyContent={"space-between"}
                        marginTop={4}
                        key={idx}
                      >
                        <Text>{detail.user}</Text>
                        <Text>{formatTimestamp(detail.TS)}</Text>
                      </Flex>
                    ))}
                  </Text>
                ) : (
                  <Text
                    UNSAFE_className="call-detail-description"
                    UNSAFE_style={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    {callCustomerAttributes[attribute.attributeName]}
                  </Text>
                )}
              </Flex>
            ) : null
          )}
        </Flex>
      </View>
    );
  }

  // 3. Append Notes if Present
  if (notesPresent) {
    finalArray.push(
      <Flex direction="column" key="transfer-notes" marginTop={23}>
        <Text UNSAFE_className="call-detail-title">Transfer Notes</Text>
        <Text UNSAFE_className="call-detail-description">
          {callCustomerAttributes[notesPropertySpelling]}
        </Text>
      </Flex>
    );
  }

  /**
   * for displaying label in "Label Text" format.
   *
   */

  function displayInProperFormat(inputString: string) {
    if (inputString) {
      // replace all _ and - with space
      inputString = inputString.replace(/_/g, " ").replace(/-/g, " ").trim();
      // split with space and convert to desired output
      let inputStringArray = inputString.split(" ");
      inputStringArray = inputStringArray.map(function (nameEntry) {
        return (
          nameEntry.charAt(0).toUpperCase() + nameEntry.slice(1).toLowerCase()
        );
      });
      // join the array with space.
      return inputStringArray.join(" ");
    } else {
      return "";
    }
  }

  return (
    <>
      <View
        borderColor="gray-200"
        borderWidth="thin"
        flexGrow={1}
        backgroundColor={"gray-100"}
        height={"100%"}
      >
        <Flex direction={"row"} width={"100%"} height={"100%"}>
          <View
            width={
              !call?.isSilentMonitor || call?.self?.direction !== "OUTBOUND"
                ? "100%"
                : "65%"
            }
            padding={32}
          >
            <Flex
              direction={"column"}
              UNSAFE_className="call-detail-wrapper"
              height={"100%"}
            >
              <Flex height={"60%"} direction={"column"}>
                <Text UNSAFE_className="customer-detail-heading">
                  Call Details
                </Text>
                <View>{finalArray}</View>
              </Flex>
              {/* uncomment case history when needed */}
              {/* <Flex height={"40%"}>
                <Text UNSAFE_className="customer-detail-heading">
                  Case History
                </Text>
              </Flex> */}
            </Flex>
          </View>
          {call?.isSilentMonitor ||
            (call?.self?.direction !== "OUTBOUND" && (
              <View
                UNSAFE_className="customer-detail-section"
                height={"100%"}
                borderStartColor={"gray-200"}
                borderStartWidth={"thin"}
              >
                <Flex
                  UNSAFE_className="customer-detail-wrapper"
                  direction={"column"}
                  gap={15}
                >
                  <Text UNSAFE_className="customer-detail-heading">
                    Customer Details
                  </Text>
                  <Flex gap={15}>
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      UNSAFE_className="agent-avatar"
                      UNSAFE_style={{
                        backgroundColor,
                        borderRadius: "50%",
                      }}
                    >
                      <Text UNSAFE_className="call-monitor-agent-text">
                        {initials}
                      </Text>
                    </Flex>
                    <Flex direction={"column"}>
                      <Text UNSAFE_className="customer-detail-name">
                        {"Unknown"}
                      </Text>
                      <Text UNSAFE_className="customer-detail-email">
                        {"-"}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex direction={"column"}>
                    <Text UNSAFE_className="call-detail-title">org name</Text>
                    <Text UNSAFE_className="call-detail-description">-</Text>
                  </Flex>
                  <Flex direction={"column"}>
                    <Flex gap={40}>
                      <Flex direction={"column"}>
                        <Text UNSAFE_className="call-detail-title">
                          Phone number
                        </Text>
                        <Text UNSAFE_className="call-detail-description">
                          {call?.customer?.phoneNumber || "-"}
                        </Text>
                      </Flex>
                      <Flex direction={"column"}>
                        <Text UNSAFE_className="call-detail-title">
                          Country
                        </Text>
                        <Text UNSAFE_className="call-detail-description">
                          {call?.customer?.country || "-"}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </View>
            ))}
        </Flex>
      </View>
    </>
  );
};

export default CallDetails;
