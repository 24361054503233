import { current } from "@reduxjs/toolkit";
import {
  WEBRTC_SET,
  WEBRTC_ERROR,
  LOGOUT,
  WEBRTC_RESET,
  SET_WEBRTC_SESSION,
  RESET_WEBRTC_ERROR,
  SET_CCP_STATE,
  UPDATE_MEDIA_DEVICES,
  UPDATE_SELECTED_MEDIA_DEVICE,
  UPDATE_RING_ON_DIFFERENT_DEVICES,
  UPDATE_VOICE_NETWORK_STATE,
  SET_WEBRTC_TOKEN,
  RESET_STATE,
  STORE_LOGIN,
  SET_CURRENT_CCP_INSTANCE
} from "../constants/action-types";
import { CCP_STATES } from "../constants/constants";
import { WebRTC } from "../types/webrtc.type";
const initialState: WebRTC = {
  session: undefined,
  error: undefined,
  mediaDevices: undefined,
  ringtoneOutoutDeviceId: undefined,
  ringOnDifferentDevice: false,
  voiceOutputDeviceId: undefined,
  voiceInputDeviceId: undefined,
  isVoiceNetworkAvailable: false,
  ccpSessionState: CCP_STATES.CLOSED,
  currentCcpInstance: '',
  t: undefined,
};

/**
 *
 * @param {*} state
 * @param {*} action
 */
export default (state: WebRTC = initialState, action: any) => {
  switch (action.type) {
    case WEBRTC_SET:
      return {
        ...state,
        ...action.webrtc,
      };
    case WEBRTC_RESET:
      return {
        ...state,
        session: undefined,
      };
    case SET_WEBRTC_SESSION:
      return {
        ...state,
        session: action.session || undefined,
      };
    case WEBRTC_ERROR:
      const error = action.error
        ? { message: action.error, timestamp: new Date() }
        : undefined;
      return {
        ...state,
        error: error,
      };
    case SET_CCP_STATE:
      return {
        ...state,
        ccpSessionState: action.ccpSessionState
      };
    case RESET_WEBRTC_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case UPDATE_MEDIA_DEVICES:
      return {
        ...state,
        mediaDevices: action.mediaDevices,
      };
    case UPDATE_SELECTED_MEDIA_DEVICE: {
      return {
        ...state,
        ringtoneOutoutDeviceId: action.deviceId,
      };
    }
    case SET_CURRENT_CCP_INSTANCE: {
      return {
        ...state,
        currentCcpInstance: action.currentCcpInstance || state.currentCcpInstance,
      };
    }
    case UPDATE_RING_ON_DIFFERENT_DEVICES: {
      return {
        ...state,
        ringOnDifferentDevice: action.ringOnDifferentDevice,
      };
    }
    case UPDATE_VOICE_NETWORK_STATE: {
      return {
        ...state,
        isVoiceNetworkAvailable: action.isVoiceNetworkAvailable,
      };
    }
    case SET_WEBRTC_TOKEN:
      return {
        ...state,
        t: action.payload,
      };

    case LOGOUT:
      return { ...initialState };

    case RESET_STATE:
      return { ...initialState };
    case STORE_LOGIN:
      return {
        ...state,
        ...action.payload.webrtc,
      };

    default:
      return state;
  }
};
