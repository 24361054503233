import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ActionButton, Flex, View } from "@adobe/react-spectrum";
import CallHistorySidebar from "./CallHistorySidebar";
import CallHistoryDetail from "./CallHistoryDetail";
import { RootState } from "../../../store/storeConfig";
import ActiveCallContainer from "../CallDialer/ActiveCallContainer";
import storeRTK from "../../../store/storeRTK";
import { setHistoryfilterCallCount } from "../../../actions/call";
import { Call } from "../../../types/call.type";

const CallHistory = () => {
  const history = useSelector((state: RootState) => state.history);
  const { conversations } = history || {};

  const [activeCallDetail, setActiveCallDetail] = useState<any>({});
  const [callList, setCallList] = useState<any>([]);
  const [filterAction, setFilterAction] = useState("all");

  useEffect(() => {
    if (conversations?.length > 0) {
      setActiveCallDetail(conversations[0]);
      setCallList(conversations);
    }
    storeRTK.dispatch(setHistoryfilterCallCount(conversations?.length));
  }, []);

  const getActiveCallId = (id: string) => {
    const activeCall = conversations.find((call: Call) => call.id === id);
    setActiveCallDetail(activeCall);
  };

  const handleMissedCall = () => {
    const missedCallList =
      conversations?.length > 0 &&
      conversations.filter((call: Call) => call.self?.missed === true);
    setCallList(missedCallList);
    setFilterAction("missed");
    setActiveCallDetail(missedCallList[0]);
    storeRTK.dispatch(setHistoryfilterCallCount(missedCallList?.length));
  };

  const handleAllCall = () => {
    setCallList(conversations);
    setFilterAction("all");
    setActiveCallDetail(conversations[0]);
    storeRTK.dispatch(setHistoryfilterCallCount(conversations?.length));
  };

  return (
    <View backgroundColor={"static-white"} width={"100%"} height={"100%"}>
      <Flex height={"100%"}>
        {conversations?.length > 0 ? (
          <View backgroundColor={"static-white"} width={"100%"}>
            <Flex direction={"column"} height={"100%"}>
              <View
                backgroundColor="static-white"
                UNSAFE_className="call-history-header"
              >
                <View
                  UNSAFE_className="call-history-filter"
                  backgroundColor="static-white"
                >
                  <ActionButton
                    UNSAFE_className={`call-history-button ${
                      filterAction === "all" && "call-history-button-active"
                    }`}
                    onPress={handleAllCall}
                    aria-label="all"
                    data-testid="all-call"
                  >
                    All
                  </ActionButton>
                  <ActionButton
                    marginX={8}
                    UNSAFE_className={`call-history-button ${
                      filterAction === "missed" && "call-history-button-active"
                    }`}
                    onPress={handleMissedCall}
                    aria-label="missed"
                    data-testid="missed-call"
                  >
                    Missed
                  </ActionButton>
                  <ActionButton
                    UNSAFE_className="call-history-button"
                    isDisabled
                    aria-label="favourites"
                    data-testid="favourites-call"
                  >
                    Favourites
                  </ActionButton>
                </View>
              </View>
              <View width={"100%"} UNSAFE_className="call-history-container">
                <Flex
                  direction={"row"}
                  alignItems={"start"}
                  height={"100%"}
                  width={"100%"}
                >
                  {activeCallDetail?.id && (
                    <CallHistorySidebar
                      getActiveCallId={getActiveCallId}
                      activeCallerId={activeCallDetail?.id}
                      callList={callList}
                    />
                  )}
                  <CallHistoryDetail activeCallDetail={activeCallDetail} />
                </Flex>
              </View>
            </Flex>
          </View>
        ) : (
          <Flex alignItems={"center"} justifyContent={"center"} width={"90%"}>
            <ActiveCallContainer
              title="No Call history"
              description="Start a new call or wait for the call"
            />
          </Flex>
        )}
        {/* <View
          borderColor="gray-300"
          borderWidth="thin"
          backgroundColor="static-white"
          borderTopWidth={NONE}
          height={"100%"}
        >
          <CaseAi caseID="" isDisabled={true} />
        </View> */}
      </Flex>
    </View>
  );
};

export default CallHistory;
