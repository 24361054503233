import {
  CALL_RESET,
  CALL_SET_CONSULT,
  CALL_SET,
  CALL_SET_CONSULT_TYPE,
  UPDATE_CALL,
  UPDATE_CALL_TRANSFER_ENDPOINTS,
  RESET_NORMAL_CALL,
  HISTORY_FILTER_CALL_COUNT,
} from "../constants/action-types";

export function callReset(lastCall = null) {
  return {
    type: CALL_RESET,
    lastCall,
  };
}

export function setConsultCall(consultCall: any) {
  return {
    type: CALL_SET_CONSULT,
    consultCall,
  };
}

export function setCallState(call: any) {
  return {
    type: CALL_SET,
    call,
  };
}
export function setConsultType(consultType: any) {
  return {
    type: CALL_SET_CONSULT_TYPE,
    consultType,
  };
}
export function updateNormalCall(call: any) {
  return {
    type: UPDATE_CALL,
    call,
  };
}
export function updateTransferEndpoints(call: any) {
  return {
    type: UPDATE_CALL_TRANSFER_ENDPOINTS,
    call,
  };
}

export function resetNormalCall() {
  return {
    type: RESET_NORMAL_CALL,
  };
}

export function setHistoryfilterCallCount(callCount: number) {
  return {
    type: HISTORY_FILTER_CALL_COUNT,
    callCount,
  };
}
