import React, { useEffect, useState } from "react";
import {
  ActionGroup,
  Flex,
  Item,
  View,
  Selection,
  Text,
  TooltipTrigger,
  Tooltip,
  ActionButton,
} from "@adobe/react-spectrum";
import "./style.css";
import { getSupervisorAgentList } from "../../api/session-api";
import SupervisorSidebar from "./SupervisorSidebar";
import SupervisorCallDetails from "./SupervisorCallDetails";
import { SUPERVISOR_BUTTONS } from "../../constants/constants";
import { AgentStatusDetail } from "../../types/agent.type";
import { RefreshIcon, TimerIcon } from "../common/Icons";
import { Tabs } from "../../types/generic.types";
import { AGENT_STATUS_ERROR, TELEMETRY_ERROR } from "../../constants/events";
import Emitter from "../../emitter";

const Supervisor: React.FC = () => {
  const [agentList, setAgentList] = useState<any>([]);
  const [selectedAgent, setSelectedAgent] = useState<string>("");
  const [filteredAgentList, setFilteredAgentList] = useState<any>(agentList);
  const [selectedState, setSelectedState] = useState<Selection>(
    new Set(["All"])
  );
  const [isFetching, setIsFetching] = useState(true);
  const [statusRefreshTimer, setStatusRefreshTimer] = useState(10);
  const statusOrder: { [key: string]: number } = {
    "On Call": 1,
    "On Queue": 2,
    Available: 3,
    Logoff: 4,
  };

  // Fetch agent list
  const fetchAgentList = async () => {
    try {
      setStatusRefreshTimer(10);
      const response = await getSupervisorAgentList();
      if (response.length === 0) {
        setIsFetching(false);
        return;
      }
      if (response?.agent_status && response?.agent_status.length === 0) {
        setIsFetching(false);
        return;
      }

      // Sort the agent list based on the status order and additional condition
      const sortedAgentList = response.agent_status.sort(
        (a: AgentStatusDetail, b: AgentStatusDetail) => {
          const aStatus = a.agent_current_status_name;
          const bStatus = b.agent_current_status_name;

          // Check additional condition for "On Call" status
          const aOrder =
            aStatus === "On Call" || a.agent_current_contacts.length > 0
              ? 1
              : statusOrder[aStatus] || 5;
          const bOrder =
            bStatus === "On Call" || b.agent_current_contacts.length > 0
              ? 1
              : statusOrder[bStatus] || 5;

          return aOrder - bOrder;
        }
      );
      setAgentList(sortedAgentList);
      setFilteredAgentList(sortedAgentList);

      setIsFetching(false);
      if (selectedAgent === "" && response)
        setSelectedAgent(response?.agent_status[0]?.agent_ldap);
    } catch (error) {
      setIsFetching(false);
      console.error("Failed to fetch agent list:", error);
      Emitter.emit(TELEMETRY_ERROR, {
        type: "notif",
        chan: TELEMETRY_ERROR,
        body: {
          type: AGENT_STATUS_ERROR,
          message: "Failed to fetch agent list",
          error,
        },
      });
    }
  };

  useEffect(() => {
    fetchAgentList();
  }, []);

  // Handle status refresh timer countdown
  useEffect(() => {
    if (statusRefreshTimer <= 0) {
      fetchAgentList();
      setStatusRefreshTimer(10);
    }

    const timer = setInterval(() => {
      setStatusRefreshTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [statusRefreshTimer]);

  // Filter agents based on selected status
  const mapFilteredAgentList = (agentStatus: string | undefined) => {
    if (!agentStatus || !agentList || agentList.length === 0) {
      setFilteredAgentList([]);
      return;
    }

    const statusFilters: {
      [key: string]: (agent: AgentStatusDetail) => boolean;
    } = {
      "On queue": (agent) =>
        agent.agent_current_status_name === "On Queue" &&
        agent.agent_current_contacts.length === 0,
      "Log-off": (agent) =>
        agent.agent_current_status_name === "Offline" &&
        agent.agent_current_contacts.length === 0,
      "On call": (agent) => agent.agent_current_contacts.length > 0,
      "Not ready": (agent) =>
        agent.agent_current_status_name !== "On Queue" &&
        agent.agent_current_status_name !== "Offline" &&
        agent.agent_current_contacts.length === 0,
      All: () => true,
    };

    const filterFunction = statusFilters[agentStatus] || (() => false);
    const filteredAgents = agentList.filter(filterFunction);

    setFilteredAgentList(filteredAgents);
  };

  // Handle selection change
  useEffect(() => {
    if (Array.from(selectedState)?.length > 0) {
      const value = Array.from(selectedState)[0];
      let selectedFilter = SUPERVISOR_BUTTONS.find(
        (button: Tabs) => button.name === value
      );
      mapFilteredAgentList(selectedFilter?.name);
    } else {
      setSelectedState(new Set(["All"]));
      mapFilteredAgentList("All");
    }
  }, [selectedState, agentList]);

  return (
    <Flex gap={"40px"} height={"100%"}>
      <View width={"100%"}>
        <Flex direction={"column"} height={"100%"}>
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            UNSAFE_className="call-monitor-header"
          >
            <View UNSAFE_className="call-monitor-header-wrapper">
              <Flex gap="100px" alignItems={"center"}>
                <Text UNSAFE_className="call-monitor-heading">
                  Realtime Status
                </Text>
                <View UNSAFE_style={{ cursor: "pointer" }}>
                  <Flex alignItems={"center"} marginTop={4}>
                    <View width={20} UNSAFE_className="call-monitor-time-icon">
                      <TimerIcon />
                    </View>
                    <Flex alignItems={"center"}>
                      <View>
                        <Text
                          marginX={5}
                          UNSAFE_className="supervisor-refresh-timer"
                        >
                          Refresh in
                        </Text>
                      </View>
                      <View width={15}>
                        <Text UNSAFE_className="supervisor-refresh-timer">
                          {statusRefreshTimer}s
                        </Text>
                      </View>
                    </Flex>
                    <View marginX={8} marginTop={4}>
                      <TooltipTrigger delay={0}>
                        <ActionButton
                          UNSAFE_className="supervisor-refresh-button"
                          aria-label="Refresh"
                          onPress={fetchAgentList}
                          UNSAFE_style={{ cursor: "pointer" }}
                        >
                          <RefreshIcon color={"#0265DC"} />
                        </ActionButton>
                        <Tooltip>Refresh now</Tooltip>
                      </TooltipTrigger>
                    </View>
                  </Flex>
                </View>
              </Flex>
            </View>
            <View paddingX={32} backgroundColor="static-white">
              <Flex direction={"row"} UNSAFE_className="call-monitor-btn">
                <ActionGroup
                  selectionMode="single"
                  selectedKeys={selectedState}
                  onSelectionChange={setSelectedState}
                >
                  {SUPERVISOR_BUTTONS.map((button: Tabs) => (
                    <Item key={button.name}>
                      <Text UNSAFE_className="call-monitor-action-btn">
                        {button.name}
                      </Text>
                    </Item>
                  ))}
                </ActionGroup>
              </Flex>
            </View>
          </Flex>
          <Flex direction={"row"} alignItems={"start"} height={"90%"}>
            <SupervisorSidebar
              agentList={filteredAgentList}
              selectedAgent={selectedAgent}
              setSelectedAgent={setSelectedAgent}
              isFetching={isFetching}
            />
            <SupervisorCallDetails
              agentList={agentList}
              selectedAgent={selectedAgent}
            />
          </Flex>
        </Flex>
      </View>
    </Flex>
  );
};

export default Supervisor;
