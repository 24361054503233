import { useEffect, useState } from "react";
import "./style.css";
import { connect } from "react-redux";
import Emitter from "../../emitter";
import {
  API_AGENT_CHANGE,
  SESSION_TIMER_ALERT,
  STOP_SESSION,
} from "../../constants/constants";
import {
  ActionButton,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Heading,
} from "@adobe/react-spectrum";
import { setLoader } from "../../actions/common";
import storeRTK from "../../store/storeRTK";
import { AgentState } from "../../types/agent.type";

/**
 * Map Store State To component Props
 * @param {*} state
 */

function mapStateToProps(state: any) {
  return {
    session: state.session,
    agent: state.agent,
    call: state.call,
    chat: state.chat,
  };
}

const SessionTimer = (props: any) => {
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    const handleSessionTimerAlert = (event: { body: { dialog: boolean } }) => {
      if (event.body) {
        setDialog(true);
      }
    };

    Emitter.on(SESSION_TIMER_ALERT, handleSessionTimerAlert);

    return () => {
      Emitter.off(SESSION_TIMER_ALERT, handleSessionTimerAlert);
      setDialog(false);
    };
  }, []);

  const handleLogout = () => {
    storeRTK.dispatch(setLoader(true));
    Emitter.emit(STOP_SESSION);
    storeRTK.dispatch(setLoader(false));
  };

  return (
    <DialogTrigger isOpen={dialog}>
      <ActionButton isHidden>Dialog</ActionButton>
      <Dialog width={"30rem"}>
        <Heading>Session expire warning! </Heading>
        <Divider />
        <Content>
          Your session has expired , please reauthenticate to connect again.
        </Content>
        <ButtonGroup>
          <Button variant="secondary" onPress={handleLogout}>
            Log Off
          </Button>
        </ButtonGroup>
      </Dialog>
    </DialogTrigger>
  );
};
export default connect(mapStateToProps)(SessionTimer);
