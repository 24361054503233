import { useMemo } from "react";
import { Flex, Heading, View, Text } from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import ContactIcon from "../../../assets/contactIcon.svg";
import CallIcon from "../../../assets/callIcon.svg";
import StarIcon from "../../../assets/starIcon.svg";
import Emitter from "../../../emitter";
import { API_CALL_DIAL } from "../../../constants/constants";
import storeRTK from "../../../store/storeRTK";
import { setSelectedTab } from "../../../actions/common";
import { RootState } from "../../../store/storeConfig";
import { showErrorToast } from "../../../utils/utils";
import { fixJson, formatTimestamp, getInitialsAndColor } from "../../../utils";
import { Call } from "../../../types/call.type";
import { BusinessAttributes } from "../../../types/agent.type";
import ActiveCallContainer from "../CallDialer/ActiveCallContainer";

interface ICallHistoryDetailProps {
  activeCallDetail: Call;
}

const CallHistoryDetail = (props: ICallHistoryDetailProps) => {
  const { activeCallDetail } = props;

  let vpnStatus = useSelector(
    (state: RootState) => state.webrtc?.isVoiceNetworkAvailable
  );
  const agent = useSelector((state: RootState) => state.agent);
  const call = useSelector((state: RootState) => state.call);
  const micStatus = useSelector(
    (state: RootState) => state.session.isMicEnabled
  );

  const { initials, backgroundColor } = useMemo(
    () => getInitialsAndColor("N A"),
    [activeCallDetail?.id]
  );

  const dialCall = (phoneNumber: string) => {
    if (!micStatus) {
      showErrorToast("Please enable microphone to make a call", "micError");
      return;
    }
    Emitter.emit(API_CALL_DIAL, { phoneNumber });
    storeRTK.dispatch(setSelectedTab("Call"));
  };

  let callCustomerAttributes = activeCallDetail?.customer?.attributes || {};
  let agentBusinessAttributes = agent?.businessAttributes || [];
  let notesPresent = false;
  let notesPropertySpelling = "";

  // 1. use filter to compare agent attributes with call attributes. Get matching attributes as output.
  let matchingAttributes = agentBusinessAttributes.filter(function (
    entry: BusinessAttributes
  ) {
    if (
      entry.attributeName.toLowerCase() === "notes" &&
      callCustomerAttributes.hasOwnProperty(entry.attributeName)
      // && callCustomerAttributes[entry.attributeName]
    ) {
      notesPresent = true;
      notesPropertySpelling = entry.attributeName;
      return false;
    }

    return callCustomerAttributes.hasOwnProperty(entry.attributeName);
  });

  // Group the matching attributes into sets of 3 (three columns per row)
  let finalArray = [];
  for (let i = 0; i < matchingAttributes?.length; i += 3) {
    const attributes = [
      matchingAttributes[i],
      matchingAttributes[i + 1],
      matchingAttributes[i + 2],
    ];

    finalArray.push(
      <View key={`attribute-group-${i}`} marginTop={23}>
        <Flex direction="row" wrap="wrap" gap={20}>
          {attributes.map((attribute, index) =>
            attribute ? (
              <Flex
                direction="column"
                key={`attribute-${i + index}`}
                minWidth={"30%"}
              >
                <Text UNSAFE_className="call-detail-title">
                  {displayInProperFormat(attribute.attributeName)}
                </Text>
                {displayInProperFormat(attribute.attributeName) ===
                "Aic Web Contact Monitored History" ? (
                  <Text UNSAFE_className="call-detail-description">
                    {fixJson(
                      callCustomerAttributes[attribute.attributeName]
                    ).map((detail: any, idx: number) => (
                      <Flex
                        justifyContent={"space-between"}
                        marginTop={4}
                        key={idx}
                      >
                        <Text>{detail.user}</Text>
                        <Text>{formatTimestamp(detail.TS)}</Text>
                      </Flex>
                    ))}
                  </Text>
                ) : (
                  <Text UNSAFE_className="call-detail-description">
                    {callCustomerAttributes[attribute.attributeName]}
                  </Text>
                )}
              </Flex>
            ) : null
          )}
        </Flex>
      </View>
    );
  }

  // 3. Append Notes if Present
  if (notesPresent) {
    finalArray.push(
      <Flex direction="column" key="transfer-notes" marginTop={23}>
        <Text UNSAFE_className="call-detail-title">Transfer Notes</Text>
        <Text UNSAFE_className="call-detail-description">
          {callCustomerAttributes[notesPropertySpelling]}
        </Text>
      </Flex>
    );
  }

  /**
   * for displaying label in "Label Text" format.
   *
   */

  function displayInProperFormat(inputString: string) {
    if (inputString) {
      // replace all _ and - with space
      inputString = inputString.replace(/_/g, " ").replace(/-/g, " ").trim();
      // split with space and convert to desired output
      let inputStringArray = inputString.split(" ");
      inputStringArray = inputStringArray.map(function (nameEntry) {
        return (
          nameEntry.charAt(0).toUpperCase() + nameEntry.slice(1).toLowerCase()
        );
      });
      // join the array with space.
      return inputStringArray.join(" ");
    } else {
      return "";
    }
  }

  return (
    <>
      {activeCallDetail?.id ? (
        <View
          borderColor="gray-200"
          borderWidth="thin"
          height={"100%"}
          backgroundColor={"gray-100"}
          UNSAFE_className="call-history-detail"
        >
          <View
            padding="10px 15px"
            borderBottomWidth="thin"
            borderBottomColor="gray-200"
          >
            <Flex
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex alignItems={"center"}>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  UNSAFE_className="agent-avatar"
                  UNSAFE_style={{
                    backgroundColor,
                    borderRadius: "50%",
                  }}
                >
                  <Text UNSAFE_className="call-monitor-agent-text">
                    {initials}
                  </Text>
                </Flex>
                <View marginX={12}>
                  <Heading level={3} margin={0}>
                    <Text UNSAFE_className="call-history-detail-title">
                      {activeCallDetail?.customer?.attributes?.Customer_ANI ||
                        activeCallDetail?.customer?.displayName ||
                        activeCallDetail?.customer?.phoneNumber ||
                        "-"}
                    </Text>
                  </Heading>
                  {/* <Text UNSAFE_className="call-history-detail-subtitle">
                    {activeCallDetail?.customer?.attributes?.Customer_ANI ||
                      activeCallDetail?.customer?.displayName ||
                      activeCallDetail?.customer?.phoneNumber ||
                      "-"}
                  </Text> */}
                </View>
              </Flex>
              <Flex
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <button
                  className="call-history-action-btn"
                  aria-label="Add to favourites"
                  data-testid="add-favourites-button"
                  disabled
                >
                  <img src={StarIcon} alt="favourite icon" />
                  <Text marginX={7} UNSAFE_style={{ whiteSpace: "nowrap" }}>
                    Add to favourites
                  </Text>
                </button>
                <button
                  className="call-history-action-btn"
                  disabled
                  aria-label="Add to speed dials"
                  data-testid="add-speed-dials-button"
                >
                  <img src={ContactIcon} alt="contact icon" />
                  <Text marginX={7} UNSAFE_style={{ whiteSpace: "nowrap" }}>
                    Add to speed dials
                  </Text>
                </button>
                <button
                  aria-label="Call"
                  data-testid="call-button"
                  onClick={() =>
                    dialCall(
                      (activeCallDetail?.customer?.attributes?.Customer_ANI ||
                        activeCallDetail?.customer?.displayName) ??
                        ""
                    )
                  }
                  disabled={call?.id || !vpnStatus}
                  className={`call-history-action-btn call-history-action-btn-call ${
                    call?.id || !vpnStatus ? "call-btn-disabled" : ""
                  }`}
                >
                  <img src={CallIcon} alt="call icon" />
                  <Text marginX={7}>Call</Text>
                </button>
              </Flex>
            </Flex>
          </View>
          <View>
            <Flex direction={"row"} height={"100%"}>
              <Flex width={"100%"}>
                <View
                  UNSAFE_className="call-history-detail-body"
                  width={"100%"}
                >
                  {finalArray.length > 0 ? (
                    <View marginTop={23}>{finalArray}</View>
                  ) : (
                    <Flex marginTop={23} justifyContent={"center"}>
                      <Text>No Detail Found</Text>
                    </Flex>
                  )}
                </View>
              </Flex>
              {/* {activeCallDetail?.history &&
                activeCallDetail?.history.length > 0 && (
                  <Flex
                    UNSAFE_className="call-history-detail-right"
                    width={"22.5%"}
                  >
                    <View
                      width={"100%"}
                      borderStartWidth={"thick"}
                      borderStartColor={"gray-200"}
                    >
                      <View UNSAFE_className="call-history-detail-subheading">
                        <Text>History</Text>
                      </View>
                      <View UNSAFE_className="call-history-detail-wrapper">
                        {activeCallDetail?.history.map(
                          (call: any, index: number) => {
                            const { connectedTime, endTime, missed } =
                              call?.self || {}; // Ensure these are numbers
                            const {
                              formattedConnectedTime,
                              formattedDifference,
                            } = formatTime(connectedTime, endTime);
                            return (
                              <Flex
                                width={"100%"}
                                alignItems={"end"}
                                justifyContent={"space-between"}
                                UNSAFE_className="call-hisory-wrapper"
                                UNSAFE_style={
                                  index !== 0
                                    ? { borderTop: "1px solid #D5D5D5" }
                                    : {}
                                }
                              >
                                <Flex
                                  direction="column"
                                  alignItems="start"
                                  UNSAFE_className="call-detail-list-start"
                                >
                                  <Text UNSAFE_className="call-history-title">
                                    {call.customer.attributes.Customer_ANI ||
                                      call.customer.displayName ||
                                      call.customer?.phoneNumber ||
                                      "-"}
                                  </Text>
                                  <Text
                                    UNSAFE_style={{
                                      textAlign: "start",
                                      color: "#D7373F",
                                    }}
                                  >
                                    <Flex
                                      UNSAFE_className={`call-history-subtitle ${
                                        missed ? "call-missed" : "call-attend"
                                      }`}
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                      marginTop={5}
                                    >
                                      <img
                                        src={getCallIcon(call)}
                                        className="call-history-icon"
                                        alt="Missed call icon"
                                      />
                                      <Text
                                        marginX={5}
                                        UNSAFE_style={{ whiteSpace: "nowrap" }}
                                      >
                                        {formattedConnectedTime}
                                      </Text>
                                    </Flex>
                                  </Text>
                                </Flex>
                                <Flex direction="column">
                                  <Text UNSAFE_className="call-history-time-diff">
                                    {formattedDifference}
                                  </Text>
                                </Flex>
                              </Flex>
                            );
                          }
                        )}
                      </View>
                    </View>
                  </Flex>
                )} */}
            </Flex>
          </View>
        </View>
      ) : (
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          height={"60%"}
          width={"100%"}
        >
          <ActiveCallContainer
            title="No Missed Call history"
            description="Start a new call or wait for the call"
          />
        </Flex>
      )}
    </>
  );
};

export default CallHistoryDetail;
