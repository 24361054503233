//OKTA Login Types
export const AGENT_LOGIN_TYPES = {
  // NO_CRM: 'NO CRM',
  // CONSUMER: 'CONSUMER',
  ENTERPRISE: "ENTERPRISE",
  // CONNECT: 'CONNECT',
};

export const CALL = "call";
export const CHAT = "chat";
export const NONE = "none";
export const CASE = "case";
export const SETTINGS = "setting";
export const HELPSUPPORT = "helpSupport";
export const LOG_PREFIX = "AIC web: ";
export const LIVE_CASE = "live case";
export const HISTORY = "history";

// CCP Constants

export const CONTACT_EVENT_STATE_MAP = {
  INITIAL: "INITIAL",
  INCOMING: "INCOMING",
  CONNECTING: "CONNECTING",
  ACCEPTED: "ACCEPTED",
  CONNECTED: "CONNECTED",
  REFRESH: "REFRESH",
  ENDED: "ENDED",
  MUTETOGGLE: "MUTETOGGLE",
  MISSED: "MISSED",
  DESTROYED: "DESTROYED",
  RINGING: "RINGING",
};

export const CONNECT_STREAMS_DIV_ID = "connect-streams-div";
export const CONTACT_STATE_MAP = {
  INCOMING: "RINGING",
  CONNECTING: "RINGING",
  DIALING: "DIALING",
  ACCEPTED: "RINGING",
  CONNECTED: "ESTABLISHED",
  ENDED: "RELEASED",
};

export const CCP_STATES_INDEX = [
  "CONNECTING",
  "OPEN",
  "CLOSING",
  "CLOSED",
  "CONNECTED",
  "DISCONNECTED",
];

export const CHANNEL_TYPES = {
  VOICE: "voice",
  CHAT: "chat",
  VOICEMAIL: "voicemail",
  TASK: "task",
};
export const CALLBACK = "callback";
export const MESSAGE = "message";
export const EMAIL = "email";

export const ALLOWED_CONV_MEDIA_TYPES = {
  VOICE: "VOICE",
  CHAT: "CHAT",
  CASE: "CASE",
};
//DEFINE okta urls for all above agent login types
export const APP_VERSION = process.env.REACT_APP_VERSION;
// API Events

export const API_GENESYS_HANGUP = "/api/genesys/hangup";
export const API_GENESYS_DIAL = "/api/genesys/dial";
export const API_GENESYS_INITIATE_TRANSFER = "/api/genesys/initiateTransfer";

// COMETD Events
export const COMETD_EVENT_LOGOUT = "/cometd/logout";
export const COMETD_EVENT_CALLS = "/cometd/events/calls";
export const COMETD_EVENT_CALLS_RELEASE = "/cometd/events/calls/released";
// bg
export const INITIATE_TWO_STEP_CONFERENCE =
  "/bg/call/initiate-two-step-conference";

export const BG_INITIATE_LOGIN = "bg.InitiateLogin";
export const BG_INITIATE_RELOGIN = "bg.InitiateRelogin";
export const BG_TABS_ADD = "/bg/tabs/add";
export const BG_CUSTOMERS = "/bg/customers";
export const BG_CUSTOMER_SELECTED = "/bg/customer/selected";
export const BG_HISTORY = "/bg/history/change";
export const BG_PERF_LOADUI = "/bg/performance/load-ui";
export const BG_CASE_STATE = "/bg/case/state";
export const BG_INITIATE_CONSULT = "/bg/call/initiate-consult";

export const BG_NOTIFICATION = "bg.Notification";
export const BG_MESSAGE = "bg.Message";
export const BG_ERROR_MESSAGE = "bg.Error.Message";

export const BG_MEDIA_PERM_CHECK = "bg.MediaPermCheck";
export const BG_ASK_MEDIA_PERM = "bg.AskMediaPerm";
export const BG_HISTORY_CHANGE = "bg.historyChange";
export const BG_ERROR = "bg.Error";
export const WEBRTC_ERROR = "webrtc.Error";
export const RESET_WEBRTC_ERROR = "reset.Webrtc.Error";
export const SESSION_ERROR = "session.Error";
export const RESET_SESSION_ERROR = "reset.Session.Error";
export const WEBRTC_SET_SESSION = "webrtc.set.session";
export const WEBRTC_RESET_SESSION = "webrtc.reset.session";
export const CCP_STATE = "ccp.state";
export const CCP_INSTANCE = 'ccp.instance';
export const CCP_PING = "ccp.Ping";
export const WEBSOCKET_ERROR = "websocket.Error";
export const START_SESSION = "session.Start";
export const STOP_SESSION = "session.Stop";
export const SESSION_STATE = "session.State";
export const SESSION_TIMER_ALERT = "session.Timer.Alert";
export const BG_LOGIN_DETAILS = "bg.LoginDetails";
export const BG_BROADCAST = "bg.Broadcast";
export const STATE_AGENT = "state.Agent";
export const STATE_CALL = "state.Call";
export const STATE_ACW = "state.ACW";
export const STATE_VOICEMAIL = "state.voicemail";
export const API_TEST_RING_PLAY = "api.testRing.play";
export const VOICE_NETWORK_STATE = "voice.network.state";

// Client Side Events
export const API_CALL_ANSWER = "api.callAnswer";
export const API_CALL_REJECT = "api.callReject";
export const API_CALL_HANGUP = "api.callHangup";
export const API_CALL_HOLD = "api.callHold";
export const API_CALL_PLAY = "api.callPlay";
export const API_CHANGE_AGENT_STATE = "api.changeAgentState";
export const API_CALL_DIAL = "api.callDial";
export const API_CALL_TRANSFER = "api.callTransfer";
export const API_CALL_PATCH_ATTRIBUTE = "api.patchAttribute";
export const API_AGENT_GROUP_STATUS = "api.agentGroupStatus";
export const API_CALL_SEND_DTMF = "api.sendDTMF";
export const API_CALL_MUTE = "api.callMute";
export const API_CALL_WRAPUP_CODE = "api.callWrapupCode";
export const API_CALL_WRAPUP = "api.callWrapup";
export const API_CALL_TRANSFER_ENDPOINTS = "api.call.transfer.endpoints";
export const API_AIL_HEALTH = "api.ailHealth";
export const API_TRANSFER_FAILURE = "api.transfer.failure";
export const API_AGENT_CHANGE = "api.changeAgentStatus";
// callback events

export const STATE_CALLBACK = "state.Callback";
export const API_CALLBACK_ANSWER = "api.callbackAnswer";
export const API_CALLBACK_REJECT = "api.callbackReject";
export const API_CALLBACK_HANGUP = "api.callbackHangup";
export const API_CALLBACK_DIAL = "api.callbackDial";

// chat events

export const STATE_CHAT = "state.Chat";
export const STATE_CHAT_HISTORY = "state.ChatHistory";
export const API_CHAT_ANSWER = "api.chatAnswer";
export const API_CHAT_REJECT = "api.chatReject";
export const API_SENDCHATMESSAGE = "api.sendChatMessage";
export const STATE_CANNEDRESPONSES = "state.CannedResponses";
export const API_SENDCHATTYPING = "api.sendChatTyping";
export const API_CHAT_TRANSFER = "api.chatTransfer";
export const API_CHAT_PATCH_ATTRIBUTE = "api.patchChatAttribute";
export const API_ACTIVE_CHAT_UPDATE = "api.activeChatUpdate";
export const API_UNREAD_CHAT_COUNT = "api.unreadChatCount";
export const API_TAB_CHANGE = "api.tabChange";
export const API_CHAT_HISTORY = "api.chatHistory";
export const API_CHECK_FOR_LOST_MESSAGE = "api.checkLostMessage";
export const API_CHAT_TRANSFER_ENDPOINTS = "api.chat.transfer.endpoints";

export const RING_TYPES = {
  CALL: "CALL",
  CHAT: "CHAT",
  MESSAGE: "MESSAGE",
  LOGOUT_ALARM: "LOGOUT_ALARM",
  CASE: "CASE",
  CALLBACK: "CALLBACK",
};

// case events
export const API_CASE_ANSWER = "api.caseAnswer";
export const API_CASE_REJECT = "api.caseReject";
export const STATE_CASE = "state.Task";
export const API_ACTIVE_CASE_UPDATE = "api.activeCaseUpdate";

// internal events
export const SHOW_TAB = "/internal/show_tab";
export const SHOW_LOADER = "/internal/show_loader";
export const HIDE_LOADER = "/internal/hide_loader";
export const SHOW_NOTIFICATION = "/internal/notification/show";
export const HIDE_NOTIFICATION = "/internal/notification/hide";
export const SHOW_MESSAGE = "/internal/message/show";
export const HIDE_MESSAGE = "/internal/message/hide";
export const SHOW_ERROR_MESSAGE = "/internal/error/message/show";
export const HIDE_ERROR_MESSAGE = "/internal/error/message/hide";
export const SHOW_TRANSFER_CONTROLS = "/internal/transfer/show";
export const SHOW_CONFERENCE_CONTROLS = "/internal/conference/show";
export const SELECT_TRANSFER_QUEUE = "/internal/transfer/queue";
export const SELECT_CONFERENCE_QUEUE = "/internal/conference/queue";
export const SHOW_DIALOG = "/internal/show_dialog";
export const HIDE_DIALOG = "/internal/hide_dialog";
export const SAVE_AGENT_NOTES = "/internal/save/agentNotes";
export const EXTENSION_DISCONNECT = "/internal/bg/disconnect";
export const INTERNAL_UPDATE_DIAL_NUMBER = "internal.updateDialNumber";
export const INTERNAL_UPDATE_CALLBACK_DIAL_NUMBER =
  "internal.updateCallbackDialNumber";
export const INTERNAL_CALLWEBCASE_MODIFY = "internal.callwebcaseModify";
export const SHOW_EDITNUMBER = "internal.showEditNumber";
export const HIDE_EDITNUMBER = "internal.hideEditNumber";
export const SHOW_DISCONNECT = "SHOW_DISCONNECT";
export const HIDE_DISCONNECT = "HIDE_DISCONNECT";
export const HIDE_MEDIA_PERM_POPUP = "hide.media.perm.popup";

export const SHOW_EDITNUMBER_CASE = "internal.showEditNumberCase";
export const HIDE_EDITNUMBER_CASE = "internal.hideEditNumberCase";
export const INTERNAL_UPDATE_CASE_DIAL_NUMBER = "internal.updateCaseDialNumber";

export const TOGGLE_CALL_STATUS_WEBCASE = "internal.toggleCallStatusWebcase";
export const TOGGLE_CHAT_STATUS_WEBCASE =
  "internal.toggleChatAgentStatusWebcase";
export const TOGGLE_CASE_STATUS_WEBCASE =
  "internal.toggleCaseAgentStatusWebcase";
export const INTERNAL_SCROLL_SYNC = "internal.scrollSync";
export const SHOW_SELECTED_ATTACHMENT = "state.showSelectedAttachment";

export const INTERNAL_VPN_STATUS_UPDATE = "internal.vpn.status.update";

export const STATE_WEBCHATMESSAGE = "state.WebChatMessage";

export const CHANGE_CALL_AGENT_STATUS = "internal.changeCallAgentStatus";

export const CHANGE_CHAT_AGENT_STATUS = "internal.changeChatAgentStatus";
export const CHANGE_CASE_AGENT_STATUS = "internal.changeCaseAgentStatus";
export const UPDATE_MEDIA_DEVICES_LIST = "wb.updateMediaDeviceList";
export const API_UPDATE_RING_MEDIA_DEVICE = "api.updateRingMediaDevice";
export const STATE_UPDATE_RING_MEDIA_DEVICE = "state.updateRingMediaDevice";

export const API_UPDATE_ROD_DEVICE = "api.updateRODevice";
export const STATE_UPDATE_ROD_DEVICE = "state.updateRODevice";
export const TRANSFER_CHAT_IN_PROGRESS = "state.transferChatInProgress";

export const API_UPDATE_VI_DEVICE = "api.updateVIDevice";
export const STATE_UPDATE_VI_DEVICE = "state.updateVIDevice";

export const API_UPDATE_VO_DEVICE = "api.updateVODevice";
export const STATE_UPDATE_VO_DEVICE = "state.updateVODevice";

export const SESSION_LOGOUT_MESSAGE = "session.logout.message";
export const SESSION_LOGOUT_MESSAGE_CLOSE = "session.logout.messageClose";
export const API_DOWNLOAD_LOGS = "api.download.logs";

export const AGENT_AFTER_CALL_WORK_STATE_ID = "after_call_work_id";
export const AGENT_STATUS_LIST = {
  ON_QUEUE: "On Queue",
  AVAILABLE: "Available",
  OFFLINE: "Offline",
};

// Export active chat Header and message text
export const CHANGE_ACTIVE_CHAT = "internal.activeChatChange";
export const UPDATE_AGENT_RESPONSE = "internal.updateAgentResponse";
export const CHAT_ROLES = {
  CUSTOMER: "CUSTOMER",
  AGENT: "AGENT",
};

//Other contants
export const AGENT_STATUS_COLOR_MAP = {
  "On Queue": "#2D9D78",
  Available: "#2D9D78",
  Offline: "#E34850",
  default: "#2680EB",
  "After Call Work": "#6E6E6E",
};

export const AGENT_STATUS_COLOR_VARIENT = {
  "On Queue": "positive",
  Available: "positive",
  Offline: "negative",
  default: "info",
  "After Call Work": "neutral",
};

export const headerTabs = [
  {
    id: 1,
    name: "Calls",
    children: "Arma virumque cano, Troiae qui primus ab oris.",
  },
  {
    id: 2,
    name: "Chat",
    children: "Senatus Populusque Romanus.",
  },
  { id: 3, name: "Cases", children: "Alea jacta est." },
];

export const CALL_STATES = {
  RINGING: "RINGING",
  DIALING: "DIALING",
  ESTABLISHED: "ESTABLISHED",
  RELEASED: "RELEASED",
};
export const CHAT_STATES = {
  RINGING: "RINGING",
  ESTABLISHED: "ESTABLISHED",
  RELEASED: "RELEASED",
};

export const CASE_STATES = {
  RINGING: "RINGING",
  ESTABLISHED: "ESTABLISHED",
  RELEASED: "RELEASED",
};

export const CCP_STATES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
  CONNECTED: 4,
  DISCONNECTED: 5,
};

export const TRANSFER_NOTES_LENGTH = 1000;
export const AGENT_MESSAGE_LENGTH = 1024;
export const DEFAULT_WRAPUP_CODE = "7fb334b0-0e9e-11e4-9191-0800200c9a66";

export const CALL_CANNOT_DIAL =
  "Call cannot be established, ensure that Network connection is stable";
export const NEWORK_DISCONNECT_MESSAGE =
  "Call functionality is down due to Network disconnect. Once Network is back, AIC will reconnect automatically.";
export const WEBRTC_NOT_READY_MESSAGE =
  "Phone is not ready yet...please wait for phone calls, It may take few seconds to become ready.";
export const FETCH_ABORT_TIMEOUT = 5000;
export const VOICE_NETWORK_DISCONNECT =
  "Call functionality is down due to VPN disconnect. Once VPN is back, AIC will reconnect automatically.";
export const SESSION_TIMER_WARNING = 36000; //in seconds
export const CCP_CONNECTION_ERROR_MSG =
  "Max retries reached. Could not connect to CCP.";
// message Type
export const CHAT_MESSAGE_TYPE = {
  TEXT: "text/plain",
  MARKUP: "text/markdown",
  ATTACHMENT: "ATTACHMENT",
};
export const SHOW_CCP_CONNECTION_ERROR = "show.CCPConnectionError";
export const HIDE_CCP_CONNECTION_ERROR = "hide.CCPConnectionError";
export const API_DOWNLOAD_ATTACHMENT = "api.downloadAttachment";
export const API_SEND_ATTACHMENT = "api.sendAttachment";
export const BG_API_STATUS = "bg.Api.Status";
export const OPEN_MEDIA_PERMISSION_TAB = "open.MediaPermTab";
export const CCP_ASK_MEDIA_PERMISSION = "ccp.AskMediaPerm";
export const SHOW_API_STATUS_MESSAGE = "/internal/api/message/show";
export const HIDE_API_STATUS_MESSAGE = "/internal/api/message/hide";
export const SHOW_API_STATUS_MESSAGE_FILESTYPES =
  "/internal/api/message/show/FileType";
export const HIDE_API_STATUS_MESSAGE_FILESTYPES =
  "/internal/api/message/hide/FileType";
export const SHOW_UPLOADING_ATTACHMENT = "state.showUploadingAttachment";
export const MAX_SIZE_IN_MB = 20;
export const MAX_FILE_SIZE_MESSAGE = "File size must be less than 20 MB.";
export const FILE_TYPE_NOT_SUPPORTED =
  "The file type is not supported for attachment.";
export const ALLOWED_FILE_TYPE = [
  "csv",
  "doc",
  "docx",
  "heic",
  "jfif",
  "jpeg",
  "jpg",
  "mov",
  "mp4",
  "pdf",
  "png",
  "ppt",
  "pptx",
  "rtf",
  "txt",
  "wav",
  "xls",
  "xlsx",
];

// Link of the user guide for the help and support section
export const USER_GUIDE_LINK =
  "https://wiki.corp.adobe.com/pages/viewpage.action?pageId=3409129100&moved=true";

export const SUPERVISOR_BUTTONS = [
  { id: 1, name: "All" },
  { id: 2, name: "On call" },
  { id: 3, name: "On queue" },
  { id: 4, name: "Not ready" },
  { id: 5, name: "Log-off" },
];

export const permissionName = "microphone" as PermissionName;

export const REACT_APP_CARE_PORTAL_LOGIN_URL = "https://care.adobe.com/login";
export const REACT_APP_IAM_URL = "https://iam.corp.adobe.com/";
export const REACT_APP_AIC_USER_GUIDE_URL =
  "https://wiki.corp.adobe.com/pages/viewpage.action?pageId=3409129100&moved=true";

export const MULTI_INSTANCES = [
  {
    id: 'dxoc-' + process.env.REACT_APP_ENV,
    name: 'US'
  },
  {
    id: 'dxoc-sg-' + process.env.REACT_APP_ENV,
    name: 'SG',
  }
];
