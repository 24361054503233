import {
  AGENT_SET,
  AGENT_SET_QUEUES,
  AGENT_SET_STATES,
  LOGOUT,
  AGENT_SET_CURRENT_STATE,
  AGENT_SET_ACW_STATE,
  RESET_AGENT_SET_ACW_STATE,
  AGENT_SET_CANNEDRESPONSES,
  UPDATE_ASSINGED_QUEUE,
  STORE_LOGIN,
  RESET_STATE,
  AGENT_UPDATE_STATE_FROM_AWS,
  AGENT_UPDATE_GEO_LOCATION,
  AGENT_UPDATE_COUNTRY,
} from "../constants/action-types";
import { AGENT_AFTER_CALL_WORK_STATE_ID } from "../constants/constants";
import { Agent } from "../types/agent.type";


const initialState: Agent = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  state: null, // Current State
  aicConfig: null,
  agentInfo: null,
  stateModifiedDate: 0,
  acwState: null,
  acwStateModifiedDate: 0,
  groupId: "",
  states: {
    allowedStates: null, // All possible states.
    allowedStatesIndex: null,
  },
  businessAttributes: [],
  cannedResponses: [],
  chatTransferQueues: null,
  queues: {
    allowedQueues: null,
    conferenceQueues: null,
    sivrQueues: null,
  },
  allowedConversationMedia: [],
  availableMedia: [],
  sessionPingInterval: null,
  vpnCheckUrl: "",
  transferQueues: null,
  queuesAssigned: null,
  queueSelectionAllowed: false,
  routingProfileConCurrency: null,
  crm: null,
  agentLoginTime: "",
  instanceConfig: []
};

/**
 *
 * @param {*} state
 * @param {*} action
 */
export default (state = initialState, action: any) => {
  switch (action.type) {
    case AGENT_SET:
      return {
        ...state,
        ...action.agent,
      };
    case AGENT_SET_STATES:
      return {
        ...state,
        states: {
          allowedStates: action.payload.allowedStates,
          allowedStatesIndex: action.payload.allowedStatesIndex,
        },
      };

    case AGENT_SET_QUEUES:
      return {
        ...state,
        queues: {
          allowedQueues: action.payload.queues,
          conferenceQueues: action.payload.conferenceQueues,
          sivrQueues: action.payload.sivrQueues,
        },
      };
    case AGENT_SET_CURRENT_STATE:
      return {
        ...state,
        state: {
          ...state.state,
          displayName: action.status,
          id: action.status,
        },
        stateModifiedDate: action.stateModifiedDate,
      };
    case AGENT_UPDATE_STATE_FROM_AWS:
      return {
        ...state,
        state: {
          ...state.state,
          displayName: action.status.id,
          id: action.status.displayName,
        },
        stateModifiedDate: action.status.stateModifiedDate,
      };
    case AGENT_SET_ACW_STATE:
      if (state.state?.id !== action.acwState.id) {
        return {
          ...state,
          acwState: state.state,
          acwStateModifiedDate: state.stateModifiedDate,
          state: action.acwState,
          stateModifiedDate: action.acwStateModifiedDate,
        };
      }
      return {
        ...state,
      };
    case RESET_AGENT_SET_ACW_STATE:
      if (state.acwState && state.state?.id !== state.acwState?.id) {
        const agentState =
          state?.state?.id === AGENT_AFTER_CALL_WORK_STATE_ID
            ? state.acwState
            : state.state;
        const stateModifiedDate =
          state?.state?.id === AGENT_AFTER_CALL_WORK_STATE_ID
            ? state.acwStateModifiedDate
            : state.stateModifiedDate;
        return {
          ...state,
          acwState: null,
          acwStateModifiedDate: 0,
          state: agentState,
          stateModifiedDate,
        };
      }
      return {
        ...state,
      };
    case AGENT_SET_CANNEDRESPONSES: {
      // since canned responses will be for a particular queueName/LibraryName,
      //just add individual cannedResponses to the state.
      return {
        ...state,
        cannedResponses: [
          ...(state.cannedResponses || []),
          action.cannedResponse,
        ],
      };
    }
    case UPDATE_ASSINGED_QUEUE: {
      const queuesAssigned = action.queuesAssigned;
      return {
        ...state,
        queuesAssigned: queuesAssigned,
      };
    }
    case AGENT_UPDATE_GEO_LOCATION: {
      return {
        ...state,
        agentInfo: {
          ...state.agentInfo,
          geoLocation: action.geoLocation,
          country: action.country || state.agentInfo?.country,
        }};
    }
    case AGENT_UPDATE_COUNTRY: {
      return {
        ...state,
        agentInfo: {
          ...state.agentInfo,
          country: action.country || state.agentInfo?.country,
        }};
    }
    case LOGOUT:
      return { ...initialState };
    case STORE_LOGIN:
      return {
        ...state,
        ...action.payload.agent,
        stateModifiedDate: new Date(
          action.payload.agent.stateModifiedDate || ""
        ).getTime(),
      };
    case RESET_STATE:
      return {};
    default:
      return state;
  }
};
