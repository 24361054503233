import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "../reducers";
import { saveStateToLocalStorage } from "./storeConfig";

// Create store with middleware and initial state
const storeRTK = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  preloadedState: {},
});

storeRTK.subscribe(() => {
  const state = storeRTK.getState();
  saveStateToLocalStorage(state);
});
declare global {
  interface Window {
    storeRTK: typeof storeRTK;
  }
}

window.storeRTK = storeRTK;
export default storeRTK;
