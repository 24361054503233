import { CONTACT_EVENT_STATE_MAP, LOG_PREFIX } from "../../constants/constants";
import { connectSession } from "../handlers/session";
import { updateTaskAndSend } from "../handlers/task";
import { changeAgentStateToAvailable } from "../handlers/agent";
import Emitter from "../../emitter";
import { API_CASE_REJECT, API_CASE_UPDATE } from "../constants";
const lp = LOG_PREFIX + "[api.task.ts]: ";

export function getAgentContactById(id: string) {
  const logPrefix = lp + "getAgentContactById() " + id + " ";
  try {
    const contacts = connectSession.agent.getContacts(connect.ContactType.TASK);
    if (contacts.length > 0) {
      return contacts.find((ct: any) => ct.contactId === id);
    }
    return null;
  } catch (err) {
    console.log(logPrefix + "Error in getting contact by id", err);
    return null;
  }
}

export function acceptTaskContact(ev: any) {
  const logPrefix = lp + "acceptTaskContact() " + ev.body.conversationId;
  try {
    const taskContact = getAgentContactById(ev.body.conversationId);
    if (taskContact) {
      taskContact.accept({
        success: function () {
          console.log(
            logPrefix + "Task Accepted via Streams, taskContact = ",
            taskContact
          );
        },
        failure: function (error: any) {
          console.log(
            logPrefix + "Failed to establish connection via Streams with error",
            error
          );
          disconnectTaskContact(ev);
        },
      });
      console.log(
        lp + "ACCEPTED task contact: accepted by agent. Contact state is "
      );
    } else {
      console.log(logPrefix + "No Task contact found");
      clearCurrentTaskContact(ev.body.conversationId);
      updateTaskAndSend(CONTACT_EVENT_STATE_MAP.ENDED, ev.body.conversationId);
    }
  } catch (e) {
    console.log(logPrefix + "Error in accepting task", e);
    clearCurrentTaskContact(ev.body.conversationId);
    updateTaskAndSend(CONTACT_EVENT_STATE_MAP.ENDED, ev.body.conversationId);
  }
}

export function disconnectTaskContact(ev: any) {
  const logPrefix = lp + "disconnectTaskContact " + ev.body.conversationId;
  try {
    const taskContact = getAgentContactById(ev.body.conversationId);
    if (taskContact) {
      const taskAgentConn = taskContact.getAgentConnection();
      if (taskAgentConn) {
        taskAgentConn.destroy({
          success: function () {
            taskContact.clear();
          },
          failure: function (err: any) {
            console.log(
              logPrefix + "Failed to Disconnect Task via Streams",
              err
            );
            clearCurrentTaskContact(ev.body.conversationId);
            updateTaskAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {
              contactId: ev.body.conversationId,
            });
          },
        });
      } else {
        console.log(logPrefix + "No Task agent connection found");
        clearCurrentTaskContact(ev.body.conversationId);
        updateTaskAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {
          contactId: ev.body.conversationId,
        });
      }
    } else {
      console.log(logPrefix + "No Task contact found");
      clearCurrentTaskContact(ev.body.conversationId);
      updateTaskAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {
        contactId: ev.body.conversationId,
      });
    }
  } catch (error) {
    console.log(logPrefix + "Error in disconnecting task", error);
    clearCurrentTaskContact(ev.body.conversationId);
    updateTaskAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {
      contactId: ev.body.conversationId,
    });
  }
}

export function rejectTaskContact(ev: any) {
  changeAgentStateToAvailable();
  disconnectTaskContact(ev);
}

export function updateTaskContact(ev: any) {
  console.log("  updateTaskContact ", ev);
  if (ev.body.state === "connected") {
    acceptTaskContact(ev);
  } else if (ev.body.state === "disconnected") {
    disconnectTaskContact(ev);
  }
}

export function clearCurrentTaskContact(contactId: string) {
  const contact = getAgentContactById(contactId);
  if (contact) {
    try {
      contact.clear();
    } catch (e) {}
  }
}

export default function registerTaskEvents() {    
  Emitter.on(API_CASE_UPDATE, updateTaskContact);
  // Emitter.on(API_CASE_REJECT, rejectTaskContact);
}