import {
  ActionButton,
  Button,
  Divider,
  Flex,
  Item,
  Key,
  Menu,
  MenuTrigger,
  Text,
  View,
} from "@adobe/react-spectrum";
import "./Header.css";
import AdobeLogo from "../../assets/adobeLogo.svg";
import AgentStatusChange from "../Dashboard/AgentStatusChange";
import MultiInstanceChange from "../MultiInstance";
import { HELPSUPPORT, SETTINGS } from "../../constants/constants";
import { SETTING_OPTIONS } from "../Dashboard/constants";
import { useSelector } from "react-redux";
import storeRTK from "../../store/storeRTK";
import { setLoader } from "../../actions/common";
import { setCurrentSessionTab } from "../../actions/session";
import { RootState } from "../../store/storeConfig";
import Emitter from "../../emitter";
import { BellIcon, ConnectIcon } from "../common/Icons";
import { STOP_SESSION } from "../../constants/events";
import QueueSelection from "../Dashboard/QueueSelection";

const HeaderComponent = () => {
  // Select only the parts of the state needed for the component
  const agent = useSelector((state: RootState) => state.agent);
  const call = useSelector((state: RootState) => state.call);
  const callback = useSelector((state: RootState) => state.callback);
  const chat = useSelector((state: RootState) => state.chat);
  const sessionState = useSelector((state: RootState) => state.session);

  // Conditional menu options based on agent's WFM scheduling URL config
  const menuOptions =
    agent?.aicConfig?.wfm_scheduling_url_enable === "true"
      ? SETTING_OPTIONS
      : SETTING_OPTIONS.filter((item) => item.id !== 3);

  const onTabChange = (id: string) => {
    storeRTK.dispatch(setCurrentSessionTab({ id }));
  };

  const handleLogout = (key: any) => {
    switch (key) {
      case "1":
        setTimeout(() => {
          Emitter.emit(STOP_SESSION);
          storeRTK.dispatch(setLoader(false));
        }, 100);
        break;
      case "2":
        onTabChange(SETTINGS);
        break;
      case "4":
        onTabChange(HELPSUPPORT);
        break;
      default:
        break;
    }
  };

  // const openWfmWindow = (url: any) => {
  //   // Define the features for the new window
  //   const features =
  //     "fullscreen=1,toolbar=no,location=no,directories=no,status=no,menubar=no,resizable=no";
  //   // Open a new window with the specified features
  //   const newWindow = window.open(url, "_blank", features);
  //   // Add an event listener to prevent user input in the address bar
  //   // newWindow.addEventListener('load', function() {
  //   //     newWindow.document.addEventListener('DOMContentLoaded', function() {
  //   //         var addressBar = newWindow.document.querySelector('input[type="url"]');
  //   //         if (addressBar) {
  //   //             addressBar.disabled = true;
  //   //         }
  //   //     });
  //   // });
  // };

  const openConnect = () => {
    const url = agent?.aicConfig?.ccp_url.replace("/ccp-v2", "");
    window.open(url, "_blank");
  };

  return (
    <Flex
      UNSAFE_className="headerWrapper"
      justifyContent={"space-between"}
      alignItems={"center"}
      data-testid="headerMain"
    >
      <Flex alignItems="center" UNSAFE_className="left-content-header">
        <Flex>
          <img src={AdobeLogo} className="adobeLogo" alt="Adobe Logo" />
          <Text UNSAFE_className="aic-logo">AIC</Text>
        </Flex>
      </Flex>
      {agent.firstName && (
        <Flex>
          <Flex UNSAFE_className="versionInfo">
            <Flex>
              <QueueSelection />
            </Flex>
            <Flex UNSAFE_className="agentStatusChangePicker">
              <AgentStatusChange />
            </Flex>
            {sessionState.loggedIn && <MultiInstanceChange />}
            <Divider size="S" orientation="vertical" />
            <View>
              {sessionState.loggedIn && (
                <Button
                  height={"20px"}
                  variant="secondary"
                  style="fill"
                  data-testid="open-connect-button"
                  UNSAFE_className="amazon-connect-button"
                  target="_blank"
                  onPress={openConnect}
                >
                  <ConnectIcon />
                  <Text
                    marginStart={"2px"}
                    UNSAFE_className="amazon-connect-btn"
                  >
                    Amazon Connect
                  </Text>
                </Button>
              )}
            </View>
            <View>
              <BellIcon />
            </View>

            <Flex alignItems={"center"}>
              {/* <View
                marginEnd={6}
                marginBottom={4}
                UNSAFE_style={{ fontSize: "0.785rem" }}
              >
                <Text>{APP_VERSION ? `v${APP_VERSION}` : ""}</Text>
              </View> */}
              <View UNSAFE_className={"settingsButtonWrapper"}>
                <MenuTrigger>
                  <ActionButton UNSAFE_className={"settingsButton"}>
                    {agent.firstName
                      ? (
                          agent.firstName[0] +
                          (agent.lastName ? agent.lastName[0] : "")
                        ).toUpperCase()
                      : "NA"}
                  </ActionButton>
                  <Menu
                    onAction={(key: Key) => handleLogout(key)}
                    disabledKeys={
                      call.id ||
                      (callback.id && callback.self?.id) ||
                      chat.chats.length
                    }
                  >
                    {/**right now we have only logout menu item here */}
                    {menuOptions.map((item: any) => (
                      <Item key={item.id}>
                        <Text UNSAFE_style={{ fontSize: "0.75rem" }}>
                          {item.name}
                        </Text>
                      </Item>
                    ))}
                  </Menu>
                </MenuTrigger>
              </View>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default HeaderComponent;
