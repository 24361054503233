import {
  ACTIVE_CASE_UPDATE,
  CASE_CONNECTED_TIME,
  CASE_RESET,
  CASE_SET,
  CASE_SET_SUGGEST_ISSUE,
  CASE_SET_SUMMARY_ISSUE,
  INDIVIDUAL_CASE_RESET,
  INITIAL_CASE_SET,
  LOGOUT,
  REROUTE_CASE_DATA,
  RESET_STATE,
  STORE_LOGIN,
} from "../constants/action-types";
import { Case } from "../types/case.type";

const initialState: Case = {
  cases: [],
  activeCaseID: "",
};
export default (state = initialState, action: any) => {
  switch (action.type) {
    case CASE_SET: {
      let caseId = action.indCase.id;

      let existingIndex = state.cases.findIndex((c: any) => caseId === c.id);
      let cases = state.cases;
      if (existingIndex > -1) {
        cases[existingIndex] = {
          ...cases[existingIndex],
          ...action.indCase,
        };
      } else {
        cases.push(action.indCase);
      }
      return {
        ...state,
        cases,
      };
    }

    case CASE_CONNECTED_TIME: {
      let caseId = action.caseID;
      let caseConnectedTime: any = action.connectedTime;
      let caseCopy = [...state.cases];
      let caseIndex = caseCopy.findIndex(
        (currentValue) => currentValue.id === caseId
      );
      caseCopy[caseIndex] = {
        ...caseCopy[caseIndex],
        caseConnectedTime,
      };
      return {
        ...state,
        cases: caseCopy,
      };
    }

    case CASE_SET_SUGGEST_ISSUE: {
      const {
        caseID,
        userResponse,
        aiResponse,
        showSuggestDialog,
        isLiked,
        isDisliked,
        isDislikeFeedbackOpen,
        dislikeReason,
        uniqueId,
      } = action;

      const caseCopy = [...state.cases];
      const caseIndex = caseCopy.findIndex(
        (currentValue) => currentValue.id === caseID
      );

      if (caseIndex !== -1) {
        const caseToUpdate = caseCopy[caseIndex];
        const messageIndex = caseToUpdate.suggestCaseMessages?.findIndex(
          (message: any) => message.uniqueId === uniqueId
        );

        let updatedMessages;
        if (messageIndex !== undefined && messageIndex !== -1) {
          // Check and update existing chat message
          updatedMessages = caseToUpdate.suggestCaseMessages.map(
            (message: any, index: number) =>
              index === messageIndex
                ? {
                    ...message,
                    userResponse,
                    aiResponse,
                    showSuggestDialog:
                      showSuggestDialog !== undefined
                        ? showSuggestDialog
                        : message.showSuggestDialog,
                    isLiked: isLiked !== undefined ? isLiked : message.isLiked,
                    isDisliked:
                      isDisliked !== undefined
                        ? isDisliked
                        : message.isDisliked,
                    isDislikeFeedbackOpen:
                      isDislikeFeedbackOpen !== undefined
                        ? isDislikeFeedbackOpen
                        : message.isDislikeFeedbackOpen,
                    dislikeReason:
                      dislikeReason !== undefined
                        ? dislikeReason
                        : message.dislikeReason,
                  }
                : message
          );
        } else {
          // Add new chat message
          updatedMessages = [
            ...(caseToUpdate.suggestCaseMessages || []),
            {
              userResponse,
              aiResponse,
              showSuggestDialog:
                showSuggestDialog !== undefined
                  ? showSuggestDialog
                  : caseToUpdate.showSuggestDialog,
              isLiked: isLiked !== undefined ? isLiked : caseToUpdate.isLiked,
              isDisliked:
                isDisliked !== undefined ? isDisliked : caseToUpdate.isDisliked,
              isDislikeFeedbackOpen:
                isDislikeFeedbackOpen !== undefined
                  ? isDislikeFeedbackOpen
                  : caseToUpdate.isDislikeFeedbackOpen,
              dislikeReason:
                dislikeReason !== undefined
                  ? dislikeReason
                  : caseToUpdate.dislikeReason,
              uniqueId:
                uniqueId !== undefined ? uniqueId : caseToUpdate.uniqueId,
            },
          ];
        }

        caseCopy[caseIndex] = {
          ...caseToUpdate,
          suggestCaseMessages: updatedMessages,
        };
      }

      return {
        ...state,
        cases: caseCopy,
      };
    }

    case CASE_SET_SUMMARY_ISSUE: {
      const { caseID, userResponse, aiResponse } = action;
      const caseCopy = [...state.cases];
      const caseIndex = caseCopy.findIndex(
        (currentValue) => currentValue.id === caseID
      );

      if (caseIndex !== -1) {
        const caseToUpdate = caseCopy[caseIndex];
        const updatedMessages = caseToUpdate.summaryCaseMessages
          ? [...caseToUpdate.summaryCaseMessages, { userResponse, aiResponse }]
          : [{ userResponse, aiResponse }];

        caseCopy[caseIndex] = {
          ...caseToUpdate,
          summaryCaseMessages: updatedMessages,
        };
      }

      return {
        ...state,
        cases: caseCopy,
      };
    }

    case INITIAL_CASE_SET: {
      return {
        ...state,
        ...action.indCase,
      };
    }

    case ACTIVE_CASE_UPDATE: {
      const caseId = action.caseID;
      return {
        ...state,
        activeCaseID: caseId,
      };
    }

    case LOGOUT:
    case CASE_RESET:
      return { cases: [], activeCaseID: "" };
    case INDIVIDUAL_CASE_RESET: {
      let caseId = action.indCase.id;
      let caseCopy = [...state.cases];
      let cases = caseCopy.filter((currentValue) => currentValue.id !== caseId);
      return {
        ...state,
        cases,
      };
    }
    case STORE_LOGIN:
      return {
        ...state,
        ...action.payload.cases,
      };
    case RESET_STATE:
      return { cases: [], activeCaseID: "" };
    case REROUTE_CASE_DATA: {
      let caseId = action.caseID;
      let caseRerouteData: any = action.caseRerouteData;
      let caseCopy = [...state.cases];
      let caseIndex = caseCopy.findIndex(
        (currentValue) => currentValue.id === caseId
      );
      caseCopy[caseIndex] = {
        ...caseCopy[caseIndex],
        caseRerouteData,
      };
      return {
        ...state,
        cases: caseCopy,
      };
    }
    default:
      return state;
  }
};
