import {
  Item,
  TabList,
  TabPanels,
  Tabs,
  View,
  Text,
  StatusLight,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import storeRTK from "../../../store/storeRTK";
import { setSelectedTab } from "../../../actions/common";
import "./index.css";
import { RootState } from "../../../store";
import { JSX } from "react";

interface Tab {
  name: string;
  children: JSX.Element;
}

interface TabSwitchProps {
  tabs: Tab[];
  history: any[];
  isActiveCall: string;
}

const TabSwitch = ({ tabs, history, isActiveCall }: TabSwitchProps) => {
  const common = useSelector((state: RootState) => state.common);
  const session = useSelector((state: RootState) => state.session);
  const call = useSelector((state: RootState) => state.call);

  const handleTabChange = (value: any) => {
    storeRTK.dispatch(setSelectedTab(value));
  };

  return (
    <View flexGrow={1} backgroundColor="static-white" height={"100%"}>
      <Tabs
        selectedKey={common.tab || ""}
        onSelectionChange={handleTabChange}
        height={"100%"}
        aria-label="Tab switch"
        data-testid="tab-switch"
      >
        <TabList UNSAFE_className="tab-item">
          {tabs.map((tab) => (
            <Item
              key={tab.name}
              textValue={tab.name}
              aria-label="tab heading"
              data-testid="tab-heading"
            >
              <Text UNSAFE_className="tab-item-label">{tab.name}</Text>
              <div className="tab-switch-container">
                {tab.name === "Call" && isActiveCall && (
                  <StatusLight
                    role="status"
                    variant="negative"
                    UNSAFE_className="call-status-light"
                    aria-label="Call status light"
                  />
                )}
                {tab.name === "History" && (
                  <div>
                    {history?.length > 0 && call?.callCount > 0 && (
                      <span className="tab-switch-count">
                        {session.currentTab === "call"
                          ? call?.callCount
                          : history.length}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </Item>
          ))}
        </TabList>
        <TabPanels UNSAFE_style={{ border: "0" }} height={"85%"}>
          {tabs.map((tab) => (
            <Item key={tab.name} aria-label="tab item" data-testid="tab-panel">
              {tab.children}
            </Item>
          ))}
        </TabPanels>
      </Tabs>
    </View>
  );
};

export default TabSwitch;
