import { useMemo } from "react";
import {
  Flex,
  View,
  Text,
  TabList,
  Tabs,
  Item,
  TabPanels,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/storeConfig";
import "./style.css";
import { API_CALL_DIAL } from "../../../constants/constants";
import Emitter from "../../../emitter";
import { formatTime, getCallIcon, getInitialsAndColor } from "../../../utils";
import { CallParticipant } from "../../../types/call.type";

const RecentCalls = () => {
  // Only select the relevant slices of state
  const conversations = useSelector((state: RootState) => state.history?.conversations || []);
  const vpnStatus = useSelector((state: RootState) => state.webrtc?.isVoiceNetworkAvailable);

  // Memoize the callersData to prevent unnecessary recalculations on re-renders
  const callersData = useMemo(() => {
    return conversations.slice(0, 5).map((callerList: any) => {
      const { initials, backgroundColor } = getInitialsAndColor("N A");
      return { callerList, initials, backgroundColor };
    });
  }, [conversations]);

  // Handle the call initiation
  const dialCall = (customer: CallParticipant) => {
    const phoneNumber = customer.displayName || customer.attributes.Customer_ANI;
    Emitter.emit(API_CALL_DIAL, { phoneNumber });
  };

  return (
    <View width={"100%"} height={"100%"}>
      <Flex direction={"column"} height={"85%"} margin={"auto"}>
        <Tabs aria-label="Recent calls" UNSAFE_className="recent-call-tabs">
          <TabList UNSAFE_className="recent-call-heading">
            <Item key="recent-call">Recent Calls</Item>
            <Item key="favourites-call">Favourites</Item>
          </TabList>
          <TabPanels
            UNSAFE_style={{
              border: "0",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <Item key="recent-call">
              <View UNSAFE_className="recent-call-container">
                {callersData.length > 0 ? (
                  callersData.map((data: any, index: number) => {
                    const { callerList, initials, backgroundColor } = data;
                    const { connectedTime, endTime, missed } = callerList?.self || {};
                    const { formattedConnectedTime, formattedDifference } = formatTime(connectedTime, endTime);

                    return (
                      <View
                        UNSAFE_style={index !== 0 ? { borderTop: "1px solid #D5D5D5" } : {}}
                        key={callerList.id + index}
                        UNSAFE_className="call-recent-items"
                      >
                        <Flex UNSAFE_className="call-recent-list" alignItems={"center"} height={"100%"}>
                          <Flex>
                            <View UNSAFE_className="recent-call-avatar">
                              <Flex
                                justifyContent="center"
                                alignItems="center"
                                UNSAFE_className="agent-avatar"
                                UNSAFE_style={{
                                  backgroundColor,
                                  borderRadius: "50%",
                                }}
                              >
                                <Text UNSAFE_className="call-monitor-agent-text">{initials}</Text>
                              </Flex>
                            </View>
                          </Flex>
                          <Flex width={"100%"} justifyContent={"space-between"} alignItems={"end"}>
                            <View UNSAFE_className="recent-call-box">
                              <Flex direction="column" alignItems="start" UNSAFE_className="call-history-list">
                                <Text UNSAFE_className="call-history-title">
                                  {callerList.customer.attributes.Customer_ANI ||
                                    callerList.customer.displayName ||
                                    callerList.customer?.phoneNumber ||
                                    "-"}
                                </Text>
                                <Text
                                  UNSAFE_style={{
                                    textAlign: "start",
                                    color: "#D7373F",
                                  }}
                                >
                                  <Flex
                                    UNSAFE_className={`call-history-subtitle ${
                                      missed ? "call-missed" : "call-attend"
                                    }`}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    marginTop={4}
                                  >
                                    <img
                                      src={getCallIcon(callerList)}
                                      className="call-history-icon"
                                      alt="Missed call icon"
                                    />
                                    <Text marginX={4} UNSAFE_style={{ whiteSpace: "nowrap" }}>
                                      {formattedConnectedTime}
                                    </Text>
                                  </Flex>
                                </Text>
                              </Flex>
                            </View>
                            <View>
                              <Flex direction="column">
                                <Text UNSAFE_className="recent-call-time-diff">{formattedDifference}</Text>
                              </Flex>
                            </View>
                            <Flex
                              direction="column"
                              alignSelf={"center"}
                              UNSAFE_className="recent-call-btn"
                            >
                              <button
                                onClick={() => dialCall(callerList.customer)}
                                disabled={!vpnStatus}
                                data-testid="call-button"
                              >
                                Call
                              </button>
                            </Flex>
                          </Flex>
                        </Flex>
                      </View>
                    );
                  })
                ) : (
                  <Text marginTop={20}>No data found</Text>
                )}
              </View>
            </Item>
            <Item key="favourites-call">
              <View marginTop={20}>No data found</View>
            </Item>
          </TabPanels>
        </Tabs>
      </Flex>
    </View>
  );
};

export default RecentCalls;
