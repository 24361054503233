export const TELEMETRY_ERROR = 'aicweb.TelemetryError';
export const TELEMETRY_INFO = 'aicweb.TelemetryInfo';
export const BG_ERROR = 'bg.Error';
export const BG_WARNING = 'bg.Warning';
export const BG_INITIATE_LOGIN = 'bg.InitiateLogin';
export const BG_INITIATE_RELOGIN = 'bg.InitiateRelogin';
export const WEBRTC_ERROR = 'webrtc.Error';
export const RESET_WEBRTC_ERROR = 'reset.Webrtc.Error';
export const EX_UNINSTALLED = 'ex.uninstalled';
export const BG_NOTIFICATION = 'bg.Notification';
export const SESSION_ERROR = 'session.Error';
export const SHOW_CCP_CONNECTION_ERROR = 'show.CCPConnectionError';
export const RESET_SESSION_ERROR = 'reset.Session.Error';
export const SET_MEDIA_PERM = 'set.Media.Perm';
export const BG_TAB_CHANGE = 'bg.Tab.Change';
export const WEBSOCKET_ERROR = 'websocket.Error';
export const BG_LOGIN_DETAILS = 'bg.LoginDetails';
export const WEBRTC_SET_HOST_TOKEN= 'webrtc.set.host.token';
export const CCP_STATE = 'ccp.state';
export const INTERNAL_LOGIN_DETAILS = 'internal.LoginDetails';
export const INTERNAL_CLEAR_WEBRTC_SESSION = "interna.clear.webrtc.session";
export const INTERNAL_RESET_CCP_STATE = 'internal.reset.ccp.state';
export const INTERNAL_CALL_API = 'internal.call.api';
export const WEBRTC_WINDOW_CLOSED = 'webrtc.window.closed';
export const WEBRTC_SET_SESSION= 'webrtc.set.session';
export const WEBRTC_RESET_SESSION= 'webrtc.reset.session';
export const BG_BROADCAST = 'bg.Broadcast';
export const BG_HISTORY_CHANGE = 'bg.historyChange';
export const BG_RTC_DISCONNECT = 'bg.rtc.disconnect';
export const INIT_CCP = 'init.ccp';

// Permission check Events
export const BG_MEDIA_PERM_CHECK = 'bg.MediaPermCheck';

export const WT_PING = 'wt.Ping';
export const WT_PONG = 'wt.Pong';

export const STATE_UPDATE_RING_MEDIA_DEVICE = 'state.updateRingMediaDevice';

export const STATE_UPDATE_ROD_DEVICE = 'state.updateRODevice';
export const TRANSFER_CHAT_IN_PROGRESS = 'state.transferChatInProgress';
export const SHOW_SELECTED_ATTACHMENT="state.showSelectedAttachment";
export const SHOW_UPLOADING_ATTACHMENT="state.showUploadingAttachment";

export const STATE_UPDATE_VI_DEVICE = 'state.updateVIDevice';

export const STATE_UPDATE_VO_DEVICE = 'state.updateVODevice';
export const BG_ASK_MEDIA_PERM = 'bg.AskMediaPerm';

export const BG_RTC_RECONNECT = 'bg.rtcReconnect';
export const START_SESSION = 'session.Start';
export const STOP_SESSION = 'session.Stop';
export const FAIL_SESSION = 'session.Fail';
export const INTERNAL_CALL_RELEASE = 'internal.call.release';
export const INTERNAL_CALLBACK_RELEASE = 'internal.callback.release';
export const INTERNAL_CHAT_RELEASE = 'internal.chat.release';
export const INTERNAL_CASE_RELEASE = 'internal.case.release';
export const INTERNAL_NETWORK_DISCONNECT = 'internal.network.disconnect';
export const INTERNAL_NETWORK_DISCONNECT_LOGOUT =
    'internal.network.disconnect.logout';
export const INTERNAL_SESSION_START = 'internal.session.start';
export const INTERNAL_SESSION_RESTART = 'internal.session.restart';
export const INTERNAL_API_CHAT_HISTORY = 'internal.apiChat.history';
export const INTERNAL_OPEN_WINDOW = "internal.open.window";
export const STATE_AGENT = 'state.Agent';
export const STATE_CHAT = 'state.Chat';
export const STATE_CHAT_HISTORY = 'state.ChatHistory';
export const STATE_WEBCHATMESSAGE = 'state.WebChatMessage';
export const STATE_ACW = 'state.ACW';
export const STATE_CANNEDRESPONSES = 'state.CannedResponses';

export const STATE_CALL = 'state.Call';
export const STATE_CALLBACK = 'state.Callback';
export const API_TRANSFER_FAILURE = 'api.transfer.failure';
export const STATE_CHANGE_INSTANCE = 'state.change.instance';


// Client Side Events
// Call api events
export const API_CALL_DIAL = 'api.callDial';
export const API_CALL_ANSWER = 'api.callAnswer';
export const API_CALL_REJECT = 'api.callReject';
export const API_CALL_HANGUP = 'api.callHangup';
export const API_CALL_HOLD = 'api.callHold';
export const API_CALL_PLAY = 'api.callPlay';
export const API_CALL_TRANSFER = 'api.callTransfer';
export const API_CALL_INITIATE_WARM_TRANSFER = 'api.callInitiateWarmTransfer';
export const API_CALL_COMPLETE_WARM_TRANSFER = 'api.callCompleteWarmTransfer';
export const API_CALL_SEND_DTMF = 'api.sendDTMF';
export const API_CALL_PATCH_ATTRIBUTE = 'api.patchAttribute';
export const API_CALL_MUTE = 'api.callMute';
export const API_CALL_WRAPUP_CODE = 'api.callWrapupCode';
export const API_CALL_WRAPUP = 'api.callWrapup';
export const API_CALL_UPDATE = 'api.callUpdate';
export const API_CALL_TRANSFER_ENDPOINTS = 'api.call.transfer.endpoints';
export const API_MILO_CHANNEL_TRANSACTION = 'api.milo.channelTransaction';

// Callback api events
export const API_CALLBACK_UPDATE = 'api.callbackUpdate';
export const API_CALLBACK_ANSWER = 'api.callbackAnswer';
export const API_CALLBACK_REJECT = 'api.callbackReject';
export const API_CALLBACK_HANGUP = 'api.callbackHangup';
export const API_CALLBACK_WRAPUP = 'api.callbackWrapup';
export const API_CALLBACK_DIAL = 'api.callbackDial';
export const API_MILO_VOICEMAIL_CHANNEL_TRANSACTION = 'api.milo.voicemailChannelTransaction';

// Chat api events
export const API_CHAT_ANSWER = 'api.chatAnswer';
export const API_CHAT_REJECT = 'api.chatReject';
export const API_SENDCHATMESSAGE = 'api.sendChatMessage';
export const API_SENDCHATTYPING = 'api.sendChatTyping';
export const API_CHAT_PATCH_ATTRIBUTE = 'api.patchChatAttribute';
export const API_CHAT_TRANSFER = 'api.chatTransfer';
export const API_CHAT_HISTORY = 'api.chatHistory';
export const API_CHECK_FOR_LOST_MESSAGE = 'api.checkLostMessage';
export const API_CHAT_UPDATE = 'api.chatUpdate';
export const API_CHAT_WRAPUP = 'api.chatWrapup';
export const API_ACTIVE_CHAT_UPDATE = 'api.activeChatUpdate';
export const API_ACTIVE_CASE_UPDATE = 'api.activeCaseUpdate';
export const API_CHAT_TRANSFER_ENDPOINTS = 'api.chat.transfer.endpoints';
export const API_MILO_CHAT_CHANNEL_TRANSACTION = 'api.milo.chatChannelTransaction';

// Case api events
export const API_CASE_ANSWER = 'api.caseAnswer';
export const API_CASE_REJECT = 'api.caseReject';
export const API_CASE_UPDATE = 'api.caseUpdate';
export const API_EMAIL_WRAPUP = 'api.emailWrapup';
export const API_MILO_CASE_CHANNEL_TRANSACTION = 'api.milo.caseChannelTransaction';
export const API_CASE_AUTO_ANSWER = 'api.caseAutoAnswer';

//Audio output device related events
export const API_UPDATE_RING_MEDIA_DEVICE = 'api.updateRingMediaDevice';
export const API_UPDATE_ROD_DEVICE = 'api.updateRODevice';
export const API_UPDATE_VI_DEVICE = 'api.updateVIDevice';
export const API_UPDATE_VO_DEVICE = 'api.updateVODevice';

// Other API events
export const API_GET_USER = 'api.getUser';
export const API_CHANGE_AGENT_STATE = 'api.changeAgentState';
export const STATE_VOICEMAIL = 'state.voicemail';
export const API_AGENT_GROUP_STATUS = 'api.agentGroupStatus';
export const API_QUEUE_JOIN_UPDATE = 'api.queueJoinUpdate';
export const API_TAB_CHANGE = 'api.tabChange';
export const API_RESTORE_UTILIZATION = 'api.restoreUtilization';
export const API_CLEAR_VOICE_UTILIZATION = 'api.clearVoiceUtilization';
export const API_AIL_HEALTH = 'api.ailHealth';
export const API_WINDOW_RELOAD = 'api.windowReload';

export const CHANGE_CALL_AGENT_STATUS = 'internal.changeCallAgentStatus';
export const CHANGE_CHAT_AGENT_STATUS = 'internal.changeChatAgentStatus';
export const CHANGE_CASE_AGENT_STATUS = 'internal.changeCaseAgentStatus';
export const STATE_CASE='state.Task';

export const STATE_LOGIN = 'state.login';
export const SESSION_STATE = 'session.State';
export const SESSION_TIMER_ALERT = 'session.Timer.Alert';

export const START_CCP = 'start.ccp';
export const RESTART_CCP = 'restart.ccp';

export const UPDATE_MEDIA_DEVICES_LIST = 'wb.updateMediaDeviceList';

// ================ Below are not exist in emitter =============================================
export const BG_MESSAGE = 'bg.Message';
export const BG_ERROR_MESSAGE = 'bg.Error.Message';
export const WEBRTC_TOKENMISSING_ERROR = 'webrtc.TokenMissing.Error';
export const SESSIONID_MISSING_ERROR = 'sessionId.Missing.Error';
export const VPN_DISCONNECT = 'vpn.Disconnect';
export const VPN_RECONNECT = 'vpn.Reconnect';
export const PING = 'ping';
export const PONG = 'pong';
export const CCP_PING = 'ccp.Ping';
export const CCP_PONG = 'ccp.Pong';
export const STOP_CCP = 'stop.ccp';
export const BG_NETWORK_DISCONNECT = 'bg.network.disconnect';
export const INTERNAL_CHANGE_AGENT_STATE = 'internal.agent.state.change';
export const INTERNAL_VPN_STATUS_UPDATE="internal.vpn.status.update";
export const BG_EXTENSION_INSTALLED = 'bg.extension.Installed';

// Ringtone related events
export const API_TEST_RING_PLAY = 'api.testRing.play';
export const BG_CALLRING_PLAY = 'bg.callring.play';
export const BG_CALLRING_PAUSE = 'bg.callring.pause';
export const BG_CALLBACKRING_PLAY = 'bg.callbackring.play';
export const BG_CALLBACKRING_PAUSE = 'bg.callbackring.pause';
export const BG_CHATRING_PLAY = 'bg.chatring.play';
export const BG_CHATRING_PAUSE = 'bg.chatring.pause';
export const BG_MESSAGERING_PLAY = 'bg.messagering.play';
export const BG_CASESRING_PLAY = 'bg.casesring.play';
export const BG_CASESRING_PAUSE = 'bg.casesring.pause';

// Other events
export const BG_CASE_RELEASE = 'bg.case.release';
export const SHOW_DISCONNECT = 'SHOW_DISCONNECT';
export const HIDE_DISCONNECT = 'HIDE_DISCONNECT';

export const CCP_CONNECTION_SUCCESS = 'ccp.connection.success';
export const CCP_ASK_MEDIA_PERMISSION = 'ccp.AskMediaPerm';
export const OPEN_MEDIA_PERMISSION_TAB = 'open.MediaPermTab';
export const VOICE_NETWORK_STATE = 'voice.network.state';
export const API_DOWNLOAD_ATTACHMENT = 'api.downloadAttachment';
export const API_DOWNLOAD_ATTACHMENT_STATUS = 'api.downloadAttachment.Status';
export const API_SEND_ATTACHMENT = 'api.sendAttachment';
export const API_SEND_ATTACHMENT_STATUS = 'api.sendAttachment.Status';
export const BG_API_STATUS = 'bg.Api.Status';
export const API_SEND_ATTACHMENT_DOWNLOAD_SAVEAS = 'api.download.fileSaveas';
export const SESSION_LOGOUT_MESSAGE = 'session.logout.message';

// Download Logs
export const API_DOWNLOAD_LOGS = 'api.download.logs';
export const API_DOWNLOAD_LOGS_STATUS = 'api.downloadLogs.Status';
// Contact Event LOGGING API 
export const API_CONTACT_EVENT_LOGGING = 'api.contactEventLogging';

//Supervisor
export const MONITORING_ERROR = 'supervisor.monitoring.error';
export const AGENT_STATUS_ERROR = 'supervisor.status.error';