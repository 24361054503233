import { connectSession } from './session';
import { CONTACT_EVENT_STATE_MAP, CONTACT_STATE_MAP, CHANNEL_TYPES, LOG_PREFIX } from '../../constants/constants';
import { Voicemail } from '../models/voicemail';
import { formatCaseAttributes } from '../../utils';
import { changeAgentStateToAvailable } from './agent';
import { clearCurrentVoicemailContact } from '../api/voicemail';
const lp = LOG_PREFIX + "[handlers.voicemail.ts]: ";

const defaultValue = {
    self: {
        callback: {}
    },
    customer: {
    }
}
export function handleIncomingVoicemail(contact: any) {
    console.log(lp + ' INCOMING voicemail contact: ', contact);
    updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.INCOMING, contact);
    return {contactId: contact.contactId || contact.getContactId(), eventType: CONTACT_EVENT_STATE_MAP.RINGING, channel: CHANNEL_TYPES.VOICEMAIL};
}
export function handleConnectingVoicemail(contact: any) {
    console.log(lp + ' CONNECTING voicemail contact: ', contact);
    //updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.CONNECTING, contact);
}

export function handleAcceptedVoicemail(contact: any) {
    console.log(lp + ' ACCEPTED Contact accepted by agent. Contact state is ' + contact.getStatus().type, contact);
    //updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.ACCEPTED, contact);
}
export function handleConnectedVoicemail(contact: any) {
    console.log(lp + ' CONNECTED voicemail contact connected to agent. Contact state is ' + contact.getStatus().type);
    updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.CONNECTED, contact);

    // Send message to bg to open milo URL
    return { contactId: contact.contactId || contact.getContactId()};
}

export function handleRefreshVoicemail(contact: any) {
    console.log(lp + ' REFRESH voicemail contact: ', contact);
    try {
        updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.REFRESH, contact);
    } catch(e) {
        handleEndedVoicemail(contact);
    }
}
export function handleMissedVoicemail(contact: any) {
    console.log(lp + ' MISSED voicemail contact: ', contact);
    changeAgentStateToAvailable();
    clearCurrentVoicemailContact((contact.contactId || contact.getContactId()));
    updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.MISSED, contact);
    return {contactId: contact.contactId || contact.getContactId(), eventType: CONTACT_EVENT_STATE_MAP.MISSED, channel: CHANNEL_TYPES.VOICEMAIL};
}

export function handleDestroyVoicemail(contact: any) {
    console.log(lp + ' DESTROY voicemail contact: ', contact);
    updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.DESTROYED, contact);
}
export function handleEndedVoicemail(contact: any) {
    console.log(lp + ' ENDED voicemail contact has ended. Contact state is ' + contact.getStatus().type);
    updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.ENDED, contact);
    if (contact.getStatus().type === 'error') {
        console.log(lp + 'Error in voicemail contact', contact.getStatus().error);
    }
}

export function updateVoicemailAndSend(type: any, contact: any) {
    const vm = Object.assign({}, connectSession.getVoicemail());
    const updatedVm = updateVoicemail(type, contact);
    if(JSON.stringify(vm) === JSON.stringify(updatedVm)) return;
    connectSession.setVoicemail(updatedVm);
    return updatedVm;
}

function updateVoicemail(contactEventType: string, contact: any): Voicemail | null {
    try {
        const vm = connectSession.getVoicemail() || defaultValue;
        
        switch(contactEventType) {
            case CONTACT_EVENT_STATE_MAP.INITIAL:
                const voicemailAttributes = formatCaseAttributes(contact.getAttributes());
                vm.id = contact.contactId || contact.getContactId();
                vm.self.id = contact.getAgentConnection().getConnectionId();
                vm.selfState = CONTACT_STATE_MAP.INCOMING; 
                vm.conversationMedia = "CALLBACK";
                vm.self.userId = contact.getAgentConnection().getConnectionId();
                vm.self.queueId = contact.getAgentConnection().getConnectionId();
                vm.self.type = 'AGENT';
                vm.self.attributes = {};
                vm.self.state = CONTACT_STATE_MAP.INCOMING;
                vm.self.callback = {
                    id: contact.contactId || contact.getContactId(),
                    voicemailId: contact.contactId || contact.getContactId(),
                    callbackNumber: voicemailAttributes.PHONE_NUMBER,
                    state: CONTACT_STATE_MAP.INCOMING,
                    connectedTime: null,
                    endTime: null,
                }
                vm.customer.state = CONTACT_STATE_MAP.CONNECTED;
                vm.customer.attributes = voicemailAttributes;
                vm.customer.address = voicemailAttributes.PHONE_NUMBER;
                vm.customer.type = "CUSTOMER";
                break;
            
            case CONTACT_EVENT_STATE_MAP.CONNECTED:
                vm.self.callback.state = CONTACT_STATE_MAP.CONNECTED;
                vm.selfState = CONTACT_STATE_MAP.CONNECTED;
                vm.self.state = CONTACT_STATE_MAP.CONNECTED;
                if(!vm.self.callback.connectedTime) {
                    //console.log(`Connected time set ${new Date(contact.getStateDuration())}, time from new date ${new Date()}`);
                    vm.self.callback.connectedTime = new Date().getTime();
                    vm.self.connectedTime = new Date().getTime();
                }
                break;
    
            case CONTACT_EVENT_STATE_MAP.ENDED:
            case CONTACT_EVENT_STATE_MAP.MISSED:
            case CONTACT_EVENT_STATE_MAP.DESTROYED:
                vm.self.state = CONTACT_STATE_MAP.ENDED;
                vm.self.callback.state = CONTACT_STATE_MAP.ENDED;
                vm.selfState = CONTACT_STATE_MAP.ENDED;
                if(!vm.self.callback.endTime) {
                    vm.self.callback.endTime = new Date().getTime();
                    vm.self.endTime = new Date().getTime();
                }
                break;
            default:
                break;
        };
        return vm;
    } catch(err) {
        console.log(lp + ' Error in updating voicemail contactId: ' + contact.getContactId() + err);
        return null
    }
}