import { useEffect, useState } from "react";
import { Button, Flex, Grid, TextField } from "@adobe/react-spectrum";
import PhoneInput, { getCountryCallingCode } from "react-phone-number-input";
import { BackspaceIcon, CallIcon, CrossIcon } from "../../common/Icons";
import Emitter from "../../../emitter";
import {
  API_CALL_DIAL,
  CALL_CANNOT_DIAL,
  SHOW_NOTIFICATION,
} from "../../../constants/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/storeConfig";
import { showErrorToast } from "../../../utils/utils";
import { session } from "../../../controller/session";
import { API_CALL_SEND_DTMF } from "../../../constants/events";

const CallDialPad = () => {
  const call = useSelector((state: RootState) => state.call);
  let micStatus = useSelector((state: RootState) => state.session.isMicEnabled);
  let vpnStatus = useSelector(
    (state: RootState) => state.webrtc?.isVoiceNetworkAvailable
  );

  const [number, setNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<string>("US");
  const [dialInProgress, setDialInProgress] = useState(false);

  useEffect(() => {
    if (call.id != "") {
      setNumber("");
    }
  }, []);

  const renderDialer = (num: number) => {
    const displayNumMap: { [key: number]: any } = {
      10: "*",
      11: "0",
      12: "#",
      13: <BackspaceIcon />,
    };

    const displayNum = displayNumMap[num] ?? num;

    const handleBtnpress = (num: number) => {
      setNumber((prevNumber) => {
        const countryCode = selectedCountry
          ? getCountryCallingCode(selectedCountry as any)
          : "";
        if (prevNumber === "" && !call.id) prevNumber = "+" + countryCode;
        //handle E.164 format error
        if ((!call.id && num === 10) || (!call.id && num === 12)) {
          return prevNumber;
        }
        if (num === 13) {
          return prevNumber.slice(0, -1);
        } else if (num === 14) {
          return "";
        } else {
          if (call.id) {
            sendDtmf(displayNum);
          }
          return prevNumber + displayNum;
        }
      });
    };

    return (
      <button
        key={num}
        onClick={() => handleBtnpress(num)}
        className="dialer-btn"
      >
        {displayNum}
      </button>
    );
  };

  const validateNumber = (number: string) => {
    const regex = new RegExp(/^(?=.{1,30}$)([+]*[0-9a-zA-Z]+[#()+ -]*)/);
    return regex.test(number);
  };

  const makeCall = () => {
    setDialInProgress(false);
    if (!micStatus) {
      showErrorToast("Please enable microphone to receive a call", "micError");
      return;
    }
    if (!dialInProgress && micStatus) {
      const temp = number
        .replace(/ /g, "")
        .replace(/-/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "");

      if (validateNumber(number)) {
        setDialInProgress(true);
        if (navigator.onLine) {
          try {
            session
              .checkEligiblityForVoice()
              .then((response) => {
                console.log("response", response);
                if (response) {
                  Emitter.emit(API_CALL_DIAL, {
                    phoneNumber: temp.charAt(0) === "+" ? temp : "+" + temp, //Fix for +1 appended by CCP
                  });
                } else {
                  setDialInProgress(false);
                  Emitter.emit(SHOW_NOTIFICATION, {
                    message: CALL_CANNOT_DIAL,
                    title: "Error",
                    type: "warning",
                  });
                }
              })
              .catch(() => {
                setDialInProgress(false);
                Emitter.emit(SHOW_NOTIFICATION, {
                  message: CALL_CANNOT_DIAL,
                  title: "Error",
                  type: "warning",
                });
              });
          } catch (error) {
            setDialInProgress(false);
            Emitter.emit(SHOW_NOTIFICATION, {
              message: CALL_CANNOT_DIAL,
              title: "Error",
              type: "warning",
            });
          }
        } else {
          setDialInProgress(false);
          Emitter.emit(SHOW_NOTIFICATION, {
            message: CALL_CANNOT_DIAL,
            title: "Error",
            type: "warning",
          });
        }
      } else {
        showErrorToast("Please enter valid number", "invalidNumber");
      }
    }
  };
  const sendDtmf = (dtmf: string) => {
    Emitter.emit(API_CALL_SEND_DTMF, {
      body: { digits: dtmf },
    });
  };

  return (
    <Flex alignItems={"center"} justifyContent={"center"} width={"100%"}>
      <Flex justifyContent={"center"} direction={"column"}>
        <Flex direction={"row"} width={"100%"}>
          {!call.id! ? (
            <PhoneInput
              value={number}
              onChange={(value) => setNumber(value ?? "")}
              international
              className={"call-input"}
              onCountryChange={(country: any) => setSelectedCountry(country)}
              defaultCountry={"US"}
            />
          ) : (
            <TextField
              width={"100%"}
              value={number}
              label=""
              UNSAFE_className="call-dialpad-input"
              onChange={(value: string) => {
                setNumber(value);
                sendDtmf(value.charAt(value.length - 1));
              }}
              aria-label="call dialpad input"
              isQuiet
            />
          )}
          <span onClick={() => setNumber("")} className="cross-btn">
            <CrossIcon color="#000" />
          </span>
        </Flex>
        <Flex justifyContent={"center"} marginTop={20} width={"100%"}>
          <Grid
            areas={["1 2 3", "4 5 6", "7 8 9", "* 0 #", "call"]}
            columns={["1fr", "1fr", "1fr"]}
            rows={["1fr", "1fr", "1fr", "1fr", "auto"]}
            alignItems="center"
            justifyItems="center"
            height="100%"
            width={"100%"}
            gap={0}
            rowGap={7}
          >
            {Array.from({ length: 12 }, (_, i) => i + 1).map((num) =>
              renderDialer(num)
            )}
            <Flex gridColumn={call.id ? "span 3" : "span 1"}>
              {renderDialer(13)}
            </Flex>
            {!call.id && (
              <Flex marginStart={"10px"} width={"100%"} gridColumn="span 2">
                <Button
                  onPress={makeCall}
                  aria-label="call-dial-button"
                  UNSAFE_className="call-dial-button"
                  isDisabled={!vpnStatus}
                  variant={"accent"}
                  width={"calc(100% - 12px)"}
                  maxWidth={"188px"}
                  height={"100%"}
                  maxHeight={"48px"}
                  data-testid="call-dial-button"
                >
                  <CallIcon fill="#FFFFFF" />
                  <span style={{ marginLeft: "10px" }}>Call</span>
                </Button>
              </Flex>
            )}
          </Grid>
        </Flex>
        {/* <Flex direction={"row"} marginTop={"10px"} flexBasis={2}>
          <Flex width={"100%"}>
            <button
              onClick={makeCall}
              aria-label="call-dial-button"
              className="call-dial-button"
              style={{ borderRadius: "24px", cursor: "pointer", flex: "2" }}
              disabled={!vpnStatus}
            >
              <Flex alignItems={"center"} justifyContent={"center"}>
                {call?.id ? (
                  <Text>Consult</Text>
                ) : (
                  <>
                    <CallIcon fill="#FFFFFF" />
                    <Text>&nbsp; Call</Text>
                  </>
                )}
              </Flex>
            </button>
          </Flex>
          <Flex marginStart={"10px"}>{renderDialer(13)}</Flex>
        </Flex> */}
      </Flex>
    </Flex>
  );
};

export default CallDialPad;
