import {
  updateAgentInfoCountry,
  updateAgentQueueAssigned,
} from "../actions/agent";
import { setLoader } from "../actions/common";
import { getAicServerUrl } from "../config";

import { DEFAULT_WRAPUP_CODE, COOKIE_AWS } from "../constants/common";
import {
  API_AGENT_GROUP_STATUS,
  API_CALL_DIAL,
  API_CALL_PATCH_ATTRIBUTE,
  API_CALL_TRANSFER,
  API_CALL_INITIATE_WARM_TRANSFER,
  API_CALL_COMPLETE_WARM_TRANSFER,
  API_CALL_SEND_DTMF,
  API_CALL_UPDATE,
  API_CHANGE_AGENT_STATE,
  API_GET_USER,
  API_CALL_WRAPUP_CODE,
  API_CALL_WRAPUP,
  API_CALL_REJECT,
  API_CALLBACK_UPDATE,
  API_CALLBACK_DIAL,
  API_CHAT_UPDATE,
  API_SENDCHATMESSAGE,
  API_SENDCHATTYPING,
  API_CHAT_TRANSFER,
  API_CHAT_PATCH_ATTRIBUTE,
  API_CHAT_WRAPUP,
  API_CHAT_HISTORY,
  API_CALLBACK_WRAPUP,
  API_CLEAR_VOICE_UTILIZATION,
  API_RESTORE_UTILIZATION,
  API_CASE_UPDATE,
  API_EMAIL_WRAPUP,
  API_QUEUE_JOIN_UPDATE,
  STATE_CANNEDRESPONSES,
  API_CALLBACK_REJECT,
  API_CHECK_FOR_LOST_MESSAGE,
  API_CASE_REJECT,
  API_DOWNLOAD_ATTACHMENT,
  API_CALL_HANGUP,
  API_SEND_ATTACHMENT,
  API_CALL_ANSWER,
} from "../constants/events";
import { updateAgentGeoLocation } from "../controller/agent";
import { session } from "../controller/session";
import Emitter from "../emitter";
import storeRTK from "../store/storeRTK";
import {
  Agent,
  AssignQueues,
  CannedResponses,
  IndividualCannedResponses,
} from "../types/agent.type";
import { SessionEvent } from "../types/call.type";
import { CallbackListAPIResponse } from "../types/callback.type";
import {
  customFetch,
  fetchWithRetry,
  formatPhoneNumber,
  getInstanceAliasFromStore,
  makeUUID,
} from "../utils/utils";

export const CURRENT_DOMAIN = getAicServerUrl();

export function getUser({ toExpand }: { toExpand: string[] }) {
  return session.callApi({
    type: "req",
    chan: API_GET_USER,
    body: {
      toExpand,
    },
  });
}

export function answer() {
  const state = storeRTK.getState();
  return session.callApi({
    type: "req",
    chan: API_CALL_ANSWER,
    body: {
      participantId: state.call.self?.id,
      conversationId: state.call.id,
    },
  });
}
export function rejectCallback() {
  const state = storeRTK.getState();
  return session.callApi({
    type: "req",
    chan: API_CALLBACK_REJECT,
    body: {
      participantId: state.callback.self?.id,
      conversationId: state.callback.id,
      state: "disconnected",
    },
  });
}
export function hangupCallback() {
  const state = storeRTK.getState();
  return session.callApi({
    type: "req",
    chan: API_CALLBACK_UPDATE,
    body: {
      participantId: state.callback.self?.id,
      conversationId: state.callback.id,
      state: "disconnected",
    },
  });
}
export function answerCallback() {
  const state = storeRTK.getState();
  return session.callApi({
    type: "req",
    chan: API_CALLBACK_UPDATE,
    body: {
      participantId: state.callback.self?.id,
      conversationId: state.callback.id,
      state: "connected",
    },
  });
}
export function dialCallback({ phoneNumber }: { phoneNumber: string }) {
  const state = storeRTK.getState();
  return session.callApi({
    type: "req",
    chan: API_CALLBACK_DIAL,
    body: {
      phoneNumber,
      conversationId: state.callback.id,
    },
  });
}

export function answerChat({
  participantId,
  conversationId,
}: {
  participantId: string;
  conversationId: string;
}) {
  return session.callApi({
    type: "req",
    chan: API_CHAT_UPDATE,
    body: {
      participantId,
      conversationId,
      state: "connected",
    },
  });
}
export function rejectChat({
  participantId,
  conversationId,
}: {
  participantId: string;
  conversationId: string;
}) {
  return session.callApi({
    type: "req",
    chan: API_CHAT_UPDATE,
    body: {
      participantId,
      conversationId,
      state: "disconnected",
    },
  });
}
export function sendChatMessage({
  communicationId,
  conversationId,
  message,
}: {
  communicationId: string;
  conversationId: string;
  message: string;
}) {
  return session.callApi({
    type: "req",
    chan: API_SENDCHATMESSAGE,
    body: {
      communicationId,
      contactId: conversationId,
      message,
    },
  });
}
export function sendChatTyping({
  communicationId,
  conversationId,
}: {
  communicationId: string;
  conversationId: string;
}) {
  return session.callApi({
    type: "req",
    chan: API_SENDCHATTYPING,
    body: {
      communicationId,
      contactId: conversationId,
    },
  });
}
export function reject() {
  const state = storeRTK.getState();
  return session.callApi({
    type: "req",
    chan: API_CALL_REJECT,
    body: {
      participantId: state.call.self?.id,
      conversationId: state.call.id,
    },
  });
}

export function dial({ phoneNumber }: { phoneNumber: string }) {
  const phoneNumberToDial = formatPhoneNumber(phoneNumber);
  return session.callApi({
    type: "req",
    chan: API_CALL_DIAL,
    body: {
      phoneNumber: phoneNumberToDial,
    },
  });
}

export function hold() {
  const state = storeRTK.getState();
  return session.callApi({
    type: "req",
    chan: API_CALL_UPDATE,
    body: {
      participantId: state.call.self?.id,
      conversationId: state.call.id,
      held: true,
    },
  });
}
export function transfer({
  attributes,
  queueId,
  userId,
  notes,
  customerId,
  transferEndpoint,
}: {
  attributes: any;
  queueId: string;
  userId: string;
  notes: string;
  customerId: string;
  transferEndpoint: any;
}) {
  console.log("transferEndpoint", transferEndpoint);
  const state = storeRTK.getState();
  addTransferNoteAttribute(state.call.id, notes);
  return session.callApi({
    type: "req",
    chan: API_CALL_TRANSFER,
    body: {
      attributes,
      participantId: state.call.self?.id,
      conversationId: state.call.id,
      queueId,
      userId,
      notes,
      customerId,
      transferEndpoint,
    },
  });
}
export function chatTransfer({
  queueId,
  participantId,
  conversationId,
  transferEndpoint,
}: {
  queueId: string;
  participantId: string;
  conversationId: string;
  transferEndpoint: any;
}) {
  return session.callApi({
    type: "req",
    chan: API_CHAT_TRANSFER,
    body: {
      queueId,
      participantId,
      conversationId,
      transferEndpoint,
    },
  });
}
export function initiateWarmTransfer({
  queueId,
  notes,
}: {
  queueId: string;
  notes: string;
}) {
  const state = storeRTK.getState();
  return session.callApi({
    type: "req",
    chan: API_CALL_INITIATE_WARM_TRANSFER,
    body: {
      participantId: state.call.self?.id,
      conversationId: state.call.id,
      queueId,
      notes,
    },
  });
}

export function completeWarmTransfer({
  queueId,
  notes,
}: {
  queueId: string;
  notes: string;
}) {
  const state = storeRTK.getState();
  return session.callApi({
    type: "req",
    chan: API_CALL_COMPLETE_WARM_TRANSFER,
    body: {
      participantId: state.call.self?.id,
      conversationId: state.call.id,
      queueId,
      notes,
    },
  });
}

export function agentGroupStatus() {
  const state = storeRTK.getState();
  return session.callApi({
    type: "req",
    chan: API_AGENT_GROUP_STATUS,
    body: {
      groupId: state.agent?.groupId || {},
    },
  });
}

export function sendDtmf({ digits }: { digits: string }) {
  const state = storeRTK.getState();
  return session.callApi({
    type: "req",
    chan: API_CALL_SEND_DTMF,
    body: {
      participantId: state.call.self?.id,
      conversationId: state.call.id,
      digits,
    },
  });
}
export function play() {
  const state = storeRTK.getState();
  return session.callApi({
    type: "req",
    chan: API_CALL_UPDATE,
    body: {
      participantId: state.call.self?.id,
      conversationId: state.call.id,
      held: false,
    },
  });
}

export function hangup() {
  const state = storeRTK.getState();
  let promise = session.callApi({
    type: "req",
    chan: API_CALL_HANGUP,
    body: {
      participantId: state.call.self?.id,
      conversationId: state.call.id,
      disconnected: true,
    },
  });
  promise.then((res) => {
    console.log("res from hangup", res);
  });
  return promise;
}

export function patchAttribute(attributes: any) {
  const state = storeRTK.getState();
  addTransferNoteAttribute(state.call.id, attributes?.notes);
  return session.callApi({
    type: "req",
    chan: API_CALL_PATCH_ATTRIBUTE,
    body: {
      participantId: state.call.customer?.id,
      conversationId: state.call.id,
      attributes,
    },
  });
}

export function chatPatchAttribute({
  notes,
  participantId,
  conversationId,
}: {
  notes: string;
  participantId: string;
  conversationId: string;
}) {
  const state = storeRTK.getState();
  addTransferNoteAttribute(state?.chat?.activeChatID, notes);
  return session.callApi({
    type: "req",
    chan: API_CHAT_PATCH_ATTRIBUTE,
    body: {
      participantId,
      conversationId,
      attributes: { notes },
    },
  });
}

//////
export function changeAgentState({ agentState }: { agentState: string }) {
  const userId = storeRTK.getState().agent.id;
  return session.callApi({
    type: "req",
    chan: API_CHANGE_AGENT_STATE,
    body: {
      userId,
      agentState,
    },
  });
}

export function getCallWrapupCode() {
  const state = storeRTK.getState();
  return session.callApi({
    type: "req",
    chan: API_CALL_WRAPUP_CODE,
    body: {
      participantId: state.call.customer?.id,
      conversationId: state.call.id,
    },
  });
}

/**
 *
 * @param param0 call has been released so can not get call parameters from state
 * @returns
 */
export function callWrapup({
  wrapupCode,
  participantId,
  conversationId,
}: {
  wrapupCode: string;
  participantId: string;
  conversationId: string;
}) {
  return session.callApi({
    type: "req",
    chan: API_CALL_WRAPUP,
    body: {
      participantId,
      conversationId,
      wrapupCode,
    },
  });
}

export function chatWrapup({
  participantId,
  conversationId,
}: {
  participantId: string;
  conversationId: string;
}) {
  return session.callApi({
    type: "req",
    chan: API_CHAT_WRAPUP,
    body: {
      participantId,
      conversationId,
      wrapupCode: DEFAULT_WRAPUP_CODE,
    },
  });
}

export function emailWrapup({
  participantId,
  conversationId,
}: {
  participantId: string;
  conversationId: string;
}) {
  return session.callApi({
    type: "req",
    chan: API_EMAIL_WRAPUP,
    body: {
      participantId,
      conversationId,
      wrapupCode: DEFAULT_WRAPUP_CODE,
    },
  });
}

export function callbackWrapup({
  participantId,
  conversationId,
}: {
  participantId: string;
  conversationId: string;
}) {
  return session.callApi({
    type: "req",
    chan: API_CALLBACK_WRAPUP,
    body: {
      participantId,
      conversationId,
      wrapupCode: DEFAULT_WRAPUP_CODE,
    },
  });
}

export function getChatHistory({
  conversationId,
  nextHistoryToken,
}: {
  conversationId: string;
  nextHistoryToken?: string;
}) {
  // return session.callApi({
  //   type: "req",
  //   chan: API_CHAT_HISTORY,
  //   body: {
  //     conversationId,
  //     nextHistoryToken: nextHistoryToken || null,
  //   },
  // });
  Emitter.emit(API_CHAT_HISTORY, {
    body: {
      conversationId,
      nextHistoryToken: nextHistoryToken || null,
    },
  });
}

export function getlostMessages(conversationID: string) {
  return session.callApi({
    type: "req",
    chan: API_CHECK_FOR_LOST_MESSAGE,
    body: {
      conversationId: conversationID,
    },
  });
}

export function clearVoiceUtilization(userId: string) {
  return session.callApi({
    type: "req",
    chan: API_CLEAR_VOICE_UTILIZATION,
    body: { userId },
  });
}
export function restoreVoiceUtilization(userId: string) {
  return session.callApi({
    type: "req",
    chan: API_RESTORE_UTILIZATION,
    body: { userId },
  });
}

export function answerCase(ev: any) {
  Emitter.emit(API_CASE_UPDATE, {
    body: {
      participantId: ev.body.participantId,
      conversationId: ev.body.conversationId,
      state: "connected",
    },
  });
}

export function rejectCase(ev: any) {
  Emitter.emit(API_CASE_UPDATE, {
    body: {
      participantId: ev.body.participantId,
      conversationId: ev.body.conversationId,
      state: "disconnected",
    },
  });
}

export function downloadAttachmentAPI({
  conversationId,
  attachmentId,
  attachmentName,
}: {
  conversationId: string;
  attachmentId: string;
  attachmentName: string;
}) {
  return session.callApi({
    type: "req",
    chan: API_DOWNLOAD_ATTACHMENT,
    body: {
      conversationId,
      attachmentId,
      attachmentName,
    },
  });
}

export function sendAttachmentAPI({
  conversationId,
  attachment,
  attachmentName,
  contentType,
}: {
  conversationId: string;
  attachment: any;
  attachmentName: string;
  contentType: string;
}) {
  return session.callApi({
    type: "req",
    chan: API_SEND_ATTACHMENT,
    body: {
      conversationId,
      attachment,
      attachmentName,
      contentType,
    },
  });
}

export async function changeSelectedQueues({
  queueJoiningList,
}: {
  queueJoiningList: AssignQueues[];
}) {
  try {
    if (queueJoiningList && queueJoiningList.length > 0) {
      let add: { id: string }[] = [];
      let remove: { id: string }[] = [];

      queueJoiningList.map((e) => {
        if (e.joined === true) {
          add.push({ id: e.id });
        }
        if (e.joined === false) {
          remove.push({ id: e.id });
        }
      });

      const postData = {
        add: add,
        remove: remove,
      };

      const instanceAlias = getInstanceAliasFromStore();

      const params = {
        url: `${CURRENT_DOMAIN}/v1/session/agent/web/updateAssignedQueues${instanceAlias}`,
        json: postData,
        method: "POST",
        isJsonResponse: true,
      };
      let response: any = await customFetch(params);
      if (
        response &&
        response.queues_assigned &&
        response.queues_assigned.length > 0
      ) {
        const updateQueueAssignedName = renameKeys(
          response?.queues_assigned,
          "queue_name",
          "name"
        );
        const updateQueueAssignedId = renameKeys(
          updateQueueAssignedName,
          "queue_id",
          "id"
        );
        const modifiedQueueAssigned = {
          entities: updateQueueAssignedId.map((val: AssignQueues) => {
            return { ...val, joined: JSON.parse(val.joined as any) };
          }),
        };
        storeRTK.dispatch(updateAgentQueueAssigned(modifiedQueueAssigned));
        return Promise.resolve({
          body: modifiedQueueAssigned,
          success: true,
        } as SessionEvent<any>);
      }
    }
    return Promise.reject({
      body: [],
      success: false,
    } as SessionEvent<any>);
  } catch (error) {
    console.log("error", error);
    return Promise.reject({
      type: "res",
      chan: API_QUEUE_JOIN_UPDATE,
      body: [],
      success: false,
    } as SessionEvent<any>);
  }
}

/**
 *
 * @param contactId //contactId from ccp
 * @returns transfer endpoints for a call
 */
export async function getTransferEndpoints(contactId: string) {
  try {
    const instanceAlias = getInstanceAliasFromStore();
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/contactEstablished${instanceAlias}`,
      json: {
        contactId,
      },
      method: "POST",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response?.quickConnect || [];
  } catch (error) {
    console.log("Error in getting endpoints: ", error);
    return [];
  }
}

//  contact event api handler

export async function getContactEventLoggingAPIHandler(
  contactId: string,
  eventType: string
) {
  console.log("calling contact event logging api", contactId, eventType);
  try {
    const instanceAlias = getInstanceAliasFromStore();
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/contactEvent/${contactId}/${eventType}${instanceAlias}`,
      json: {
        contactId,
      },
      method: "POST",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response || [];
  } catch (error) {
    console.log("Error in getContactEventAPIHandler: ", error);
    return [];
  }
}
/**
 *
 * @param contactId //contactId from ccp
 * @param phoneNumber //phoneNumber from ccp
 * @returns milo deeplink for a call
 */
export async function getMiloDeeplink(
  contactId: string | undefined,
  phoneNumber: string | undefined
) {
  try {
    const instanceAlias = getInstanceAliasFromStore();
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/contactRinging${instanceAlias}`,
      json: {
        channel: "voice",
        contactId,
        phoneNumber,
      },
      method: "POST",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response?.deepLink || "";
  } catch (error) {
    console.log("Error in milo deeplink: ", error);
    return "";
  }
}

export async function getMiloDeeplinkForChat(contactId: string | undefined) {
  try {
    const instanceAlias = getInstanceAliasFromStore();
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/contactEvent/Ringing${instanceAlias}`,
      json: {
        channel: "chat",
        contactId,
      },
      method: "POST",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response?.deepLink || "";
  } catch (error) {
    console.log("Error in milo deeplink for chat: ", error);
    return "";
  }
}

export async function getMiloDeeplinkForCase(contactId: string | undefined) {
  try {
    const instanceAlias = getInstanceAliasFromStore();
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/contactEvent/Ringing${instanceAlias}`,
      json: {
        channel: "case",
        contactId,
      },
      method: "POST",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response?.deepLink || "";
  } catch (error) {
    console.log("Error in milo deeplink for case: ", error);
    return "";
  }
}

export async function getMiloDeeplinkForVoicemail(
  contactId: string | undefined
) {
  const instanceAlias = getInstanceAliasFromStore();
  try {
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/contactEvent/Ringing${instanceAlias}`,
      json: {
        channel: "voicemail",
        contactId,
      },
      method: "POST",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response?.deepLink || "";
  } catch (error) {
    console.log("Error in milo deeplink for case: ", error);
    return "";
  }
}

/**
 *
 * @param contactId //contactId from ccp
 * @param notes //transfer notes
 */
export async function addTransferNoteAttribute(
  contactId: string | undefined,
  notes: string
) {
  try {
    const instanceAlias = getInstanceAliasFromStore();
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/updateAttributes${instanceAlias}`,
      json: {
        contactId,
        attributes: {
          notes: notes,
        },
      },
      method: "POST",
      isJsonResponse: true,
    };
    await customFetch(params);
  } catch (error) {
    console.log("Error in adding transfer notes: ", error);
  }
}

/**
 *
 * @param contactId //contactId from ccp
 * @param notes //transfer notes
 */
export async function updateContactReleaseToAIL(
  channel: string,
  contactId: string,
  phoneNumber?: string,
  chatConcurrency?: number
) {
  try {
    const instanceAlias = getInstanceAliasFromStore();
    let params;
    if (channel === "case" || channel === "voicemail") {
      params = {
        url: `${CURRENT_DOMAIN}/v1/session/agent/web/contactRelease${instanceAlias}`,
        json: {
          channel,
          contactId,
        },
        method: "POST",
        isJsonResponse: false,
      };
    } else if (channel === "voice" || channel === "chat") {
      params = {
        url: `${CURRENT_DOMAIN}/v1/session/agent/web/contactRelease${instanceAlias}`,
        json: {
          channel,
          contactId,
          phoneNumber,
          ...(channel === "chat" ? { chatConcurrency } : {}), // Conditional inclusion
        },
        method: "POST",
        isJsonResponse: false,
      };
    } else {
      console.log("Invalid channel:", channel);
      return;
    }

    customFetch(params)
      .then((res) => console.log("Response from contact release api", res))
      .catch((error) => console.log("Error from contact release api", error));
  } catch (error) {
    console.log("Error in updating contact release info to AIL:", error);
  }
}

export async function checkVoicePolicy(agentData: any) {
  try {
    const voice_policy_url = agentData.aicConfig.voice_policy_web_url;
    const geoData = agentData?.agentInfo?.geoLocation
      ? agentData?.agentInfo?.geoLocation
      : await getAgentGeoLocation();
    const params = {
      url: `${voice_policy_url}`,
      json: {
        geo_data: geoData,
      },
      method: "POST",
      isJsonResponse: true,
    };
    // TODO: commented out for now, uncomment when the API is ready

    const response: any = await fetchWithRetry(customFetch, params);
    storeRTK.dispatch(updateAgentInfoCountry(response.country)); // update the store with the response
    return response;
  } catch (error) {
    console.log("Error in fetching voice policy", error);
    // Todo: Make this conditional based on the user country, get it from AIC server api, then you can make it true
    return false;
  }
}
export async function getAgentGeoLocation() {
  const DOMAIN = await getAicServerUrl();
  try {
    const params = {
      url: `${DOMAIN}/v1/session/agent/web/getMyGeoLocation`,
      json: "",
      method: "GET",
      isJsonResponse: true,
    };
    let response: any = await customFetch(params);
    if (response?.geo_data) {
      updateAgentGeoLocation({ geoLocation: response?.geo_data });
    }
    return response?.geo_data;
  } catch (error) {
    console.log("Error in fetching geo location", error);
    // Todo: Make this conditional based on the user country, get it from AIC server api, then you can make it true
    return null;
  }
}

function deleteCookie(cookieNames: Array<String>) {
  cookieNames.forEach((cookieItem: String) => {
    document.cookie = `${cookieItem}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
}

export async function stopSession() {
  try {
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/aws/web/stopSession`,
      json: "",
      method: "GET",
      isJsonResponse: true,
    };
    let response: any = await customFetch(params);
    return response.json();
  } catch (error) {
    console.log("Error in stop session during logout", error);
    return false;
  } finally {
    storeRTK.dispatch(setLoader(false));
    deleteCookie(COOKIE_AWS);
  }
}

/**
 * Api to get canned responses
 * @param obj
 * @returns cannedresponses
 */
export async function getCannedResponses() {
  try {
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/cannedResponses`,
      json: "",
      method: "GET",
      isJsonResponse: true,
    };
    const response: { adobe_canned_responses: IndividualCannedResponses[] } =
      await customFetch(params);
    const cannedResponse: CannedResponses = {
      queueId: "default",
      queueName: "default",
      libraryId: "",
      libraryname: "",
      responses: response.adobe_canned_responses.map((item, key) => {
        return { ...item, id: key.toString() };
      }),
    };
    Emitter.emit(STATE_CANNEDRESPONSES, {
      body: cannedResponse,
    });
    return response;
  } catch (error: any) {
    console.log("Error in getting canned responses", error);
  }
}

// Rename the keys

function renameKeys(obj: any, replaceThis: string, replacewith: string): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => renameKeys(item, replaceThis, replacewith));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = key === replaceThis ? replacewith : key;
      acc[newKey] = renameKeys(obj[key], replaceThis, replacewith);
      return acc;
    }, {} as any); // Add type annotation here
  } else {
    return obj;
  }
}

/**
 *
 * @param contactId
 * @returns success or failure
 */
export async function autoAnswerCase(contactId: string) {
  try {
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/contactEvent/${contactId}/autoAnswered`,
      json: {
        contactId,
      },
      method: "POST",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response;
  } catch (error) {
    console.log("Error in getting case auto answer api : ", error);
    return null;
  }
}

export async function suggestIssueReason(contactId: string) {
  try {
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/suggestIssueReason`,
      json: {
        contactId,
      },
      method: "POST",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response || [];
  } catch (error) {
    console.log("Error while getting response from suggest reason: ", error);
    return null;
  }
}

export async function summarizeIssueReason(contactId: string) {
  try {
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/summarizeIssue`,
      json: {
        contactId,
      },
      method: "POST",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response || [];
  } catch (error) {
    console.log(
      "Error while getting response from summarize issue reason :",
      error
    );
    return null;
  }
}

export async function askAi(message: string) {
  try {
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/web/agent/askAI`,
      json: {
        message,
      },
      method: "POST",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response || [];
  } catch (error) {
    console.log("Error while getting response from ask ai : ", error);
    return null;
  }
}

export async function getCaseRerouteData(contactId: string) {
  try {
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/contactEvent/caseReRouteData`,
      json: {
        contactId,
      },
      method: "POST",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response || [];
  } catch (error) {
    console.log(
      "Error while getting response from summarize issue reason :",
      error
    );
    return null;
  }
}

export async function postCaseRerouteData(payload: any) {
  try {
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/contactEvent/casereroute`,
      json: payload,
      method: "POST",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response || [];
  } catch (error) {
    console.log(
      "Error while getting response from summarize issue reason :",
      error
    );
    return null;
  }
}

export async function getSupervisorAgentList() {
  try {
    const instanceAlias = getInstanceAliasFromStore();

    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/web/supervisor/agentStatus${instanceAlias}`,
      json: "",
      method: "GET",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params, 20);
    return response || [];
  } catch (error) {
    console.log("Error while fetching Supervisor agent list ", error);
    return [];
  }
}

export async function callMonitorRequest(payload: any) {
  try {
    const instanceAlias = getInstanceAliasFromStore();
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/web/supervisor/contactMonitor${instanceAlias}`,
      json: payload,
      method: "POST",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response || [];
  } catch (error) {
    console.log("Error while getting response for call monitoring :", error);
    return null;
  }
}

export async function supervisorCallDetails(payload: any) {
  try {
    const instanceAlias = getInstanceAliasFromStore();
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/web/supervisor/getCallData${instanceAlias}`,
      json: payload,
      method: "POST",
      isJsonResponse: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response || [];
  } catch (error) {
    console.log("Error while getting response for call details :", error);
    return null;
  }
}

export async function addCCPProfile() {
  try {
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/agent/web/addCCPProfile`,
      method: "PUT",
      isJsonResponse: true,
      keepalive: true,
    };
    let response: any = await fetchWithRetry(customFetch, params);
    return response || [];
  } catch (error) {
    console.log("Error while getting response for addCCPProfile :", error);
    throw error;
  }
}

export async function getProfile(
  currentCcpInstanceAlias?: string
): Promise<Agent | undefined> {
  try {
    const CURRENT_DOMAIN = getAicServerUrl();
    let url = `${CURRENT_DOMAIN}/v1/session/agent/web/profile`;
    if (currentCcpInstanceAlias) url = `${url}/${currentCcpInstanceAlias}`;
    const params = {
      url: url,
      json: "",
      method: "GET",
      isJsonResponse: false,
      credentials: "include",
    };
    const response: any = await fetchWithRetry(customFetch, params);
    const profile = await response;
    const allowedMedia = Object.keys(
      profile?.agentProfile?.allowed_conversation_media[0]
    )
      .filter(
        (key) =>
          profile?.agentProfile?.allowed_conversation_media[0][key] === "true"
      )
      .map((value) => value.toUpperCase());
    const businessAttributes = profile.agentProfile.business_attributes
      ? renameKeys(
          profile.agentProfile.business_attributes,
          "attribute_name",
          "attributeName"
        )
      : [];
    const voice_transfer_queues = profile.agentProfile.business_attributes
      ? renameKeys(
          profile?.agentProfile?.voice_transfer_queues,
          "queue_name",
          "displayName"
        )
      : [];
    const updateIdVoiceTransfer = voice_transfer_queues
      ? renameKeys(voice_transfer_queues, "queue_id", "id")
      : [];
    const transferQueues = {
      allowedTransferQueues: updateIdVoiceTransfer ? updateIdVoiceTransfer : [],
    };
    const updateQueueAssignedName = profile.agentProfile.queues_assigned
      ? renameKeys(profile.agentProfile.queues_assigned, "queue_name", "name")
      : [];
    const updateQueueAssignedId = updateQueueAssignedName
      ? renameKeys(updateQueueAssignedName, "queue_id", "id")
      : [];
    const modifiedQueueAssigned = {
      entities: updateQueueAssignedId
        ? updateQueueAssignedId.map((val: any) => {
            // Check if 'joined' key exists before applying JSON.parse
            const joinedValue = val.joined
              ? JSON.parse(val.joined as any)
              : val.joined;

            return { ...val, joined: joinedValue };
          })
        : [],
    };

    const chattransferQueues = profile.agentProfile.chat_transfer_queues
      ? renameKeys(
          profile?.agentProfile?.chat_transfer_queues,
          "queue_name",
          "displayName"
        )
      : [];
    const modifiedchattransferQueues = {
      allowedTransferQueues: chattransferQueues
        ? chattransferQueues.map((stateObj: any, index: any) => ({
            id: stateObj.displayName,
            ...stateObj,
          }))
        : [],
    };
    const agentStates = profile.agentProfile.agent_states
      ? renameKeys(profile?.agentProfile?.agent_states, "state", "displayName")
      : [];
    const modifiedagentStates = {
      allowedStates: agentStates
        ? agentStates.map((stateObj: any, index: any) => ({
            id: stateObj.displayName,
            ...stateObj,
          }))
        : [],
    };
    const allowedStatesIndexes = modifiedagentStates
      ? modifiedagentStates?.allowedStates.map(
          (stateObject: any, i: number) => {
            const key = stateObject?.id; // Access the id property safely
            return {
              [key]: i,
            };
          }
        )
      : [];

    const allowedStatesIndex = allowedStatesIndexes
      ? allowedStatesIndexes.reduce((result: any, currentObject: any) => {
          const [key, value] = Object.entries(currentObject)[0];
          result[key] = value;
          return result;
        }, {})
      : [];
    const states = {
      allowedStates: modifiedagentStates
        ? modifiedagentStates?.allowedStates
        : [],
      allowedStatesIndex: allowedStatesIndex ? allowedStatesIndex : [],
    };
    const agentState = states ? states?.allowedStates[0] : {};
    const stateModifiedDate = new Date().getTime();

    let queueSelection = null;
    if (profile?.agentProfile?.queue_selection?.length > 0) {
      queueSelection = profile?.agentProfile?.queue_selection[0]?.allowed
        ? JSON.parse(profile?.agentProfile?.queue_selection[0]?.allowed)
        : profile?.agentProfile?.queue_selection[0]?.allowed;
    }
    return Promise.resolve({
      id: profile?.agentProfile?.agent_info?.email || "",
      firstName: profile?.agentProfile?.agent_info?.firstName || "Unknown",
      lastName: profile?.agentProfile?.agent_info?.lastName || "Unknow",
      email: profile?.agentProfile?.agent_info?.email || "",
      state: agentState,
      agentInfo: profile?.agentProfile?.agent_info,
      aicConfig: profile?.agentProfile?.aic_config,
      instanceConfig: profile?.agentProfile?.instance_config,
      stateModifiedDate: stateModifiedDate,
      acwState: null,
      acwStateModifiedDate: 0,
      groupId: "abc13920-47d0-478b-879b-69d8f3bc830d",
      states: states,
      businessAttributes: businessAttributes,
      cannedResponses: [],
      chatTransferQueues: modifiedchattransferQueues,
      allowedConversationMedia: allowedMedia,
      availableMedia: ["VOICE"],
      sessionPingInterval: profile?.agentProfile?.aic_config?.ping_interval,
      vpnCheckUrl: profile?.agentProfile?.aic_config?.vpn_check_url,
      transferQueues: transferQueues,
      queuesAssigned: modifiedQueueAssigned,
      queueSelectionAllowed: queueSelection,
      routingProfileConCurrency:
        profile?.agentProfile?.routing_profile_concurrency,
      crm: profile?.agentProfile?.crm,
      agentLoginTime: new Date().toISOString(),
    });
  } catch (error: any) {
    // store.dispatch(loginFailure("Error in getting profile api"));
    return Promise.reject(error);
  }
}

//bcz it is showing data
// export async function getScheduledCallbackListApi() {
//   try {
//     const params = {
//       url: `${CALLBACK_DASHBOARD_URL}/callback/getListCallbacks?requestId=LST_123-567-abc&startTimeEpoch=1737341666`,
//       json: "",
//       method: "GET",
//       isJsonResponse: true,
//     };
//     let response: any = await fetchWithRetry(customFetch, params, 20);
//     return response || [];
//   } catch (error) {
//     console.log("Error while fetching schedule callback list ", error);
//     return [];
//   }
// }

export async function getScheduledCallbackListApi(
  start: number,
  end: number
): Promise<CallbackListAPIResponse | {}> {
  const uniqueRequestId = makeUUID();
  const starttime = start;
  const endtime = end;
  const data = {
    requestId: uniqueRequestId,
    startTimeEpoch: starttime,
    endTimeEpoch: endtime,
  };
  try {
    const params = {
      url: `${CURRENT_DOMAIN}/v1/session/web/callback/postListCallbacks`,
      json: data,
      method: "POST",
      isJsonResponse: true,
      data: data,
    };
    let response: any = await fetchWithRetry(customFetch, params, 1);
    return response.data || {};
  } catch (error) {
    console.log("Error while fetching schedule callback list ", error);
    return {};
  }
}
