import registerAgentHandlers from "./agent";
import registerCallHandlers from "./call";
import registerHistoryHandlers from "./history";
import registerCaseHandlers from "./cases";
import registerCcpSessionHandlers from "./session";
import registerWebRTCHandlerEvents from "./webrtc";
import { registerPermissionCheckEvents } from "./permissionCheck";
import registerCcpInstanceHandlers from "./multiInstance";
import registerTelemetryEvents from "../telemetry";

export default function registerEventHandlers() {
  registerWebRTCHandlerEvents();
  registerAgentHandlers();
  registerCallHandlers();
  registerHistoryHandlers();
  registerCaseHandlers();
  registerCcpSessionHandlers();
  registerPermissionCheckEvents();
  registerCcpInstanceHandlers();
  registerTelemetryEvents();
}
