import {
  Heading,
  TableView,
  View,
  TableHeader,
  ActionButton,
  Column,
  Cell,
  Row,
  TableBody,
  Flex,
  Text,
  TextField,
  Button,
  Picker,
  Link,
  Item,
  TooltipTrigger,
  Tooltip,
  DateRangePicker,
} from "@adobe/react-spectrum";
import React, { useEffect, useState } from "react";
import { RefreshIconRotate, SearchIcon, TimerIcon } from "../common/Icons";
import styles from "./CallbackDashboard.module.css";
import { getScheduledCallbackListApi } from "../../../src/api/session-api";
// import CallbackDetails from "./CallbackDetails";
// import SaveView from "./SaveView";
import {
  CallbackData,
  CallbackStatus,
  ColumnConfig,
  SavedViewListItem,
} from "./types";
import {
  CallbackListAPIResponse,
  CallbackObjectType,
} from "../../types/callback.type";
import { callbackStatus, callbackTableColumns } from "./constants";
import {
  getAmazonConnectUrlByContactId,
  getSafeUrl,
} from "../../../src/utils/utils";
import Timer from "./Timer";
import { parseDate, today, CalendarDate } from "@internationalized/date";
import { ColumnStaticSize } from "@react-types/table";

const convertToTimestamp = (calendarDate: CalendarDate, isEndDate = false) => {
  let date = new Date(calendarDate.toDate("UTC"));
  if (isEndDate) {
    date.setHours(23, 59, 59, 999);
  }
  return Math.floor(date.getTime() / 1000);
};

const CallbackDashboard = () => {
  const [showCallbackDetails, setShowCallbackDetails] =
    useState<boolean>(false);
  const [filteredCbs, setFilteredCbs] = useState<CallbackObjectType[]>([]);
  const [callbacks, setCallbacks] = useState<CallbackObjectType[]>([]);
  const [searchText, setSearchText] = useState<string>(() => {
    return localStorage.getItem("callbackSearchText") || "";
  });
  const [selectedCallbackStatus, setSelectedCallbackStatus] =
    useState<CallbackStatus | null>(() => {
      return (
        (localStorage.getItem("callbackStatus") as CallbackStatus) || "ALL"
      );
    });
  const [isRotating, setIsRotating] = useState(false);
  const initialStartDate = today("UTC").subtract({ days: 1 });
  const initialEndDate = today("UTC");
  const storedStart = localStorage.getItem("callbackStartDate");
  const storedEnd = localStorage.getItem("callbackEndDate");
  const defaultStartDate = storedStart
    ? parseDate(storedStart)
    : initialStartDate;
  const defaultEndDate = storedEnd ? parseDate(storedEnd) : initialEndDate;

  const [dateRange, setDateRange] = useState({
    start: defaultStartDate,
    end: defaultEndDate,
  });
  const [timestamps, setTimestamps] = useState({
    startTime: convertToTimestamp(defaultStartDate),
    endTime: convertToTimestamp(defaultEndDate, true),
  });

  useEffect(() => {
    setTimestamps({
      startTime: convertToTimestamp(dateRange.start),
      endTime: convertToTimestamp(dateRange.end, true),
    });
    getScheduledCallbackList();
  }, [dateRange]);

  useEffect(() => {
    getScheduledCallbackList();
  }, []);

  const handleDateChange = (
    range: { start: CalendarDate; end: CalendarDate } | null
  ) => {
    if (range) {
      setDateRange(range);
    }
  };

  // const handleDateSearch = () => {
  //   localStorage.setItem("callbackStartDate", dateRange.start.toString());
  //   localStorage.setItem("callbackEndDate", dateRange.end.toString());
  //   getScheduledCallbackList();
  // };

  const getScheduledCallbackList = async () => {
    setIsRotating(true);
    try {
      const res: CallbackListAPIResponse = await getScheduledCallbackListApi(
        timestamps.startTime,
        timestamps.endTime
      );
      // @ts-ignore
      const result = res.length > 0 ? res?.sort((a, b) => b.callbackTimeEpoch - a.callbackTimeEpoch) : [];
      setCallbacks(result);
      let filtered = result;
      if (selectedCallbackStatus !== "ALL") {
        filtered = filtered.filter(
          (item: any) => item.requestStatus === selectedCallbackStatus
        );
      }
      if (searchText.trim()) {
        const searchValue = searchText.trim().toLowerCase();
        filtered = filtered.filter((item: any) => {
          const contact = item;
          const contactId = contact.contactId.toLowerCase();
          const phoneNumber = contact.phoneNumber.toLowerCase();
          const productFamily = contact.productFamily.toLowerCase();
          const callbackType = contact.callbackType.toLowerCase();
          const requestStatus = contact.requestStatus.toLowerCase();
          const firstName =
            contact.callbackAttributes?.FIRSTNAME?.toLowerCase() || "";
          const lastName =
            contact.callbackAttributes?.LASTNAME?.toLowerCase() || "";
          const fullName = `${firstName} ${lastName}`.trim();
          const userLdap = contact?.contactHandleBy?.toLowerCase() || "";
          const allConnectContacts = contact?.connectContacts
            ?.map((item: any) => item.id)
            .join(",");
          return (
            contactId.includes(searchValue) ||
            phoneNumber.includes(searchValue) ||
            productFamily.includes(searchValue) ||
            firstName.includes(searchValue) ||
            lastName.includes(searchValue) ||
            fullName.includes(searchValue) ||
            callbackType.includes(searchValue) ||
            requestStatus.includes(searchValue) ||
            userLdap.includes(searchValue) ||
            allConnectContacts.includes(searchValue)
          );
        });
      }

      setFilteredCbs(filtered);
    } catch (error) {
      console.error("Failed to fetch scheduled callback details:", error);
    } finally {
      setIsRotating(false);
    }
  };

  const fetchColor = (status: CallbackStatus): string => {
    const colorMap: Record<CallbackStatus, string> = {
      PENDING: "#FFA500",
      IN_PROGRESS: "#F68511",
      INPROGRESS: "#F68511",
      COMPLETE: "#008000",
      FAILED: "#FF0000",
      NEW: "#147AF3",
      ALL: "",
    };
    return colorMap[status];
  };

  const handleSearch = (value: any) => {
    setSearchText(value);
    localStorage.setItem("callbackSearchText", value);
    const searchValue = value.trim().toLowerCase();

    if (searchValue) {
      const filtered = callbacks.filter((item) => {
        const contact = item;
        const contactId = contact.contactId.toLowerCase();
        const phoneNumber = contact.phoneNumber.toLowerCase();
        const productFamily = contact.productFamily.toLowerCase();
        const callbackType = contact.callbackType.toLowerCase();
        const requestStatus = contact.requestStatus.toLowerCase();
        const firstName =
          contact.callbackAttributes?.FIRSTNAME?.toLowerCase() || "";
        const lastName =
          contact.callbackAttributes?.LASTNAME?.toLowerCase() || "";
        const fullName = `${firstName} ${lastName}`.trim();
        const userLdap = contact?.contactHandleBy?.toLowerCase() || "";
        const orgId = contact?.callbackAttributes?.ORG_ID || "";
        const allConnectContacts = contact?.connectContacts
          ?.map((item) => item.id)
          .join(",");

        return (
          contactId.includes(searchValue) ||
          phoneNumber.includes(searchValue) ||
          productFamily.includes(searchValue) ||
          firstName.includes(searchValue) ||
          lastName.includes(searchValue) ||
          fullName.includes(searchValue) ||
          callbackType.includes(searchValue) ||
          requestStatus.includes(searchValue) ||
          userLdap.includes(searchValue) ||
          allConnectContacts.includes(searchValue) ||
          allConnectContacts.includes(searchValue)
        );
      });
      setFilteredCbs(filtered);
    } else {
      console.log("callbacks in search!!!", callbacks);
      setFilteredCbs(callbacks);
    }
  };

  const handleStatusChange = (value: any) => {
    setSelectedCallbackStatus(value);
    localStorage.setItem("callbackStatus", value);
    if (value.trim() && value !== "ALL") {
      setFilteredCbs(callbacks.filter((item) => item.requestStatus === value));
    } else {
      setFilteredCbs(callbacks);
    }
  };

  const getFormattedCallLength = (startstamp: number, endstamp: number) => {
    if (!startstamp || !endstamp) return "N/A";
    const differenceInSeconds = endstamp - startstamp;
    const minutes = Math.floor(differenceInSeconds / 60);
    const seconds = differenceInSeconds % 60;
    return minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
  };

  const ContactLink = ({
    value,
    isConnectContact,
    index
  }: {
    value: string;
    isConnectContact?: boolean;
    index?: number;
  }) => {
    const contactUrl = getAmazonConnectUrlByContactId(value);
    const safeUrl = getSafeUrl(contactUrl);
    // const isAlternate = index !== undefined && index % 2 === 1;

    return safeUrl ? (
      <Link
        href={safeUrl}
        target="_blank"
        UNSAFE_className={isConnectContact ? styles.alternateLink : undefined}
      >
        {/* {value} */}
        {isConnectContact ? `Attempt ${index != undefined ? index + 1: ""}` : value?.substring(0, 8) + "..."}
      </Link>
    ) : (
      <Text>{value?.substring(0, 8) + "..."}</Text>
    );
  };

  return (
    <View UNSAFE_className={styles.container}>
      {!showCallbackDetails && (
        <>
          <Flex
            direction={"row"}
            justifyContent={"space-between"}
            UNSAFE_className={styles.header}
          >
            <Flex direction={"row"} alignItems={"center"} gap={"size-250"}>
              <Timer
                getScheduledCallbackList={getScheduledCallbackList}
                isRotating={isRotating}
              />
            </Flex>
          </Flex>

          <View UNSAFE_className={styles.content}>
            <Flex
              direction={"row"}
              UNSAFE_style={{ padding: "0px 24px 0px 0px" }}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Flex>
                <Heading level={5}>
                  Total callbacks: {filteredCbs.length}
                </Heading>
              </Flex>

              <Flex gap="size-250" alignItems="center">
                <DateRangePicker
                  label="Date Range"
                  labelAlign="start"
                  labelPosition="side"
                  width="315px"
                  value={dateRange}
                  onChange={handleDateChange}
                  maxValue={today("UTC")}
                />
                <TextField
                  labelAlign="start"
                  labelPosition="side"
                  label="Search"
                  width="250px"
                  value={searchText}
                  onChange={handleSearch}
                />
                <Flex alignItems="center" gap="size-100">
                  <Picker
                    label="Callback status"
                    labelAlign="start"
                    labelPosition="side"
                    items={callbackStatus}
                    selectedKey={selectedCallbackStatus}
                    onSelectionChange={handleStatusChange}
                  >
                    {(status) => <Item key={status.id}>{status.name}</Item>}
                  </Picker>
                </Flex>
              </Flex>
            </Flex>
            <>
              <TableView
                aria-label="scheduled-callbacks"
                height="68vh"
                UNSAFE_style={{
                  padding: "0px 24px 0px 0px",
                  scrollbarWidth: "thin",
                }}
                overflowMode="wrap"
              >
                <TableHeader>
                  {callbackTableColumns.map((column) => (
                    <Column minWidth={column.width as ColumnStaticSize} allowsResizing>{column.title}</Column>
                  ))}
                </TableHeader>

                <TableBody>
                  {filteredCbs.map((value: CallbackObjectType, index) => (
                    <Row>
                      {callbackTableColumns.map((column) => (
                        <Cell>
                          {column.dataIndex === "contactId" ? (
                            <ContactLink
                              value={value.contactId}
                              isConnectContact={false}
                            />
                          ) : column.dataIndex == "requestStatus" ? (
                            <>
                              <View
                                UNSAFE_style={{
                                  height: 8,
                                  width: 8,
                                  borderRadius: 50,
                                  backgroundColor: fetchColor(
                                    value.requestStatus as CallbackStatus
                                  ),
                                  display: "inline-block",
                                  marginRight: 8,
                                }}
                              ></View>
                              <Text>{value?.requestStatus || "-"}</Text>
                            </>
                          ) : column.dataIndex == "customerName" ? (
                            <>
                              <Text>
                                {`${value?.callbackAttributes?.FIRSTNAME} ${value?.callbackAttributes?.LASTNAME}` ||
                                  "-"}
                              </Text>
                            </>
                          ): column.dataIndex == "orgId" ? (
                            <>
                              <Text>
                                {value?.callbackAttributes?.ORG_ID ||
                                  "-"}
                              </Text>
                            </>
                          ) : column.dataIndex === "connectContacts" ? (
                            <>
                              {value?.connectContacts?.length > 0
                                ? value.connectContacts.map(
                                    (contact, index) => {
                                      const callLength = getFormattedCallLength(
                                        contact.initiationTimestamp,
                                        contact.disconnectTimestamp
                                      );
                                      return (
                                        <View
                                          key={index}
                                          UNSAFE_style={{ marginBottom: "4px" }}
                                        >
                                          <TooltipTrigger delay={0}>
                                            <ContactLink
                                              value={contact.id}
                                              isConnectContact={true}
                                              index={index}
                                            />
                                            <Tooltip>{`Call duration: ${callLength}`}</Tooltip>
                                          </TooltipTrigger>
                                        </View>
                                      );
                                    }
                                  )
                                : "-"}
                            </>
                          ) : column.dataIndex === "userLdap" ? (
                            <>
                              <Text>{value?.contactHandleBy || "-"}</Text>
                            </>
                          ) : // : column.dataIndex === "callbackScheduleTimeEpoch" ? (
                          //   <Text>
                          //     {
                          //       new Date(value?.callbackScheduleTimeEpoch * 1000).toLocaleString().includes("1970") ? "-" :
                          //         new Date(value?.callbackScheduleTimeEpoch * 1000).toLocaleString()}
                          //   </Text>
                          // )
                          column.dataIndex === "callbackTimeEpoch" ? (
                            <Text>
                              {new Date(
                                value?.callbackTimeEpoch * 1000
                              ).toLocaleString() || "-"}
                            </Text>
                          ) : (
                            // @ts-ignore
                            value?.[column.dataIndex] || "-"
                          )}
                        </Cell>
                      ))}
                    </Row>
                  ))}
                </TableBody>
              </TableView>
            </>
          </View>
        </>
      )}
      {/* {showCallbackDetails && <CallbackDetails back={() => setShowCallbackDetails(false)} callbackData={selectedCallback.current as CallbackObjectType[]} />} */}
    </View>
  );
};

export default React.memo(CallbackDashboard);
