import {
  CALLBACK_RESET,
  CALLBACK_SET,
  LOGOUT,
  PLAYBACK_MEDIA_URL_SET,
  RESET_STATE,
  STORE_LOGIN,
  UPDATE_NORMAL_CALLBACK,
} from "../constants/action-types";

const initialState = {
  id: null,
  customer: null,
  self: null,
  remainings: null,
  conversationMedia: null,
  selfState: null,
  playbackMediaURL: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CALLBACK_SET:
      return {
        ...state,
        ...action.callback,
      };
    case PLAYBACK_MEDIA_URL_SET:
      return {
        ...state,
        playbackMediaURL: action.playbackMediaURL,
      };
    case UPDATE_NORMAL_CALLBACK:
      const connectedTime = new Date(
        action.callback.self?.connectedTime || ""
      ).getTime();
      return {
        ...state,
        ...action.callback.self,
        self: {
          ...action.callback.self,
          connectedTime,
        },
      };
    case LOGOUT:
    case CALLBACK_RESET:
      return {
        ...initialState,
      };
    case STORE_LOGIN:
      return {
        ...action.payload.callback,
      };
    case RESET_STATE:
      return {};
    default:
      return state;
  }
};
