import {
  View,
  Text as SpectrumText,
  Flex,
  Button,
} from "@adobe/react-spectrum";
import {
  DislikeIcon,
  DislikeOutlineIcon,
  LikeIcon,
  LikeOutlineIcon,
} from "../../common/Icons";
import storeRTK from "../../../store/storeRTK";
import { setSuggestCaseIssue } from "../../../actions/case";

const AiFeedBack = (props: any) => {
  const { activeCase, message } = props;

  const likeAction = (uniqueId: string) => {
    const getMessage = activeCase?.suggestCaseMessages?.find(
      (msg: any) => msg.uniqueId === uniqueId
    );
    if (getMessage) {
      storeRTK.dispatch(
        setSuggestCaseIssue(
          activeCase.id,
          getMessage.userResponse,
          getMessage.aiResponse,
          getMessage.showSuggestDialog,
          true,
          false,
          false,
          "",
          uniqueId
        )
      );
    }
  };

  const dislikeAction = (uniqueId: string) => {
    const getMessage = activeCase?.suggestCaseMessages?.find(
      (msg: any) => msg.uniqueId === uniqueId
    );
    if (getMessage) {
      storeRTK.dispatch(
        setSuggestCaseIssue(
          activeCase.id,
          getMessage.userResponse,
          getMessage.aiResponse,
          getMessage.showSuggestDialog,
          false,
          true,
          true,
          "",
          uniqueId
        )
      );
    }
  };

  return (
    <>
      <View paddingY={14}>
        <Flex justifyContent="space-between" alignItems="center">
          <SpectrumText UNSAFE_style={{ fontWeight: "bold" }}>
            Was it helpful
          </SpectrumText>
          <SpectrumText>
            {!message.isDisliked && (
              <SpectrumText
                marginX={16}
                UNSAFE_style={{
                  cursor: "pointer",
                }}
              >
                <Button
                  variant={"primary"}
                  onPress={() => likeAction(message.uniqueId)}
                >
                  {message.isLiked ? <LikeIcon /> : <LikeOutlineIcon />}
                </Button>
              </SpectrumText>
            )}
            {!message.isLiked && (
              <SpectrumText
                UNSAFE_style={{
                  cursor: "pointer",
                }}
              >
                <Button
                  variant="primary"
                  onPress={() => dislikeAction(message.uniqueId)}
                >
                  {message.isDisliked ? (
                    <DislikeIcon />
                  ) : (
                    <DislikeOutlineIcon />
                  )}
                </Button>
              </SpectrumText>
            )}
          </SpectrumText>
        </Flex>
      </View>
    </>
  );
};

export default AiFeedBack;
