import { getLogEndpoint } from "../config";
import { TELEMETRY_ERROR } from "../constants/events";
import Emitter from "../emitter";
import storeRTK from "../store/storeRTK";
import { SessionEvent } from "../types/call.type";
import { customFetch, fetchWithRetry, shallowClone } from "../utils/utils";

export interface RecordedEvent extends SessionEvent<any> {
  timestamp?: string;
  timestampMillis?: number;
  context?: {
    agent?: Object;
    call?: Object;
    chat?: Object;
  };
}

function preprocessEvent(event: RecordedEvent): RecordedEvent {
  if (event.chan === TELEMETRY_ERROR) event = event.body;
  const currentTime = new Date();
  event.timestamp = currentTime.toISOString();
  event.timestampMillis = currentTime.getTime();

  if ("context" in event) {
    const state = storeRTK.getState();
    if (event.context?.agent) {
      const clonedAgent = shallowClone(state.agent);
      delete clonedAgent.states;
      delete clonedAgent.acwState;
      delete clonedAgent.stateModifiedDate;
      delete clonedAgent.cannedResponses;
      delete clonedAgent.chatTransferQueues;
      delete clonedAgent.allowedConversationMedia;
      delete clonedAgent.availableMedia;
      delete clonedAgent.sessionPingInterval;
      delete clonedAgent.transferQueues;
      delete clonedAgent.businessAttributes;
      delete clonedAgent.queuesAssigned;
      delete clonedAgent.crm;
      console.log("clonedAgent", clonedAgent);
      event.context.agent = clonedAgent;
    }
  }

  return event;
}

const telemetryRecords = {
  env: navigator.userAgent,
  version: process.env.REACT_APP_VERSION,
  source: process.env.SOURCE,
  records: new Array<RecordedEvent>(),
};

export default function registerTelemetryEvents() {
  Emitter.on(TELEMETRY_ERROR, function recordEvents(event: RecordedEvent) {
    const ev = preprocessEvent(event);
    return telemetryRecords.records.push(ev);
  });
  setInterval(sendTelemetryData, 5000);
}

function sendTelemetryData() {
  if (telemetryRecords.records.length > 0) {
    const clonedData = {
      data: {
        ...telemetryRecords,
        records: telemetryRecords.records.splice(0),
      },
    };
    fetchWithRetry(customFetch, {
      url: getLogEndpoint(),
      json: clonedData,
      method: "POST",
      isJsonResponse: true,
    }).catch(() => {
      telemetryRecords.records.push(...clonedData.data.records);
    });
  }
}
