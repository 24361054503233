export const callbackTableColumns = [
  {

    title: "Product",
    dataIndex: "productFamily",
    key: "productFamily",
    width: "12%"
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
    width: "12%"
  },
  {
    title: "Customer Email",
    dataIndex: "customerAdobeId",
    key: "customerAdobeId",
    width: "15%"
  },
  {
    title: "OrgId",
    dataIndex: "orgId",
    key: "orgId",
    width: "15%"
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    width: "12%"
  },
  {
    title: "Callback Request Time",
    dataIndex: "callbackTimeEpoch",
    key: "callbackTimeEpoch",
    width: "15%"
  },
  // {

  //   title: "Schedule Time",
  //   dataIndex: "callbackScheduleTimeEpoch",
  //   key: "callbackScheduleTimeEpoch",
  //    width: "15%"
  // },
  // {
  //   title: "Timezone",
  //   dataIndex: "timezone",
  //   key: "timezone",
  //   // width: "15%"
  // },
  // {
  //   title: "Notes",
  //   dataIndex: "notes",
  //   key: "notes",
  //   // width: "15%"
  // },
 
  {
    title: "Status",
    dataIndex: "requestStatus",
    key: "requestStatus",
    width: "10%"
  },
  {
    title: "Call Attempts By",
    dataIndex: "userLdap",
    key: "userLdap",
    width: "12%"
  },
  {
    title: "Call Attempts",
    dataIndex: "connectContacts",
    key: "connectContacts",
    width: "12%"
  },
  {
    title: "Contact ID",
    dataIndex: "contactId",
    key: "contactId",
    width: "8%"
  },
  {
    title: "Type",
    dataIndex: "callbackType",
    key: "callbackType",
    width: "13%"
  },
  // {
  //     title: "Action",
  //     dataIndex: "actions",
  //     key: "actions",
  //     // width: "15%"

  // }
];

export const callbackStatus = [
  {
    name: "All",
    id: "ALL",
  },
  {
    name: "Pending",
    id: "PENDING",
  },
  {
    name: "In Progress",
    id: "INPROGRESS",
  },
  {
    name: "Complete",
    id: "COMPLETE",
  },
  {
    name: "Failed",
    id: "FAILED",
  },
  {
    name: "New",
    id: "NEW",
  },
];