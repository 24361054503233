import { createAsyncThunk } from "@reduxjs/toolkit";
import { Call } from "../reducers/call";
import { Callback } from "../reducers/callback";
import { IndividualChat } from "../reducers/chat";
import { AppDispatch, RootState } from "../store";
import { IndividualCase } from "../reducers/case";
import {
  addCallToHistory,
  addCallbackToHistory,
  addCaseToHistory,
  addChatToHistory,
  deleteCallFromHistory,
  deleteCallbackFromHistory,
  deleteCaseFromHistory,
  deleteChatFromHistory,
  removeCallFromHistory,
  removeCallbackFromHistory,
  removeCaseFromHistory,
  removeChatFromHistory,
  setHistory,
} from "../actions/history";
import storeRTK from "../store/storeRTK";
import { ChatMessage } from "../types/chat.type";
import { getInstanceAlias } from "../utils/utils";

function sendChangeMessage(state: RootState) {
  // const history = state.history;
  // sendMessage({
  //   type: "notif",
  //   body: history,
  //   chan: BG_HISTORY_CHANGE,
  // });
}

/**
 * Initializes the history for the agent.
 * This function sets up the initial state of the history, including conversations, callbacks, chats, and cases.
 * It is typically called when the agent logs in or the instance is switched.
 *
 * @param {string} agentId - The ID of the agent whose history is being initialized.
 * @returns {void}
 */
export const initializeHistory = createAsyncThunk<
  void,
  null,
  { dispatch: AppDispatch; state: RootState }
>("history/initializeHistory", (_ignore, thunkApi) => {
  const state: RootState = thunkApi.getState();
  const agent_id = state.agent.id as string;
  const agentData = localStorage.getItem("agent_id");
  if (agentData !== null) {
    const activeInstance = getInstanceAlias();
    const agentSavedData = JSON.parse(agentData);
    storeRTK.dispatch(
      setHistory(agentSavedData[agent_id][activeInstance] || {})
    );
  }
});

/**
 * Action to add a call to the agent's history.
 * This function dispatches an action to update the state with a new call entry.
 * It is typically called when a new call is made or received by the agent.
 *
 * @param {Call} call - The call object containing details of the call to be added to the history.
 * @returns {void}
 */
export const addCallToHistoryAction = createAsyncThunk<
  void,
  Call,
  { dispatch: AppDispatch; state: RootState }
>("history/addCallToHistoryAction", (call, thunkApi) => {
  let state = thunkApi.getState();
  if (!state.call.id || !state.call.self) return;
  const existingCallIndex = state.history.conversations.findIndex(
    (c: Call) => c.id === call.id
  );
  if (existingCallIndex > -1) {
    thunkApi.dispatch(deleteCallFromHistory(existingCallIndex));
  }
  state = thunkApi.getState();
  if (
    state.history.conversations.length >=
    parseInt(process.env.MAX_HISTORY_ITEMS as string)
  ) {
    const lastEntry: Callback = getLastEntryFromCallOrCallbacks();
    if (
      lastEntry?.conversationMedia &&
      lastEntry?.conversationMedia === "CALLBACK"
    ) {
      thunkApi.dispatch(removeCallbackFromHistory());
    } else {
      thunkApi.dispatch(removeCallFromHistory());
    }
  }
  thunkApi.dispatch(addCallToHistory(call));

  //Handle multi instance history
  state = thunkApi.getState();
  const agent_id = state.agent.id as string;
  const activeInstance = getInstanceAlias();
  let storeObject = {};

  // Retrieve existing data from localStorage
  const existingData = JSON.parse(localStorage.getItem("agent_id") || "{}");

  // Merge new data with existing data
  storeObject = {
    ...existingData,
    [agent_id]: {
      ...existingData[agent_id],
      [activeInstance]: state.history,
    },
  };
  localStorage.setItem("agent_id", JSON.stringify(storeObject));
});

export const addCallbackToHistoryAction = createAsyncThunk<
  void,
  Callback,
  { dispatch: AppDispatch; state: RootState }
>("history/addCallbackToHistoryAction", (callback, thunkApi) => {
  let state = thunkApi.getState();
  if (
    !state.callback.id ||
    !state.callback.self ||
    !state.callback.self?.callback?.callbackNumber ||
    !state.callback.customer?.address
  )
    return;
  const existingCallbackIndex = state.history.callback.findIndex(
    (c: any) => c.id === callback.id
  );
  if (existingCallbackIndex > -1) {
    thunkApi.dispatch(deleteCallbackFromHistory(existingCallbackIndex));
  }
  state = thunkApi.getState();
  if (
    state.history.callback.length >=
    parseInt(process.env.MAX_HISTORY_ITEMS as string)
  ) {
    const lastEntry: any = getLastEntryFromCallOrCallbacks();
    if (
      lastEntry?.conversationMedia &&
      lastEntry?.conversationMedia === "CALLBACK"
    ) {
      thunkApi.dispatch(removeCallbackFromHistory());
    } else {
      thunkApi.dispatch(removeCallFromHistory());
    }
  }
  thunkApi.dispatch(addCallbackToHistory(callback));
  state = thunkApi.getState();
  const agent_id = state.agent.id as string;
  let storeObject: any = {};
  storeObject[agent_id] = state.history;
  localStorage.setItem("agent_id", JSON.stringify(storeObject));
  sendChangeMessage(state);
});

export const addChatToHistoryAction = createAsyncThunk<
  void,
  IndividualChat,
  { dispatch: AppDispatch; state: RootState }
>("history/addChatToHistoryAction", (chat, thunkApi) => {
  let state = thunkApi.getState();
  //check if chat is already released
  if (!chat.self?.id) return;
  const liveChatIndex = state.chat.chats.findIndex((c) => c.id === chat.id);

  if (liveChatIndex === -1) return;

  const existingChatIndex = state.history.chat.findIndex(
    (c: ChatMessage) => c.id === chat.id
  );
  if (existingChatIndex > -1) {
    thunkApi.dispatch(deleteChatFromHistory(existingChatIndex));
  }
  state = thunkApi.getState();
  if (
    state.history.chat.length >=
    parseInt(process.env.MAX_CHAT_HISTORY_ITEMS as string)
  ) {
    thunkApi.dispatch(removeChatFromHistory());
  }
  thunkApi.dispatch(addChatToHistory(chat));
  state = thunkApi.getState();
  const agent_id = state.agent.id as string;
  let storeObject: any = {};
  storeObject[agent_id] = state.history;
  localStorage.setItem("agent_id", JSON.stringify(storeObject));
  sendChangeMessage(state);
});

// Code To add case from history
export const addCaseToHistoryAction = createAsyncThunk<
  void,
  IndividualCase,
  { dispatch: AppDispatch; state: RootState }
>("history/addCaseToHistoryAction", (caseObject, thunkApi) => {
  let state = thunkApi.getState();
  if (!caseObject?.id) return;
  const liveCaseIndex = state.cases.cases.findIndex(
    (c: any) => c.id === caseObject.id
  );
  if (liveCaseIndex === -1) return;
  const existingCaseIndex = state.history.cases.findIndex(
    (c: any) => c.id === caseObject.id
  );
  if (existingCaseIndex > -1) {
    thunkApi.dispatch(deleteCaseFromHistory(existingCaseIndex));
  }
  state = thunkApi.getState();
  if (
    state.history.cases.length >=
    parseInt(process.env.MAX_CHAT_HISTORY_ITEMS as string)
  ) {
    thunkApi.dispatch(removeCaseFromHistory());
  }
  //check if case is already released
  thunkApi.dispatch(addCaseToHistory(caseObject));
  state = thunkApi.getState();
  const agent_id = state.agent.id as string;
  let storeObject: any = {};
  storeObject[agent_id] = state.history;
  localStorage.setItem("agent_id", JSON.stringify(storeObject));
  sendChangeMessage(state);
});

export function getLastEntryFromCallOrCallbacks() {
  const state = storeRTK.getState();
  const callAndCallbacks = [
    ...state.history.callback,
    ...state.history.conversations,
  ];
  callAndCallbacks.sort((a: any, b: any) => {
    let a_time: any = a?.self?.endTime || a?.self?.connectedTime;
    let b_time: any = b?.self?.endTime || b?.self?.connectedTime;
    return b_time - a_time;
  });
  return callAndCallbacks.pop();
}
