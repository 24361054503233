import {
  SESSION_SET,
  LOGOUT,
  SESSION_ERROR,
  RESET_SESSION_ERROR,
  INITIATE_LOGIN,
  INITIATE_RELOGIN,
  SET_VPN_STATUS,
  SET_CALL_AGENT_STATUS,
  SET_CHAT_AGENT_STATUS,
  SET_CASE_AGENT_STATUS,
  INITIATE_DISCONNECT,
  INITIAL_DETAILS_SET,
  INITIATE_LOGIN_SESSION,
  INITIATE_LOGOUT,
  INITIATE_NAVIGATOR,
  SET_CURRENT_SESSION_TAB,
  SET_SESSION_CONNECTED,
  SET_SESSION_RECONNECT,
  RESET_SESSION,
  UPDATE_COOKIE,
  VOICE_UTILIZATION_AVAILABLE,
  WEBRTC_SESSION_WINDOW,
  STORE_LOGIN,
  RESET_STATE,
  SET_MIC_STATUS,
} from "../constants/action-types";

const initialState = {
  connected: false,
  loggedIn: false,
  disconnect: false,
  error: undefined,
  isLoginProcessing: false,
  isLogoutProcessing: false,
  isReloginProcessing: false,
  currentTab: "none", //possible values are {none, call, chat}
  vpnConnected: true,
  callAgentStatusOpen: false,
  chatAgentStatusOpen: false,
  caseAgentStatusOpen: false,
  isOnline: true,
  disconnectedRetries: 0,
  userType: "none",
  cookieWasUpdated: false,
  voiceUtilizationAvailable: false,
  serverInitiatedDisconnect: false,
  loggedInAt: Date.now(),
  isMicEnabled: false,
};

/**
 *
 * @param {*} state
 * @param {*} action
 */
export default (state = initialState, action: any) => {
  switch (action.type) {
    case SESSION_SET:
      return {
        ...state,
        connected: action.connected,
        loggedIn: action.loggedIn,
        disconnect: action.disconnect,
        isLoginProcessing: action.isLoginProcessing,
        isLogoutProcessing: action.isLogoutProcessing,
        isReloginProcessing: action.isReloginProcessing,
        error: undefined,
        currentTab: action.currentTab,
        vpnConnected: action.vpnConnected,
        callAgentStatusOpen: action.callAgentStatusOpen,
        chatAgentStatusOpen: action.chatAgentStatusOpen,
        caseAgentStatusOpen: action.caseAgentStatusOpen,
        loggedInAt: action.loggedInAt,
      };
    case INITIATE_LOGIN:
      return {
        ...state,
        isLoginProcessing: action.isLoginProcessing,
      };
    case INITIATE_RELOGIN:
      return {
        ...state,
        isReloginProcessing: action.isReloginProcessing,
      };
    case SESSION_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case RESET_SESSION_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case SET_VPN_STATUS:
      return {
        ...state,
        vpnConnected: action.vpnConnected,
      };
    case SET_CALL_AGENT_STATUS:
      return {
        ...state,
        callAgentStatusOpen: action.callAgentStatusOpen,
      };
    case SET_CHAT_AGENT_STATUS:
      return {
        ...state,
        chatAgentStatusOpen: action.chatAgentStatusOpen,
      };
    case SET_CASE_AGENT_STATUS:
      return {
        ...state,
        caseAgentStatusOpen: action.caseAgentStatusOpen,
      };
    case UPDATE_COOKIE:
      return {
        ...state,
        cookieWasUpdated: action.cookie,
      };
    case INITIATE_DISCONNECT:
      return {
        ...state,
        serverInitiatedDisconnect: action.serverInitiatedDisconnect,
      };
    case INITIAL_DETAILS_SET:
      return {
        ...state,
        userType: action.userType,
      };
    case INITIATE_LOGIN_SESSION:
      return {
        ...state,
        isLoginProcessing: false,
        isReloginProcessing: false,
        loggedIn: action.payload.loggedIn,
        loggedInAt: action.payload.loggedInAt || Date.now(),
      };
    case INITIATE_LOGOUT:
      return {
        ...state,
        isLogoutProcessing: action.payload.isLogoutProcessing,
      };
    case INITIATE_NAVIGATOR:
      return {
        ...state,
        isOnline: action.payload.isOnline,
      };
    case SET_CURRENT_SESSION_TAB:
      return {
        ...state,
        currentTab: action.payload.id,
      };
    case SET_SESSION_CONNECTED:
      return {
        ...state,
        connected: action.payload.connected,
      };
    case SET_SESSION_CONNECTED:
      return {
        ...state,
        disconnectionTime: new Date().getTime(),
        connected: false,
      };
    case SET_SESSION_RECONNECT:
      return {
        ...state,
        disconnectionTime: undefined,
        connected: true,
        disconnectedRetries: 0,
      };
    case RESET_SESSION:
      return { ...initialState };
    case VOICE_UTILIZATION_AVAILABLE:
      return {
        ...state,
        voiceUtilizationAvailable: action.payload.voiceUtilizationAvailable,
      };
    case WEBRTC_SESSION_WINDOW:
      return {
        ...state,
        webrtcWindowId: action.payload.id,
      };
    case LOGOUT:
      return { ...initialState };
    case STORE_LOGIN:
      return {
        ...state,
        ...action.payload.session,
      };
    case RESET_STATE:
      return {
        ...initialState,
        isReloginProcessing: state.isReloginProcessing, //retain relogin state because we do not want to show login screen while relogin is in progress
      };
    case SET_MIC_STATUS:
      return {
        ...state,
        isMicEnabled: action.payload.isMicEnabled,
      };
    default:
      return state;
  }
};
