/* Include tokens */
import "@spectrum-css/tokens/dist/index.css";
import {
  Alert,
  AlertWhite,
  CallbackIcon,
  CallIcon,
  CasesIcon,
  ChatIcon,
  ContactsIcon,
  CustomResponsesIcon,
  HistoryIcon,
  NotesIcon,
  SearchIcon,
  TemplatesIcon,
  VoicemailIcon,
  WarningIcon,
} from "./../common/Icons";
import "./style.css";
/*
  For components with no other contexts available, load the
  index.css file from the component's package. These are components
  that do not have a spectrum or express context available.
*/
import "@spectrum-css/page/dist/index.css";
import "@spectrum-css/typography/dist/index.css";
import "@spectrum-css/icon/dist/index.css";

/*
  Recommended: For components with multiple contexts available, if you
  want access to all contexts, load the index.css file, which includes
  all contexts and component variables.
*/
import "@spectrum-css/sidenav/dist/index.css";
import {
  Content,
  Heading,
  Icon,
  InlineAlert,
  View,
  Text,
} from "@adobe/react-spectrum";
import { setCurrentSessionTab } from "../../actions/session";
import storeRTK from "../../store/storeRTK";
import { useSelector } from "react-redux";
import { RootState } from "../../store/storeConfig";
import { VOICE_NETWORK_DISCONNECT } from "../../constants/common";

const SiderBar = () => {
  const selectedTab = useSelector(
    (state: RootState) => state.session.currentTab
  );
  const sessionError = useSelector(
    (state: RootState) => state.session.sessionError
  );
  const isVoiceNetworkAvailable = useSelector(
    (state: RootState) => state.webrtc.isVoiceNetworkAvailable
  );
  const changeTab = (id: string) => {
    storeRTK.dispatch(setCurrentSessionTab({ id: id }));
  };

  return (
    <div
      data-html-preview=""
      className="spectrum spectrum--light spectrum--medium spectrum--express"
      style={{
        margin: "12.6% 12.6% 15% 12.6%",
        //padding:"0 4.77%",
        backgroundColor: "#F8F8F8",
      }}
    >
      <Heading level={4} UNSAFE_style={{paddingLeft: "calc(1rem - 1px)"}}>Channels</Heading>
      <nav>
        <ul className="spectrum-SideNav spectrum-SideNav--hasIcon" style={{fontSize: "1rem"}}>
          <li
            className={
              "sidebar-content spectrum-SideNav-item" +
              (selectedTab === "call" ? " is-selected" : "")
            }
            onClick={() => changeTab("call")}
          >
            <CallIcon />
            <Text
              UNSAFE_className="spectrum-SideNav-link-text "
            >
              Call
            </Text>
          </li>
          <li className="sidebar-content spectrum-SideNav-item">
            <CallbackIcon color={"#d6d6d6"} />
            <Text
              UNSAFE_className="spectrum-SideNav-link-text disable-text"
            >
              Callback
            </Text>
          </li>
          <li className=" sidebar-content spectrum-SideNav-item">
            <VoicemailIcon color={"#d6d6d6"} />
            <Text
              UNSAFE_className="spectrum-SideNav-link-text disable-text"
              
            >
              Voicemail
            </Text>
          </li>
          <li className="sidebar-content spectrum-SideNav-item">
            <ChatIcon color={"#d6d6d6"} />
            <Text
              UNSAFE_className="spectrum-SideNav-link-text disable-text"
              
            >
              Chat{" "}
            </Text>
          </li>
          <li
            className={
              "spectrum-SideNav-item sidebar-content" +
              (selectedTab === "case" ? " is-selected" : "")
            }
          // onClick={() => changeTab("case")}
          >
            <CasesIcon color={"#d6d6d6"} />
            <Text
              UNSAFE_className="spectrum-SideNav-link-text disable-text"
              
            >
              Cases
            </Text>
          </li>
        </ul>
      </nav>
      <Heading level={4}>My Space</Heading>
      <nav>
        <ul className="spectrum-SideNav spectrum-SideNav--hasIcon">
          <li className="spectrum-SideNav-item sidebar-content">
            <HistoryIcon color={"#d6d6d6"} />
            <Text
              UNSAFE_className="spectrum-SideNav-link-text disable-text"
              
            >
              History
            </Text>
          </li>
          <li className="spectrum-SideNav-item sidebar-content">
            <ContactsIcon color={"#d6d6d6"} />
            <Text
              UNSAFE_className="spectrum-SideNav-link-text disable-text"
              
            >
              Speed Dials
            </Text>
          </li>
          <li className="spectrum-SideNav-item sidebar-content">
            <SearchIcon color={"#d6d6d6"} />
            <Text
              UNSAFE_className="spectrum-SideNav-link-text disable-text"
              
            >
              KB Search
            </Text>
          </li>
          <li className="spectrum-SideNav-item sidebar-content">
            <TemplatesIcon color={"#d6d6d6"} />
            <Text
              UNSAFE_className="spectrum-SideNav-link-text disable-text"
              
            >
              Templates
            </Text>
          </li>
          <li className="spectrum-SideNav-item sidebar-content">
            <CustomResponsesIcon color={"#d6d6d6"} />
            <Text
              UNSAFE_className="spectrum-SideNav-link-text disable-text"
              
            >
              Custom Responses
            </Text>
          </li>
          <li className="spectrum-SideNav-item sidebar-content">
            <NotesIcon color={"#d6d6d6"} />
            <Text
              UNSAFE_className="spectrum-SideNav-link-text disable-text"
              
            >
              Notes
            </Text>
          </li>
        </ul>
      </nav>
      {selectedTab === "call" &&
        (!navigator.onLine || !isVoiceNetworkAvailable) && (
          <View
            width={"9%"}
            position={"absolute"}
            bottom={"size-0"}
            padding={"8px"}
            backgroundColor={"red-400"}
            borderRadius={"medium"}
            alignSelf={"end"}
            marginBottom={"size-200"}
          >
            <AlertWhite />
            <br></br>
            <Text UNSAFE_className="vpn_err_msg">
              {sessionError || VOICE_NETWORK_DISCONNECT}
            </Text>
          </View>
        )}
    </div>
  );
};

export default SiderBar;
