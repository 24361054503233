import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
// import CaseDetails from "./CaseDetails";
// import CaseWebcase from "./CaseWebcase";
// import CaseHistory from "./CaseHistory";
import "./style.css";
import {
  CASE_STATES,
  AGENT_STATUS_COLOR_MAP,
  API_ACTIVE_CASE_UPDATE,
  BG_BROADCAST,
  API_TAB_CHANGE,
  INTERNAL_UPDATE_DIAL_NUMBER,
  SHOW_NOTIFICATION,
  CHANGE_CASE_AGENT_STATUS,
  TOGGLE_CASE_STATUS_WEBCASE,
} from "../../constants/constants";
import { sendMessage } from "../../services";
// import {
//   ChatRinging,
//   DownArrowRedIcon,
//   UpArrowRedIcon,
//   CallCenter,
// } from "../common/Icons";
// import AgentStatus from "../AgentStatus";
// import {
//   TooltipTrigger,
//   Tooltip,
//   ActionButton,
//   Button,
//   Text,
// } from "@adobe/react-spectrum";
// import { caseHeadingColors } from "./constants";
import ConnectionPopup from "../Dashboard/ConnectionPopup";
import {
  setCaseAgentStatusOpen,
  setCurrentSessionTab,
} from "../../actions/session";
import Emitter from "../../emitter";
import storeRTK from "../../store/storeRTK";
import { RootState } from "../../store"; // Adjust this path based on your project structure
import CaseAcceptDialog from "../CaseInformationContainer/CaseAcceptDialog";
import { IndividualTask } from "../../amazon-connect/models/task";

interface CasePageProps extends PropsFromRedux {}

const CasePage: React.FC<CasePageProps> = ({ cases }: CasePageProps) => {
  const activeCase = cases.cases.find(
    (indCase: IndividualTask) => indCase.id === cases.activeCaseID
  );

  const toggleStatusWebcase = (event: any) => {
    storeRTK.dispatch(setCaseAgentStatusOpen(event.body));
  };

  const statusWebcaseToggle = (value: any) => {
    storeRTK.dispatch(setCaseAgentStatusOpen(value));
    sendMessage({
      type: "notif",
      body: {
        caseAgentStatusOpen: value,
      },
      chan: CHANGE_CASE_AGENT_STATUS,
    });
    sendMessage({
      type: "notif",
      body: {
        body: value,
        chan: TOGGLE_CASE_STATUS_WEBCASE,
        type: "notif",
      },
      chan: BG_BROADCAST,
    });
  };

  useEffect(() => {
    Emitter.on(TOGGLE_CASE_STATUS_WEBCASE, toggleStatusWebcase);
    return () => {
      Emitter.off(TOGGLE_CASE_STATUS_WEBCASE, toggleStatusWebcase);
    };
  }, []);

  const showCaseEditNumberPopup = () => {
    if (activeCase?.customer?.attributes?.PHONE) {
      storeRTK.dispatch(setCurrentSessionTab({ id: "call" }));
      setTimeout(() => {
        Emitter.emit(INTERNAL_UPDATE_DIAL_NUMBER, {
          body: activeCase.customer.attributes.PHONE,
        });
      }, 100);
    }
  };

  const updateCaseHeader = (activeIndex: number, caseID: any) => {
    sendMessage({
      type: "notif",
      body: {
        caseID,
      },
      chan: API_ACTIVE_CASE_UPDATE,
    });
  };

  const getStatusColor = (status: any) => {
    // return AGENT_STATUS_COLOR_MAP[status] || AGENT_STATUS_COLOR_MAP.default;
  };

  const elementToShow: JSX.Element[] = [];

  //console.log("cases **", cases);

  if (cases?.cases?.length) {
    // elementToShow.push(
    //   <>
    //     <div className="rowCase tabsWrapper-multiCase" id="multicaseHeader">
    //       {cases.cases.map((c: any, index: number) => (
    //         <TooltipTrigger position="bottom" delay={0} key={index}>
    //           <ActionButton
    //             onPress={() => {
    //               updateCaseHeader(index, c?.id);
    //             }}
    //             aria-label={c.customer?.name}
    //             data-letters={
    //               `${c.customer?.attributes?.FIRSTNAME?.charAt(0) ?? ""}${
    //                 c.customer?.attributes?.LASTNAME?.charAt(0) ?? ""
    //               }` || "NA"
    //             }
    //             UNSAFE_className={
    //               cases.activeCaseID === c?.id
    //                 ? `selectedMultiCase color-${
    //                     caseHeadingColors[index > -1 ? index % 6 : 0]?.name
    //                   }`
    //                 : `color-${
    //                     caseHeadingColors[index > -1 ? index % 6 : 0]?.name
    //                   }`
    //             }
    //           >
    //             {cases.cases[index]?.self?.state === CASE_STATES.RINGING && (
    //               <span className="badgeMultiCase ringingIcon">
    //                 <ChatRinging />
    //               </span>
    //             )}
    //           </ActionButton>
    //           <Tooltip>
    //             {c.customer?.attributes?.FIRSTNAME
    //               ? `${c.customer.attributes.FIRSTNAME} ${
    //                   c.customer.attributes.LASTNAME ?? ""
    //                 }`
    //               : "Unknown User"}
    //           </Tooltip>
    //         </TooltipTrigger>
    //       ))}
    //     </div>
    //     <div>
    //       {cases.cases.findIndex((ele: any) => ele?.id === cases.activeCaseID) >
    //         -1 && (
    //         <div className="centeringBox">
    //           <>
    //             <span className="statusDialogHeading">
    //               <span className="dialogHeading caseNameElipse">
    //                 {activeCase?.customer?.attributes?.FIRSTNAME
    //                   ? `${activeCase.customer.attributes.FIRSTNAME} ${
    //                       activeCase.customer.attributes.LASTNAME ?? ""
    //                     }`
    //                   : "NA"}
    //               </span>
    //             </span>
    //           </>
    //           <div>
    //             <Button
    //               variant="primary"
    //               type="button"
    //               isDisabled={activeCase.selfState !== "ESTABLISHED"}
    //               UNSAFE_className="caseCallButton"
    //               onPress={showCaseEditNumberPopup}
    //             >
    //               <CallCenter /> <Text>Call</Text>
    //             </Button>
    //           </div>
    //         </div>
    //       )}
    //     </div>
    //     <div className="mainContent mainContent__case">
    //       <div>
    //         {cases.cases.findIndex(
    //           (ele: any) => ele.id === cases.activeCaseID
    //         ) > -1 &&
    //           cases?.cases[0]?.selfState === CASE_STATES.ESTABLISHED && (
    //             <CaseDetails
    //               caseData={
    //                 cases.cases.find(
    //                   (ele: any) => ele.id === cases.activeCaseID
    //                 ) || {}
    //               }
    //             />
    //           )}
    //       </div>
    //     </div>
    //   </>
    // );

    elementToShow.push(
      <footer>
        {/* {session.caseAgentStatusOpen && (
          <>
            <span
              className="isStatusWebcaseOpenInCaseBtn"
              onClick={() => statusWebcaseToggle(!session.caseAgentStatusOpen)}
            >
              <DownArrowRedIcon
                color="white"
                background={getStatusColor(agent.state.displayName)}
              />
            </span>
            <footer>
              <AgentStatus onSubmit={() => null} />
            </footer>
          </>
        )} */}
        {cases.cases.findIndex((ele: any) => ele.id === cases.activeCaseID) >
          -1 && (
          <>
            {/* {!session.caseAgentStatusOpen && (
              <span
                className="isStatusWebcaseOpenInCaseBtn"
                onClick={() =>
                  statusWebcaseToggle(!session.caseAgentStatusOpen)
                }
              >
                <UpArrowRedIcon background="#e34850" color="#ffffff" />
              </span>
            )}
            <ConnectionPopup
              message={
                "Case functionality is down due to internet disconnect. Once internet is back, AIC will reconnect automatically."
              }
            /> */}
          </>
        )}
      </footer>
    );
  } else {
    elementToShow
      .push
      // <div className="mainContent">
      //   <CaseHistory />
      // </div>
      ();
    elementToShow.push(
      <>
        <ConnectionPopup
          message={
            "Case functionality is down due to internet disconnect. Once internet is back, AIC will reconnect automatically."
          }
        />
        {/* <footer>
          <AgentStatus onSubmit={() => null} />
        </footer> */}
      </>
    );
  }
  elementToShow.push(<CaseAcceptDialog/>);

  return <>{elementToShow}</>;
};

const mapStateToProps = (state: RootState) => ({
  cases: state.cases,
  agent: state.agent,
  session: state.session,
  history: state.history,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CasePage);
