import {
  initiateNavigator,
  resetSessionError,
  setCallAgentStatusOpen,
  setCaseAgentStatusOpen,
  setChatAgentStatusOpen,
  setCurrentSessionTab,
  setMicrophoneStatus,
  setSessionError,
} from "../actions/session";
import {
  START_SESSION,
  STOP_SESSION,
  TELEMETRY_ERROR,
  BG_NOTIFICATION,
  TELEMETRY_INFO,
  STATE_LOGIN,
  CCP_STATE,
  INTERNAL_RESET_CCP_STATE,
  INTERNAL_CALL_API,
  BG_ASK_MEDIA_PERM,
  API_TAB_CHANGE,
  CHANGE_CALL_AGENT_STATUS,
  CHANGE_CHAT_AGENT_STATUS,
  CHANGE_CASE_AGENT_STATUS,
  UPDATE_MEDIA_DEVICES_LIST,
  API_UPDATE_RING_MEDIA_DEVICE,
  API_UPDATE_ROD_DEVICE,
  STATE_UPDATE_RING_MEDIA_DEVICE,
  SESSION_ERROR,
  RESET_SESSION_ERROR,
  VOICE_NETWORK_STATE,
  SESSION_TIMER_ALERT,
  // API_CALL_HANGUP,
  API_CALL_REJECT,
  // API_WINDOW_RELOAD,
  //CCP_ASK_MEDIA_PERMISSION,
  //OPEN_MEDIA_PERMISSION_TAB,
  API_DOWNLOAD_LOGS_STATUS,
  BG_API_STATUS,
  SHOW_CCP_CONNECTION_ERROR,
  // BG_LOGIN_DETAILS,
  INIT_CCP,
  CCP_ASK_MEDIA_PERMISSION,
  FAIL_SESSION,
  API_CALL_HANGUP,
} from "../constants/events";
import // initiateLogout,
  // initiateNavigator,
  // resetSessionError,
  // setCallAgentStatus,
  //setCaseAgentStatus,
  // setChatAgentStatus,
  // setSessioError,
  "../reducers/session";

import {
  MISSING_GROUP_MSG,
  MISSING_GROUP_TITLE,
  NETWORK_DISCONNECT_RECONNECT,
  ALLOWED_CONV_MEDIA_TYPES,
  VOICE_NETWORK_DISCONNECT,
  SESSION_TIMER_WARNING,
  AGENT_STATUS_LIST,
} from "../constants/common";
import {
  changeAgentState,
  checkVoicePolicy,
  getCannedResponses,
  getProfile,
  getlostMessages,
  stopSession,
  rejectCallback,
  getAgentGeoLocation,
  addCCPProfile,
} from "../api/session-api";
import { IndividualChat } from "../types/chat.type";
import { checkInternet, logoutChannel, showErrorToast } from "../utils/utils";
import {
  setCCPState,
  updateMediaDeviceAction,
  updateMediaDevicesAction,
  updateRingOnDifferentDevice,
  updateVoiceNewtworkState,
} from "../actions/webrtc";
import storeRTK from "../store/storeRTK";
import {
  loginFailure,
  loginSession,
  logoutSession,
} from "../connector/session";
import Emitter from "../emitter";
import { SessionEvent } from "../types/call.type";
import { setCompleteAgentData } from "./agent";
import { Agent, AgentState, ConnectInstance } from "../types/agent.type";
import { IntervalType } from "../types/generic.types";
import { BG_LOGIN_DETAILS } from "../constants/constants";

export const CCP_STATES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
  CONNECTED: 4,
  DISCONNECTED: 5,
};
let retryCount = 0;
const maxRetries = 2;
const delay = 30000; // 30 seconds
let initCCPTimeout: NodeJS.Timeout | null = null;
class Session {
  ccpStateInterval: IntervalType = null;
  geoLocationInterval: IntervalType = null;
  agentProfile: Agent | null = null;
  sessionTimer: IntervalType = null;
  lastPingTime: number = 0;
  constructor() {
    this.onloggedIn = this.onloggedIn.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.startCcpConnection = this.startCcpConnection.bind(this);
    this.performNetworkReconnectActions =
      this.performNetworkReconnectActions.bind(this);
    this.getStartCCPConnectionParams =
      this.getStartCCPConnectionParams.bind(this);
      this.logoutFromAnotherTab = this.logoutFromAnotherTab.bind(this);
  }

  /**
   * Initiates the login process for the agent.
   * This function retrieves the agent's profile and checks if the agent has CCP access.
   * If the agent has CCP access, it proceeds to perform the necessary login actions.
   *
   * @returns {void}
   */
  login(): void {
    setTimeout(() => {
      getProfile()
        .then((agentProfile: Agent | undefined) => {
          this.agentProfile = agentProfile ?? null;
          if (agentProfile && agentProfile.email) {
            this.agentProfile = agentProfile;
            // Checking CCP Access when it is true then only to proceed to login.
            const ccpAccess =
              typeof agentProfile?.aicConfig?.CCP_ACCESS === "string"
                ? JSON.parse(agentProfile?.aicConfig?.CCP_ACCESS)
                : agentProfile?.aicConfig?.CCP_ACCESS;
            if (ccpAccess) {
              const params: {
                ccpUrl: string;
                region: string;
                instanceAlias: string;
              } | null = this.getStartCCPConnectionParams(agentProfile);
              if (params) {
                Emitter.emit(INIT_CCP, params);
              } else {
                storeRTK.dispatch(
                  loginFailure(
                    `Error in getting instance List or ccp url ${JSON.stringify(
                      params
                    )}, aic config ${JSON.stringify(agentProfile)}`
                  )
                );
              }
            } else {
              Emitter.emit(TELEMETRY_ERROR, {
                type: "notif",
                chan: TELEMETRY_INFO,
                body: {
                  type: BG_LOGIN_DETAILS,
                  chan: BG_LOGIN_DETAILS,
                  info: `Login failed due to ccp access false for user ${agentProfile?.email}`,
                },
              });
              storeRTK.dispatch(
                loginFailure("Error in login due to ccp access false")
              );
            }
          } else {
            storeRTK.dispatch(
              loginFailure("Error in getting profile, no email id received")
            );
          }
        })
        .catch((error: Error) => {
          console.error("Error in getting profile api", error);

          storeRTK.dispatch(loginFailure("Error in getting profile"));
        });
    }, 500);
  }

  /**
   * Initiates the logout process for the agent.
   * This function performs necessary cleanup actions and emits telemetry data indicating the logout event.
   *
   * @returns {Promise<void>}
   */
  async logout(): Promise<void> {
    console.log("Logging out: Logout function called!!!");
    Emitter.emit(TELEMETRY_ERROR, {
      type: "notif",
      chan: TELEMETRY_INFO,
      body: {
        type: STOP_SESSION,
        chan: STOP_SESSION,
        info: `Logging out: Logout function called!!!`,
      },
    });

    // Clear intervals only if they exist
    const intervals = [
      this.ccpStateInterval,
      this.sessionTimer,
      this.geoLocationInterval,
    ];
    intervals.forEach((interval) => interval && clearInterval(interval));

    // Reset intervals
    [this.ccpStateInterval, this.sessionTimer, this.geoLocationInterval] = [
      null,
      null,
      null,
    ];

    try {
      await stopSession();
    } catch (e) {
      console.error("Error stopping session:", e);
    }
    // Final dispatch after session stop
    storeRTK.dispatch(logoutSession("No session conn, logging out user"));
    setTimeout(() => {
      logoutChannel.postMessage("Logout");
    }, 100);
  }
  logoutFromAnotherTab = () => {
    const intervals = [
      this.ccpStateInterval,
      this.sessionTimer,
      this.geoLocationInterval,
    ];
    intervals.forEach((interval) => interval && clearInterval(interval));

    // Reset intervals
    [this.ccpStateInterval, this.sessionTimer, this.geoLocationInterval] = [
      null,
      null,
      null,
    ];
    storeRTK.dispatch(logoutSession("No session conn, logging out user"));
  }
  private async restartCCPConn() {
    const state = storeRTK.getState();
    const isLoggedIn = state.session.loggedIn;
    addCCPProfile().then(() => {
      const params: {
        ccpUrl: string;
        region: string;
        instanceAlias: string;
      } | null = this.getStartCCPConnectionParams(state.agent);
      console.log(params);
      if (params && isLoggedIn) {
        if (initCCPTimeout) {
          clearTimeout(initCCPTimeout);
          initCCPTimeout = null;
        }
        initCCPTimeout = setTimeout(() => {
          Emitter.emit(INIT_CCP, params);
        }, 1000);
      } else {
        console.log("Error in getting instance List or ccp url", params);
      }
    }).catch((error) => {
      this.logout();
      console.log("CCP Init start failed due to error: ", error);
    })
  }
  /**
   * Handles actions to be performed when the agent successfully logs in.
   * This function is triggered after the agent has logged in and sets up the necessary state and configurations.
   *
   * @returns {void}
   */
  onloggedIn(ev: SessionEvent<string>): void {
    // Check if login was successful
    console.log("Login event received", ev);
    if (ev.success) {
      const state = storeRTK.getState();
      const isLoggedIn = state.session.loggedIn;
      // Restart CCP connection if it is closed only covers page refresh cases
      if(state.webrtc.ccpSessionState === CCP_STATES.CLOSED || !state.webrtc.ccpSessionState || state.webrtc.ccpSessionState === CCP_STATES.DISCONNECTED) {
        this.restartCCPConn();
      }
      setCompleteAgentData(isLoggedIn ? state.agent : this.agentProfile);
      const initialState = {
        body: {
          agent: isLoggedIn ? state.agent : this.agentProfile,
          state: { loggedIn: true },
          call: {},
          case: {},
        },
        success: true,
      } as any;

      if (
        (this.agentProfile?.email || state.agent?.agentInfo?.email) &&
        initialState &&
        state.session.loggedIn !== true
      ) {
        storeRTK.dispatch(loginSession(initialState.body));
        getCannedResponses();
        Emitter.emit(TELEMETRY_ERROR, {
          type: "notif",
          chan: TELEMETRY_INFO,
          body: {
            type: STATE_LOGIN,
            message: `User with email ${initialState?.body?.agent?.email
              } logged into AICWEB v${process.env.REACT_APP_VERSION
              } at ${new Date().getTime()}`,
          },
        });
      } else if (!this.agentProfile?.email && !state.agent?.agentInfo?.email) {
        storeRTK.dispatch(
          loginFailure("Error in getting profile, no email id received")
        );
      } else if (state.session.loggedIn === true) {
        Emitter.emit(TELEMETRY_ERROR, {
          type: "notif",
          chan: TELEMETRY_INFO,
          body: {
            type: STATE_LOGIN,
            message: `User with email ${initialState?.body?.agent?.email
              } refreshed browser window ${process.env.REACT_APP_VERSION
              } at ${new Date().getTime()}`,
          },
        });
      }
      //This is to check on login if agent has right group assigned or not
      if (initialState?.body?.agent) {
        const agent = initialState?.body?.agent;
        if (
          !agent?.allowedConversationMedia ||
          agent?.allowedConversationMedia?.length === 0
        ) {
          setTimeout(
            () =>
              Emitter.emit(BG_NOTIFICATION, {
                type: "error",
                title: MISSING_GROUP_TITLE,
                message: MISSING_GROUP_MSG,
              }),
            500
          );
        }
      }
      clearInterval(this.sessionTimer ?? undefined);
      this.sessionTimer = setInterval(() => {
        this.checkSessionTimeout();
      }, 1000);

      let geoLocatInterval;
      if (state.agent?.aicConfig?.agent_geo_location_time_interval) {
        geoLocatInterval =
          state.agent?.aicConfig?.agent_geo_location_time_interval * 1000;
      } else if (
        this.agentProfile?.aicConfig?.agent_geo_location_time_interval
      ) {
        geoLocatInterval =
          this.agentProfile?.aicConfig?.agent_geo_location_time_interval * 1000;
      } else {
        // If agent_geo_location_time is NaN, set geoLocationTime to default value
        geoLocatInterval = 5000;
      }
      clearInterval(this.geoLocationInterval ?? undefined);
      this.geoLocationInterval = setInterval(() => {
        try {
          getAgentGeoLocation();
        } catch (error: any) {
          Emitter.emit(TELEMETRY_ERROR, {
            type: "notif",
            chan: TELEMETRY_INFO,
            body: {
              type: SESSION_ERROR,
              message: "Error in getting geo location Api",
              timestamp: new Date().getTime(),
            },
          });
          console.log("Error in getting geo location", error);
        }
      }, geoLocatInterval);

      clearInterval(this.ccpStateInterval ?? undefined);

      this.ccpStateInterval = setInterval(() => {
        const state = storeRTK.getState();
        const params: {
          ccpUrl: string;
          region: string;
          instanceAlias: string;
        } | null = this.getStartCCPConnectionParams(state.agent);
        if (params) {
          this.startCcpConnection(params);
        } else {
          console.log(
            "Error in getting instance List or ccp url inside ccpStateInterval",
            params
          );
        }
      }, 5000);
    } else {
      const errorEvent = ev as SessionEvent<string>;
      console.log("Error during login", errorEvent);
      storeRTK.dispatch(
        loginFailure(errorEvent.body || "Error occurred during login.")
      );
    }
  }

  callApi(ev: SessionEvent<any>, timeout?: number) {
    try {
      return Promise.resolve({
        body: {},
        success: true,
      } as SessionEvent<any>);
    } catch (error) {
      return Promise.reject({
        body: "Error in sending message",
        error,
        success: false,
      } as SessionEvent<any>);
    }
  }

  //Api call related functions
  ccpStatusChanged(ev: SessionEvent<{ status: number }>) {
    const state = storeRTK.getState();
    // change ccpSessionState only if it is different from current state
    if (state.webrtc.ccpSessionState !== ev.body?.status) {
      Emitter.emit(TELEMETRY_ERROR, {
        type: "notif",
        chan: TELEMETRY_INFO,
        body: {
          type: CCP_STATE,
          chan: CCP_STATE,
          reason: JSON.stringify(ev),
        },
      });
      if (ev.body && ev.body?.status) {
        storeRTK.dispatch(setCCPState({ ccpSessionState: ev.body?.status }));
      }
    }
  }

  resetCCPsessionState() {
    storeRTK.dispatch(setCCPState({ ccpSessionState: CCP_STATES.CLOSED }));
    Emitter.emit(TELEMETRY_ERROR, {
      type: "notif",
      chan: TELEMETRY_INFO,
      body: {
        type: "notif",
        chan: CCP_STATE,
        body: {
          status: CCP_STATES.CLOSED,
          message: `ccp state is reset.`,
        },
      },
    });
  }

  isVoiceAllowed() {
    const agentData = this.agentProfile || storeRTK.getState().agent;
    if (
      // check if voice available in allowedConversationMedia
      agentData?.allowedConversationMedia?.filter(
        (media: string) => media === ALLOWED_CONV_MEDIA_TYPES.VOICE
      )?.length !== 0
    ) {
      if (agentData?.availableMedia?.length !== 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async checkEligiblityForVoice() {
    if (!this.isVoiceAllowed()) {
      return false;
    }
    return await checkVoicePolicy(
      this.agentProfile || storeRTK.getState().agent
    ).then((res) => res.voice_allowed);
  }

  private async startCcpConnection(_t: {
    ccpUrl: string;
    region: string;
    instanceAlias: string;
  }) {
    let micStatus = false;
    const isInternetConnected = await checkInternet();
    const state = storeRTK.getState();

    await navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => (micStatus = true))
      .catch(() => (micStatus = false));
    storeRTK.dispatch(setMicrophoneStatus({ isMicEnabled: micStatus }));

    if (isInternetConnected) {
      if (!state.session.isOnline && state.session.loggedIn) {
        //if user is offline and network is back, perform reconnect actions
        const sessionTimedOut = this.isSessionTimeout();
        if (!sessionTimedOut) {
          this.performNetworkReconnectActions();
          setTimeout(() => {
            this.checkCCPStateAndRetry(_t);
          }, 100);
        }
      }
      this.lastPingTime = new Date().getTime();
      let isEligibleForVoice = await this.checkEligiblityForVoice();
      if (!isEligibleForVoice) {
        //save in store and call tab red
        //update telemetry
        Emitter.emit(TELEMETRY_ERROR, {
          type: "notif",
          chan: TELEMETRY_INFO,
          body: {
            type: SESSION_ERROR,
            chan: SESSION_ERROR,
            message: VOICE_NETWORK_DISCONNECT,
            timestamp: new Date().getTime(),
          },
        });
        // Clear call if call exists
        let isEligibleForVoice = await this.checkEligiblityForVoice();
        if(!isEligibleForVoice) {
          console.log("!!!!!!! Call exists, clearing call on network reconnect, from inside startCcpConnection");
          this.clearCallOnNetworkVPNDisconnect();
        }
      }
      storeRTK.dispatch(updateVoiceNewtworkState(isEligibleForVoice));
    } else {
      const state = storeRTK.getState();
      if (state.webrtc.ccpSessionState !== CCP_STATES.CLOSED) {
        storeRTK.dispatch(setCCPState({ ccpSessionState: CCP_STATES.CLOSED }));
      }
      storeRTK.dispatch(
        setSessionError({
          message: NETWORK_DISCONNECT_RECONNECT,
          type: "ping",
        })
      );
      Emitter.emit(TELEMETRY_ERROR, {
        type: "notif",
        chan: TELEMETRY_INFO,
        body: {
          message: NETWORK_DISCONNECT_RECONNECT,
          type: "ping",
          chan: SESSION_ERROR,
          timestamp: new Date().getTime(),
        },
      });

      storeRTK.dispatch(initiateNavigator({ isOnline: false }));
      if (state?.callback?.selfState === "RINGING") {
        rejectCallback();
      }
    }
  }

  /**
   * Retrieves the parameters required to start a CCP (Contact Control Panel) connection.
   * This function extracts the necessary connection parameters from the agent's profile.
   *
   * @param {Agent} agentProfile - The profile of the agent containing configuration details.
   * @returns {{ ccpUrl: string; region: string; instanceAlias: string } | null}
   *          An object containing the CCP connection parameters or null if parameters are missing.
   */
  getStartCCPConnectionParams(
    agentProfile: Agent
  ): { ccpUrl: string; region: string; instanceAlias: string } | null {
    const instanceList = agentProfile.instanceConfig;
    if (instanceList && instanceList.length > 0) {
      const instance = instanceList.find(
        (inst: ConnectInstance) => inst.active
      );
      if (instance && agentProfile.aicConfig?.ccp_url) {
        return {
          ccpUrl: agentProfile.aicConfig?.ccp_url,
          region: instance.region,
          instanceAlias: instance.instance_alias,
        };
      } else {
        console.log(
          "Error in getting instance List or ccp url",
          instanceList,
          agentProfile.aicConfig
        );
      }
    } else {
      console.log(
        "Error in getting instance List or ccp url",
        instanceList,
        agentProfile.aicConfig
      );
    }

    return null;
  }
  private checkSessionTimeout() {
    const state = storeRTK.getState();
    const { aicConfig } = state.agent;
    const sessionWarningTime =
      (Number(aicConfig?.session_warning_duration) || 0) * 1000 ||
      SESSION_TIMER_WARNING * 1000; //converting to mili seconds
    if (state?.session?.loggedInAt) {
      const sessionDuration = new Date().getTime() - state?.session?.loggedInAt;
      if (sessionDuration > sessionWarningTime) {
        this.sendSessionTimeoutAlert();
      }
    }
  }

  private sendSessionTimeoutAlert() {
    const state = storeRTK.getState();
    if (state?.agent?.state?.displayName != AGENT_STATUS_LIST.AVAILABLE) {
      const stateOfAgent = state?.agent?.states?.allowedStates.filter(function (
        st: AgentState
      ) {
        return st.displayName === AGENT_STATUS_LIST.AVAILABLE;
      });
      if (typeof stateOfAgent !== "undefined" && stateOfAgent.length > 0) {
        changeAgentState({ agentState: stateOfAgent[0].id });
      }
    }

    if (!state?.call?.id && !state?.chat?.activeChatID) {
      Emitter.emit(SESSION_TIMER_ALERT, { body: { dialog: true } });
    }
  }
  private clearCallOnNetworkVPNDisconnect() {
    const state = storeRTK.getState();
    if (state.call.id) {
      if (
        state.call.self?.state === "ESTABLISHED" ||
        state.call.self?.state === "DIALING" || (state.call.self?.state === "RINGING" && state.call.self?.direction === "OUTBOUND")
      ) {
        Emitter.emit(API_CALL_HANGUP, { body: { conversationId: state.call.id } });
      } else {
        Emitter.emit(API_CALL_REJECT, { body: { conversationId: state.call.id } });
      }
      showErrorToast(
        "Call disconnected due to network/VPN disconnect",
        "callHangupToast"
      );
    }
  }

  private isSessionTimeout() {
    const state = storeRTK.getState();
    const { aicConfig } = state.agent;
    const sessionWarningTime =
      (Number(aicConfig?.session_warning_duration) || 0) * 1000 ||
      SESSION_TIMER_WARNING * 1000; //converting to mili seconds
    if (state?.session?.loggedInAt) {
      const sessionDuration = new Date().getTime() - state?.session?.loggedInAt;
      if (sessionDuration > sessionWarningTime) {
        return true;
      }
    }
    return false;
  }

  /**
 * Checks the state of the CCP (Contact Control Panel) and retries the connection if necessary.
 * This function verifies if the CCP is in the desired state and attempts to reconnect if it is not.
 * It is typically used to ensure that the CCP remains connected and functional.
 * 
 * @param {number} retryCount - The number of times to retry the connection if the CCP is not in the desired state.
 * @returns {Promise<void>}
 */
  private checkCCPStateAndRetry(params: {
    ccpUrl: string;
    region: string;
    instanceAlias: string;
  }) {
    const state = storeRTK.getState();
    if (state.webrtc.ccpSessionState !== CCP_STATES.CONNECTED) {
      if (retryCount < maxRetries) {
        retryCount++;
        this.performNetworkReconnectActions(true);
        setTimeout(() => {
          if (state.webrtc.ccpSessionState !== CCP_STATES.CONNECTED) {
            if (retryCount < maxRetries) {
              this.checkCCPStateAndRetry(params);
            } else {
              retryCount = 0;
              console.log("Max retries reached. Could not connect to CCP.");

              Emitter.emit(TELEMETRY_ERROR, {
                type: "notif",
                chan: TELEMETRY_INFO,
                success: true,
                body: {
                  type: SHOW_CCP_CONNECTION_ERROR,
                  chan: SHOW_CCP_CONNECTION_ERROR,
                  message: "Max retries reached. Could not connect to CCP.",
                  timestamp: new Date().getTime(),
                  context: { agent: true },
                },
              });
            }
          }
        }, delay * retryCount);
      } else {
        retryCount = 0;
        console.log("Max retries reached. Could not connect to CCP.");

        Emitter.emit(TELEMETRY_ERROR, {
          type: "notif",
          chan: TELEMETRY_INFO,
          success: true,
          body: {
            type: SHOW_CCP_CONNECTION_ERROR,
            chan: SHOW_CCP_CONNECTION_ERROR,
            message: "Max retries reached. Could not connect to CCP.",
            timestamp: new Date().getTime(),
            context: { agent: true },
          },
        });
      }
    }
  }
  performNetworkReconnectActions = (retry?: boolean) => {
    //reset session error state also

    const state = storeRTK.getState();
    // if(state.webrtc.ccpSessionState === CCP_STATES.CONNECTED) {
    //     sendMessage({
    //         type: 'notif',
    //         chan: CCP_STATE,
    //         body: {
    //             status: CCP_STATES.CONNECTED
    //         }
    //     })
    //     return;
    // }
    const currentTime = new Date().getTime();
    const retryPrefix = retry ? "RETRYING: " : "";
    const prefix =
      retryPrefix +
      `NETWORK_RECONNECTED: User ${state.agent.firstName} ${state.agent.lastName
      } with email = ${state.agent?.email} is online now at ${new Date()}`;
    console.log(
      `${prefix}, his current state is = ${state.agent.state}, perform connection refresh actions at}`
    );

    Emitter.emit(TELEMETRY_ERROR, {
      type: "notif",
      chan: TELEMETRY_INFO,
      success: true,
      body: {
        type: NETWORK_DISCONNECT_RECONNECT,
        chan: NETWORK_DISCONNECT_RECONNECT,
        message: `${prefix}, his current state is = ${state.agent.state}, perform connection refresh actions}`,
        timestamp: currentTime,
      },
    });

    if (state.session.error) {
      storeRTK.dispatch(resetSessionError());
    }
    storeRTK.dispatch(initiateNavigator({ isOnline: true }));

    if (state?.chat?.chats?.length > 0) {
      try {
        state?.chat?.chats?.forEach((ch: IndividualChat) => {
          if (ch?.id) {
            getlostMessages(ch.id);
          }
        });
      } catch (error) {
        console.log("error get lost messages", error);
      }
    }
    if(state.call.id) {
      setTimeout(() => {
        console.log("!!!!!!! Call exists, clearing call on network reconnect, from inside performNetworkReconnectActions");
        this.clearCallOnNetworkVPNDisconnect();
      }, 1000)
    }
  };
}

export const session = new Session();

export default function registerCcpSessionHandlers() {
  Emitter.on(START_SESSION, session.login);
  Emitter.on(STOP_SESSION, session.logout);
  Emitter.on(STATE_LOGIN, session.onloggedIn);
  Emitter.on(FAIL_SESSION, session.logout);
  Emitter.on(CCP_STATE, session.ccpStatusChanged);
  Emitter.on(INTERNAL_RESET_CCP_STATE, session.resetCCPsessionState);
  Emitter.on(INTERNAL_CALL_API, session.callApi);
  // Emitter.on(CCP_PING, session.ccpPingRequest);

  // Emitter.on(BG_ASK_MEDIA_PERM, (ignored, port) => {
  //   openPermissionTab();
  // });

  // Emitter.on(CCP_ASK_MEDIA_PERMISSION, (ignored, port) => {
  //   sendMessage({
  //     type: "notif",
  //     chan: CCP_ASK_MEDIA_PERMISSION,
  //     success: true,
  //   });
  Emitter.emit(TELEMETRY_ERROR, {
    type: "notif",
    chan: TELEMETRY_INFO,
    body: {
      type: CCP_ASK_MEDIA_PERMISSION,
      chan: CCP_ASK_MEDIA_PERMISSION,
      message: "CCP is asking for media permission",
      context: {
        agent: true,
      },
    },
  });
  // });
  // Emitter.on(OPEN_MEDIA_PERMISSION_TAB, (ignored, port) => {
  //   //openPermissionTabForCCP();
  // });
  // persist main header tab on backend and send event back to all tabs of UI
  Emitter.on(API_TAB_CHANGE, (ev: SessionEvent<string>) => {
    if (ev.body) {
      storeRTK.dispatch(setCurrentSessionTab(ev.body));
    }
  });

  // status of dialog open/close on call, chat or case tab
  Emitter.on(CHANGE_CALL_AGENT_STATUS, (ev: any) => {
    if (ev.body) {
      storeRTK.dispatch(setCallAgentStatusOpen(ev.body));
    }
  });
  Emitter.on(CHANGE_CHAT_AGENT_STATUS, (ev: any) => {
    if (ev.body) {
      storeRTK.dispatch(setChatAgentStatusOpen(ev.body));
    }
  });
  Emitter.on(CHANGE_CASE_AGENT_STATUS, (ev: any) => {
    if (ev.body) {
      storeRTK.dispatch(setCaseAgentStatusOpen(ev.body));
    }
  });

  // Media devices list fetch and update events
  Emitter.on(UPDATE_MEDIA_DEVICES_LIST, (ev: SessionEvent<any>) => {
    storeRTK.dispatch(updateMediaDevicesAction(ev.body?.mediaDevices));
    // sendMessage({
    //   type: "notif",
    //   chan: UPDATE_MEDIA_DEVICES_LIST,
    //   body: ev.body,
    // });
  });
  Emitter.on(API_UPDATE_ROD_DEVICE, (ev: any) => {
    storeRTK.dispatch(
      updateRingOnDifferentDevice(ev.body?.ringOnDifferentDevice)
    );
  });
  Emitter.on(STATE_UPDATE_RING_MEDIA_DEVICE, (ev: any) => {
    if (ev.success) {
      storeRTK.dispatch(updateMediaDeviceAction(ev.body?.deviceId));
    }

    //BG will update UI about the setSink Device success
    // sendMessage(ev);
  });

  // Emitter.on(API_DOWNLOAD_LOGS_STATUS, (ev: any) => {
  //   sendMessage({
  //     type: "notif",
  //     chan: BG_API_STATUS,
  //     body: {
  //       message: ev.body,
  //       status: ev.success,
  //     },
  //   });
  // });
}
