import { Provider, View, defaultTheme } from "@adobe/react-spectrum";
import { connect } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LoginPage from "./components/Login";
import LoginCallback from "./components/Login/LoginCallback";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RootState } from "./store";
import { useEffect, useState } from "react";
import storeRTK from "./store/storeRTK";
import { CURRENT_DOMAIN } from "./api/session-api";
import { worker } from "./main";
import TabCountSync from "./utils/TabCountSync";
import { logoutChannel } from "./utils/utils";
import { CONNECT_STREAMS_DIV_ID } from "./constants/constants";
import { session } from "./controller/session";

function mapStateToProps(state: RootState) {
  return {
    session: state.session,
    loggedIn: state.session.loggedIn,
    geoData: state.agent?.agentInfo?.geoLocation,
    agent: state.agent,
  };
}

const App = (props: any) => {
  const [tabs, setTabs] = useState(1);

  useEffect(() => {
    logoutAllTabs();
  }, []);

  useEffect(() => {
    const tcs = new TabCountSync();
    window.addEventListener("unload", () => tcs.close());
    tcs.subscribe((count: number) => {
      setTabs(count);
    });
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const state = storeRTK.getState();
      //hide prompt on login page
      if (
        props.agent.id &&
        props.agent.id !== "" &&
        !props.session.isReloginProcessing
      ) {
        if (tabs < 2) {
          event.preventDefault();
          event.returnValue = "";

          worker.port.postMessage({
            url: `${CURRENT_DOMAIN}/v1/session/agent/web/removeCCPProfile`,
            geoData: state.agent?.agentInfo?.geoLocation,
          });
        }
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [props.session.isReloginProcessing, props.agent]);

  // const logout = () => {
  //   storeRTK.dispatch(logoutSession("No session conn, logging out user"));
  // };

  const logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
      session.logoutFromAnotherTab();
      logoutChannel.close();
    };
  };

  return (
    <Provider
      theme={defaultTheme}
      colorScheme="light"
      height={"100vh"}
      width={"100vw"}
    >
      <ToastContainer limit={1} />
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login/redirect" element={<LoginCallback />} />
        </Routes>
      </Router>
      <div id={CONNECT_STREAMS_DIV_ID} style={{ display: "none" }}>
        <View>Amazon Connect</View>
      </div>
    </Provider>
  );
};

export default connect(mapStateToProps)(App);
