import { useSelector } from "react-redux";
import { ProgressCircle } from "@adobe/react-spectrum";
import "./style.css";
import { RootState } from "../../store/storeConfig";

export default ({ checkLoading }: { checkLoading: string }) => {
  const isLoading = useSelector((state: RootState) => state.common.isLoading);
  const ccpSessionState = useSelector(
    (state: RootState) => state.webrtc.ccpSessionState
  );

  if (checkLoading === "webrtc" && ccpSessionState !== 4) {
    return (
      <div className="loaderBox">
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </div>
    );
  }

  if (checkLoading === "loading" && isLoading) {
    return (
      <div className="loaderBox">
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </div>
    );
  }

  return null;
};
