import {
  AlertDialog,
  Content,
  DialogContainer,
  Text,
} from "@adobe/react-spectrum";
import React, { useEffect } from "react";
import storeRTK from "../../store/storeRTK";
import { caseAnswer } from "../../controller/cases";
import Emitter from "../../emitter";
import {
  API_CASE_ANSWER,
  API_CASE_REJECT,
  CASE_STATES,
} from "../../constants/constants";
import { IndividualTask } from "../../amazon-connect/models/task";
import { connect } from "react-redux";

interface CaseAcceptDialogProps {
  indCase: IndividualTask;
  setOpen: Function;
  isOpen: boolean;
}
function mapStateToProps(state: any) {
  return {
    cases: state.cases,
  };
}

const CaseAcceptDialog = (props: any) => {
  const [isOpen, setOpen] = React.useState(false);
  const indCase = props.cases.cases.find((c: any) => c.self.state === "RINGING");
  useEffect(() => {
    if (indCase && indCase?.id) {
      setOpen(true);
    }
  }, [indCase]);
  const rejectCaseHandler = () => {
    let caseState = storeRTK.getState().cases;
    const indCase = props.cases.cases.find((c: any) => c.self.state === "RINGING");
    caseAnswer(indCase.id);
    Emitter.emit(API_CASE_REJECT, {
      body: {
        participantId: indCase?.self?.id,
        conversationId: indCase.id,
        state: "disconnected",
      },
    });
  };

  const pickupCaseHandler = () => {
    let caseState = storeRTK.getState().cases;
    const indCase = props.cases.cases.find((c: any) => c.self.state === "RINGING");
    caseAnswer(indCase.id);
    Emitter.emit(API_CASE_ANSWER, {
      body: {
        participantId: indCase?.self?.id,
        conversationId: indCase?.id,
        state: "connected",
      },
    });
  };

  let handleModal = (action: string) => {
    action === "cancel" ? rejectCaseHandler() : pickupCaseHandler();
    setOpen(false);
  };

  return (
    <div>
      <DialogContainer
        onDismiss={() => {
          setOpen(false);
        }}
        isDismissable={false}
      >
        {isOpen && indCase?.self?.state === CASE_STATES.RINGING && (
          <AlertDialog
            title="New case request"
            variant="confirmation"
            primaryActionLabel="Accept"
            cancelLabel="Reject"
            onCancel={() => handleModal("cancel")}
            onPrimaryAction={() => handleModal("accept")}
          >
            <Content>
              <Text>
                {indCase?.customer?.attributes?.CASE_TITLE &&
                  indCase.customer.attributes.CASE_TITLE}
              </Text>
            </Content>
          </AlertDialog>
        )}
      </DialogContainer>
    </div>
  );
};
export default connect(mapStateToProps)(CaseAcceptDialog);
