import { connect } from "react-redux";
import Sidebar from './Siderbar.tsx';

declare module 'react' {
    interface JSXIntrinsicElements {
        'my-sidenav': any;
    }
}

function mapStateToProps(state: any) {
    return {
        call: state.call,
        agent: state.agent,
        callback: state.callback,
        webrtc: state.webrtc,
        chat: state.chat,
        session: state.session,
        cases: state.cases,
    };
}

const SidebarCP = (props: any) => {
    return (
        <Sidebar/>
    )
}

export default connect(mapStateToProps)(SidebarCP);