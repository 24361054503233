import {
  CONTACT_EVENT_STATE_MAP,
  CONTACT_STATE_MAP,
  CHANNEL_TYPES,
  LOG_PREFIX,
  STATE_CASE,
} from "../../constants/constants";
import { IndividualTask } from "../models/task";
import { formatCaseAttributes } from "../../utils";
import { clearCurrentTaskContact, disconnectTaskContact } from "../api/task";
import { changeAgentStateToAvailable } from "./agent";
import Emitter from "../../emitter";
import storeRTK from "../../store/storeRTK";
import { setActiveCaseUpdate } from "../../actions/case";
import { API_MILO_CASE_CHANNEL_TRANSACTION } from "../../constants/events";
import Player from "../../utils/audio/player.js";
const lp = LOG_PREFIX + "[handlers.task.ts]: ";
const defaultValue = { self: {}, customer: {} };

var tasks: { [key: string]: IndividualTask } = {};

export const casePlayer = new Player({ type: "CASE", loop: true });

export function setTask(taskObj: IndividualTask) {
  //Below ignore duplicate call ended events
  const existingChat = tasks.hasOwnProperty(taskObj.id);
  if (taskObj.self?.state === CONTACT_STATE_MAP.ENDED && !existingChat) return;
  if (taskObj.self?.state === CONTACT_STATE_MAP.ENDED) {
    if (tasks.hasOwnProperty(taskObj.id)) {
      delete tasks[taskObj.id];
    }
  } else {
    tasks[taskObj.id] = JSON.parse(JSON.stringify(taskObj));
  }
}

export function handleIncomingTask(contact: any) {
  casePlayer.pause();
  casePlayer.play();
  console.log(lp + "Incoming task contact: ", contact);
  updateTaskAndSend(CONTACT_EVENT_STATE_MAP.INCOMING, contact);
  return {
    contactId: contact.contactId || contact.getContactId(),
    eventType: CONTACT_EVENT_STATE_MAP.RINGING,
    channel: CHANNEL_TYPES.TASK,
  };
}
export function handleConnectingTask(contact: any) {
  console.log(lp + "CONNECTING task contact: ", contact);
  //updateTaskAndSend(CONTACT_EVENT_STATE_MAP.CONNECTING, contact);
}

export function handleMissedTask(contact: any) {
  console.log(lp + "MISSED task contact: ", contact);
  changeAgentStateToAvailable();
  clearCurrentTaskContact(contact.contactId || contact.getContactId());
  updateTaskAndSend(CONTACT_EVENT_STATE_MAP.MISSED, contact);
  casePlayer.pause();
  return {
    contactId: contact.contactId || contact.getContactId(),
    eventType: CONTACT_EVENT_STATE_MAP.MISSED,
    channel: CHANNEL_TYPES.TASK,
  };
}

export function handleDestroyTask(contact: any) {
  console.log(lp + "DESTROY task contact: ", contact);
  updateTaskAndSend(CONTACT_EVENT_STATE_MAP.DESTROYED, contact);
  casePlayer.pause();
}

export function handleAcceptedTask(contact: any) {
  console.log(
    lp +
      "ACCEPTED task contact: accepted by agent. Contact state is " +
      contact.getStatus().type,
    contact
  );
  updateTaskAndSend(CONTACT_EVENT_STATE_MAP.ACCEPTED, contact);
  casePlayer.pause();
}
export function handleConnectedTask(contact: any) {
  console.log(
    lp +
      "CONNECTED task contact: connected to agent. Contact state is " +
      contact.getStatus().type,
    contact
  );
  updateTaskAndSend(CONTACT_EVENT_STATE_MAP.CONNECTED, contact);
  casePlayer.pause();
  // Send message to bg to open milo URL
  Emitter.emit(API_MILO_CASE_CHANNEL_TRANSACTION,{
    type: 'notif',
    chan: API_MILO_CASE_CHANNEL_TRANSACTION,
    body: {
        contactId: contact.contactId || contact.getContactId()
    }
});
}

export function handleRefreshTask(contact: any) {
  try {
    updateTaskAndSend(CONTACT_EVENT_STATE_MAP.REFRESH, contact);
  } catch (e) {
    handleEndedTask(contact);
  }
}

export function handleEndedTask(contact: any) {
  console.log(
    `${lp} contact ${contact.getContactId()} ENDED task contact: has ended. Contact state is ${
      contact.getStatus().type
    }`
  );
  updateTaskAndSend(CONTACT_EVENT_STATE_MAP.ENDED, contact);
  if (contact.getStatus().type === "error") {
    console.log(
      `${lp} contact ${contact.getContactId()} ERROR task contact: has ended. Contact state is ${
        contact.getStatus().type
      }`
    );
  }
}

export function updateTaskAndSend(type: any, contact: any) {
  const existingTask = Object.assign(
    {},
    tasks[contact.contactId || contact.getContactId()]
  );
  let task = updateTask(type, contact);
//   storeRTK.dispatch(setActiveCaseUpdate(task?.id));
  if (JSON.stringify(existingTask) === JSON.stringify(task)) return;
  console.log(
    lp + "Updated Task:",
    JSON.stringify(task),
    "Existing Task:",
    JSON.stringify(existingTask)
  );
  if (task?.id) {
    setTask({ ...task });
    storeRTK.dispatch(setActiveCaseUpdate(task?.id));
    Emitter.emit(STATE_CASE, { body: task });
    setTask({ ...task });
  }
  task = null;
}

function updateTask(
  contactEventType: string,
  contact: any
): IndividualTask | null {
  console.log(lp + "Updating Task: called ****", contactEventType, contact);
  try {
    let id = contact.contactId || contact.getContactId();
    let task: any = tasks[id];
    switch (contactEventType) {
      case CONTACT_EVENT_STATE_MAP.INITIAL:
        try {
          const cstate = contact.getState();
          const ctype: string = cstate?.type;
          let cstate_type: string = (CONTACT_EVENT_STATE_MAP as any)[
            ctype.toUpperCase()
          ];
          if (
            cstate_type === CONTACT_EVENT_STATE_MAP.ENDED ||
            ctype === "error"
          ) {
            return task;
          }
        } catch (error) {
          console.log(
            lp + "Error during state check of task contact",
            contact,
            error
          );
        }

        // This handling handles initial scenerio when chat event arrieves first time
        if (!task) {
          task = defaultValue;
        }
        task.id = contact.contactId || contact.getContactId();
        task.self.id = contact.getAgentConnection().connectionId;
        task.self.state = CONTACT_STATE_MAP.INCOMING;
        task.selfState = CONTACT_STATE_MAP.INCOMING;
        task.conversationMedia = "";
        task.self.userId = contact.getAgentConnection().getConnectionId();
        task.self.queueId = contact.getAgentConnection().getConnectionId();
        task.self.type = "AGENT";
        task.self.attributes = {};
        task.customer.state = CONTACT_STATE_MAP.CONNECTED;
        task.customer.attributes = formatCaseAttributes(
          contact.getAttributes()
        );
        task.customer.address =
          formatCaseAttributes(contact.getAttributes())?.PHONE_NUMBER || " NA";
        task.customer.type = "CUSTOMER";
        break;

      case CONTACT_EVENT_STATE_MAP.INCOMING:
        break;
      case CONTACT_EVENT_STATE_MAP.ACCEPTED:
      case CONTACT_EVENT_STATE_MAP.CONNECTED:
        task.self.state = CONTACT_STATE_MAP.CONNECTED;
        task.selfState = CONTACT_STATE_MAP.CONNECTED;
        if (!task.self.connectedTime) {
          task.self.connectedTime = new Date().getTime();
        }

        task.customer.attributes = formatCaseAttributes(
          contact.getAttributes()
        );
        if (!task.customer.name) {
          task.customer.name =
            contact.getAttributes()?.customerName?.value || "";
        }
        break;

      case CONTACT_EVENT_STATE_MAP.MISSED:
        const contactId = contact.contactId || contact.getContactId();
        break;
      case CONTACT_EVENT_STATE_MAP.ENDED:
      case CONTACT_EVENT_STATE_MAP.DESTROYED:
        task.self.state = CONTACT_STATE_MAP.ENDED;
        task.selfState = CONTACT_STATE_MAP.ENDED;
        if (!task.self.endTime) {
          task.self.endTime = new Date().getTime();
        }
        break;

      case CONTACT_EVENT_STATE_MAP.REFRESH:
        const state = contact.getState();
        const type: string = state.type;
        if (type === "error") {
          if ((task && task.id === contactId) || !task?.id) {
            disconnectTaskContact({ conversationId: contactId });
            break;
          }
        }
        let selfState: string = (CONTACT_STATE_MAP as any)[type.toUpperCase()];
        if (
          task &&
          task.id === (contact.contactId || contact.getContactId()) &&
          selfState !== CONTACT_EVENT_STATE_MAP.ENDED
        ) {
          if (!task.self.connectedTime) {
            task.self.connectedTime = new Date().getTime();
          }
        }
        break;
      default:
        break;
    }
    return task;
  } catch (err) {
    console.log(lp + "Error from updateTask" + err);
    return null;
  }
}
