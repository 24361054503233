import { getAicServerUrl } from "../config/index.ts";

export function loginHandler(selectedRegion?: string) {
  let SERVER_URL = getAicServerUrl();
  try {
    console.log("Login handler called", selectedRegion);
    // If user clicks on login button, it will route to default login page(without region) and if user selects region, it will route to selected region login page
    if(selectedRegion) {
      location.href = `${SERVER_URL}/v1/session/aws/web/login/${selectedRegion}`;
    } else {
      location.href = `${SERVER_URL}/v1/session/aws/web/login`;
    }
  } catch (error) {
    console.error("Error logging in:", error);
  }
}



