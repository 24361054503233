import { isCallbackDashboardEnabled } from "../../../config";
import CallbackDashboard from "../../CallbackDashboard/CallbackDashboard";
import CallHistory from "../../CallTab/CallHistory/CallHistory";
import CallWebcase from "../../CallTab/CallWebcase";
import CaseComponent from "../../CasePage/CaseDetails/CaseComponent";
import CaseHistoryComponent from "../../CasePage/CaseDetails/CaseHistoryComponent";
import Supervisor from "../../Supervisor";

export const caseTabs = [
  {
    id: 1,
    name: "Live Case (s)",
    children: <CaseComponent />,
  },
  {
    id: 2,
    name: "History",
    children: <CaseHistoryComponent />,
  },
];
export const callTabs = [
  {
    id: 1,
    name: "Call",
    children: <CallWebcase />,
    active: true,
  },
  {
    id: 2,
    name: "History",
    children: <CallHistory />,
    active: true,
  },
  {
    id: 3,
    name: "Monitoring",
    children: <Supervisor />,
    active: true,
  },
  {
    id: 4,
    name: "Callback Dashboard",
    children: <CallbackDashboard/>,
    active: isCallbackDashboardEnabled(),
  }
];
