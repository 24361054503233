import { useState } from "react";

import {
  Provider,
  defaultTheme,
  Dialog,
  Button,
  ActionButton,
  DialogTrigger,
  Heading,
  View,
  Flex,
  Text,
  Divider,
} from "@adobe/react-spectrum";

import "./LoginForm.css";
import { Alert } from "../common/Icons";
import { checkVpnConnNTimes } from "../../utils/utils";
import { initiateLogin } from "../../actions/session";
import store from "../../store";
import { loginHandler } from "../../controller/okta";
import Emitter from "../../emitter";
import { setLoader } from "../../actions/common";
import storeRTK from "../../store/storeRTK";
import { useSelector } from "react-redux";
import loginImage from "../../images/login-image.png";
import loginHeadphone from "../../images/login-headphone.png";

const LoginForm = () => {
  const [alert, setAlert] = useState(false);
  const isLoading = useSelector((state: any) => state.common?.isLoading);

  const onCloseHandler = () => {
    setAlert(false);
    storeRTK.dispatch(setLoader(false));
  };

  const handleLogin = () => {
    loginHandler();
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    storeRTK.dispatch(setLoader(true));
    setTimeout(() => {
      handleLogin();
    }, 100);
  };

  return (
    <Provider theme={defaultTheme}>
      <View
        UNSAFE_className="loginFormWrapper"
        UNSAFE_style={{ backgroundColor: "#EAEAEA" }}
      >
        {alert && (
          <DialogTrigger type="modal" mobileType="modal" defaultOpen={true}>
            <ActionButton isHidden isQuiet></ActionButton>
            {(close) => (
              <Dialog size={"S"} UNSAFE_className="custom-dialog">
                <Heading level={4}>
                  <span>
                    <span style={{ marginRight: "15px" }}>
                      <Alert />
                    </span>
                    <span>Unable to connect to server</span>
                  </span>
                </Heading>
                <Button
                  UNSAFE_className={"buttonMin"}
                  variant="cta"
                  onPress={onCloseHandler}
                >
                  OK
                </Button>
              </Dialog>
            )}
          </DialogTrigger>
        )}
        {!isLoading && (
          <form className="loginForm" action="" onSubmit={(e) => onSubmit(e)}>
            <Flex
              direction={"row"}
              gap={"5rem"}
              UNSAFE_className="login-wrapper"
            >
              <Flex
                UNSAFE_className="login-image-wrapper"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img src={loginImage} alt="login-image" />
              </Flex>

              <Flex
                direction={"column"}
                UNSAFE_className="login-content-wrapper"
              >
                <Flex marginTop={"2.96296%"}>
                  <Text UNSAFE_className="login-content-heading">
                    Welcome !!
                  </Text>
                </Flex>
                <Flex marginTop={"6.94814%"}>
                  <img
                    className="login-headphone-wrapper"
                    src={loginHeadphone}
                    alt="login-headphone"
                  />
                </Flex>
                <Flex marginTop={".74074%"}>
                  <Heading
                    level={3}
                    UNSAFE_className="login-subcontent-heading"
                    
                  >
                    Adobe Interaction Client
                  </Heading>
                </Flex>
                <Flex marginEnd={"2.70833%"}>
                  <Text UNSAFE_className="login-content">
                    Omnichannel Softphone Client for handling customer
                    interactions via Phone, Chat and Case Channels
                  </Text>
                </Flex>
                <Flex marginTop={"3.70370%"}>
                  <Button
                    type="submit"
                    variant="cta"
                    UNSAFE_className="loginButton"
                    data-testid="loginButton"
                  >
                    Login to AIC
                  </Button>
                </Flex>
                <Flex
                  justifyContent={"end"}
                  flexGrow={1}
                  alignItems={"end"}
                  height={100}
                >
                  <Text UNSAFE_className="login-version">
                    v{process.env.REACT_APP_VERSION}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </form>
        )}
      </View>
      {/* <Flex justifyContent={"center"} height={50}>
        <Text UNSAFE_className="login-footer">
          © 2021 Adobe Inc. All rights reserved.
        </Text>
      </Flex> */}
    </Provider>
  );
};

export default LoginForm;
