import { setLoader } from "../actions/common";
import { initiateRelogin } from "../actions/session";
import { API_AGENT_CHANGE } from "../constants/constants";
import {
  STATE_CHANGE_INSTANCE,
  STOP_SESSION,
} from "../constants/events";
import Emitter from "../emitter";
import storeRTK from "../store/storeRTK";
import { loginHandler } from "./okta";
import session from "./session";

/**
 * Handles the switching of instances.
 * This function is triggered when the user switches between different instances.
 * It updates the state and performs necessary actions based on the selected instance.
 *
 * @param {string} event.newInstanceAlias - The alias of the new instance to switch to.
 * @returns {void}
 */
export function instanceSwitchHandler(ev: {body: {currentInstanceAlias: string}}): void { 
    // remove onbeforeunload event listener
    storeRTK.dispatch(initiateRelogin(true));
    // wait some time so that the agent's current state changes to offline successfully
    Emitter.emit(STOP_SESSION);
    setTimeout(() => {
        loginHandler(ev.body?.currentInstanceAlias)
    }, 3000);
}

export default function registerCcpInstanceHandlers() {
  Emitter.on(STATE_CHANGE_INSTANCE, instanceSwitchHandler);
}
