import { useState } from "react";
import CaseAi from "../../CasePage/CaseAI/CaseAi";
import { CrossIcon, QuestionMarkIcon } from "../../common/Icons";
import { ActionButton, Tooltip, TooltipTrigger } from "@adobe/react-spectrum";

const CaseAiDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => setIsOpen(!isOpen);

  return (
    <div className={`drawer ${isOpen ? "open" : "closed"}`}>
      <div className="toggle-button" onClick={toggleDrawer}>
        {isOpen ? (
          <CrossIcon color="#fff" />
        ) : (
          <span>
            <TooltipTrigger delay={0} placement="left">
              <ActionButton
                aria-label="ask ai"
                UNSAFE_style={{
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onPress={toggleDrawer}
                marginEnd={10}
              >
                <QuestionMarkIcon color="#fff" />
              </ActionButton>
              <Tooltip>Ask AI</Tooltip>
            </TooltipTrigger>
          </span>
        )}
      </div>
      {isOpen && (
        <>
          <CaseAi caseID="" isDisabled />
        </>
      )}
    </div>
  );
};

export default CaseAiDrawer;
