import {
  HISTORY_RESET,
  HISTORY_SET,
  NEW_HISTORY_SET,
  ADD_CALL_HISTORY,
  REMOVE_CALL_HISTORY,
  LOGOUT,
  DELETE_CALL_HISTORY,
  CALLBACK_HISTORY,
  ADD_CALLBACK_HISTORY,
  REMOVE_CALLBACK_HISTORY,
  DELETE_CALLBACK_HISTORY,
  CHAT_HISTORY,
  ADD_CHAT_HISTORY,
  REMOVE_CHAT_HISTORY,
  DELETE_CHAT_HISTORY,
  CASE_HISTORY,
  ADD_CASE_HISTORY,
  REMOVE_CASE_HISTORY,
  DELETE_CASE_HISTORY,
  STORE_LOGIN,
  RESET_STATE,
  HISTORY_CASE_SET_SUMMARY_ISSUE,
  HISTORY_CASE_SET_SUGGEST_ISSUE,
} from "../constants/action-types";

interface Conversation {
  customer: {
    displayName: string;
    phoneNumber: string;
  };
  History?: any[];
}

interface State {
  conversations: Conversation[];
  callback: any[];
  chat: any[];
  cases: any[];
}

const initialState: State = {
  conversations: [],
  callback: [],
  chat: [],
  cases: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case HISTORY_SET:
      return {
        ...state,
        ...action.history,
      };
    case HISTORY_RESET:
      return { ...state, conversations: [], callback: [], chat: [], cases: [] };
    case NEW_HISTORY_SET:
      return {
        ...state,
        conversations: action.history.conversations || [],
        callback: action.history.callback || [],
        chat: action.history.chat || [],
        cases: action.history.cases || [],
      };
    case ADD_CALL_HISTORY:
      return {
        ...state,
        conversations: [action.history, ...state.conversations],
      };
    // Will uncomment when groupig of call history is implemented
    // case ADD_CALL_HISTORY:
    //   return {
    //     ...state,
    //     conversations: state.conversations.some(
    //       (convo) =>
    //         convo.customer.displayName ===
    //           action.history.customer.displayName &&
    //         convo.customer.phoneNumber === action.history.customer.phoneNumber
    //     )
    //       ? state.conversations.reduce((acc: any, convo: any) => {
    //           if (
    //             convo.customer.displayName ===
    //               action.history.customer.displayName &&
    //             convo.customer.phoneNumber ===
    //               action.history.customer.phoneNumber
    //           ) {
    //             // Create a new conversation with action.history as the latest entry
    //             const updatedConvo = {
    //               ...action.history,
    //               history: [convo, ...(convo.history || [])], // Push the existing conversation into history
    //             };
    //             return [updatedConvo, ...acc]; // Prepend the updated conversation to the result
    //           }
    //           return [...acc, convo];
    //         }, [])
    //       : [action.history, ...state.conversations], // If no match, prepend the new entry
    //   };
    case REMOVE_CALL_HISTORY:
      const conversations = [...state.conversations];
      conversations.pop();
      return {
        ...state,
        conversations,
      };
    case DELETE_CALL_HISTORY:
      const deletedconversation = [...state.conversations];
      deletedconversation.splice(action.history, 1);
      return {
        ...state,
        conversations: deletedconversation,
      };
    case CALLBACK_HISTORY:
      return {
        ...state,
        callback: [...action.history],
      };
    case ADD_CALLBACK_HISTORY:
      return {
        ...state,
        callback: [action.history, ...state.callback],
      };
    case REMOVE_CALLBACK_HISTORY:
      const callback = [...state.callback];
      callback.pop();
      return {
        ...state,
        callback,
      };
    case DELETE_CALLBACK_HISTORY:
      let deletedCallback = [...state.callback];
      deletedCallback.splice(action.history, 1);
      return {
        ...state,
        callback: deletedCallback,
      };
    case CHAT_HISTORY:
      return {
        ...state,
        chat: [...action.history],
      };
    case ADD_CHAT_HISTORY:
      let chat = action.history;
      chat.messages = [];
      return {
        ...state,
        chat: [chat, ...state.chat],
      };
    case REMOVE_CHAT_HISTORY:
      const removeChat = [...state.chat];
      removeChat.pop();
      return {
        ...state,
        chat: removeChat,
      };
    case DELETE_CHAT_HISTORY:
      let deletedChat = [...state.chat];
      deletedChat.splice(action.history, 1);
      return {
        ...state,
        chat: deletedChat,
      };
    case CASE_HISTORY:
      return {
        ...state,
        cases: [...action.history],
      };
    case ADD_CASE_HISTORY:
      return {
        ...state,
        cases: [action.history, ...state.cases],
      };
    case REMOVE_CASE_HISTORY:
      const cases = [...state.cases];
      cases.pop();
      return {
        ...state,
        cases,
      };
    case DELETE_CASE_HISTORY:
      let deleteCases = [...state.cases];
      deleteCases.splice(action.history, 1);
      return {
        ...state,
        cases: deleteCases,
      };

    case HISTORY_CASE_SET_SUMMARY_ISSUE: {
      const { caseID, userResponse, aiResponse } = action;
      const caseCopy: any = [...state.cases];
      const caseIndex = caseCopy.findIndex(
        (currentValue: any) => currentValue.id === caseID
      );

      if (caseIndex !== -1) {
        const caseToUpdate: any = caseCopy[caseIndex];
        const updatedMessages = caseToUpdate.summaryCaseMessages
          ? [...caseToUpdate.summaryCaseMessages, { userResponse, aiResponse }]
          : [{ userResponse, aiResponse }];

        caseCopy[caseIndex] = {
          ...caseToUpdate,
          summaryCaseMessages: updatedMessages,
        };
      }

      return {
        ...state,
        cases: caseCopy,
      };
    }
    case HISTORY_CASE_SET_SUGGEST_ISSUE: {
      const { caseID, userResponse, aiResponse } = action;
      const caseCopy: any = [...state.cases];
      const caseIndex = caseCopy.findIndex(
        (currentValue: any) => currentValue.id === caseID
      );

      if (caseIndex !== -1) {
        const caseToUpdate: any = caseCopy[caseIndex];
        const updatedMessages = caseToUpdate.suggestCaseMessages
          ? [...caseToUpdate.suggestCaseMessages, { userResponse, aiResponse }]
          : [{ userResponse, aiResponse }];

        caseCopy[caseIndex] = {
          ...caseToUpdate,
          suggestCaseMessages: updatedMessages,
        };
      }

      return {
        ...state,
        cases: caseCopy,
      };
    }

    case LOGOUT:
      return initialState;
    case STORE_LOGIN:
      return {
        ...state,
        ...action.payload.history,
      };
    case RESET_STATE:
      return { conversations: [], callback: [], chat: [], cases: [] };
    default:
      return state;
  }
};
