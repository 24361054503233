import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  ActionButton,
  Content,
  Dialog,
  DialogTrigger,
  Item,
  ListView,
  SearchField,
} from "@adobe/react-spectrum";
import { RootState } from "../../store/storeConfig";
import { ChevronDown, UserGroup } from "../common/Icons";
import { AgentState, AssignQueues } from "../../types/agent.type";
import Emitter from "../../emitter";
import "./style.css";
import { API_QUEUE_JOIN_UPDATE } from "../../constants/events";
interface QueueSelectionProps {}

const QueueSelection: React.FC<QueueSelectionProps> = () => {
  let agent = useSelector((state: RootState) => state.agent);
  let webrtc = useSelector((state: RootState) => state.webrtc);
  const [isQueueDialogOpen, setIsQueueDialogOpen] = useState(true);
  const [currentStateQueues, setCurrentStateQueues] = useState(new Set([]));
  const [disabledQueueIds, setDisabledQueueIds] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredSearch, setFilteredSearch] = useState<any>([]);
  const queuesToUpdate = useRef<{ id: any; joined: boolean }[]>([]);

  const updateSelectedQueues = (queues: any) => {
    let changedQueues = [];
    if (queues.size > currentStateQueues.size) {
      changedQueues = [...queues].filter(
        (x) => !currentStateQueues.has(x as never)
      );
      changedQueues = changedQueues?.map((e) => {
        return { id: e, joined: true };
      });
    } else {
      changedQueues = [...currentStateQueues].filter((x) => !queues.has(x));
      changedQueues = changedQueues?.map((e) => {
        return { id: e, joined: false };
      });
    }
    queuesToUpdate.current.push(changedQueues[0]);
    setCurrentStateQueues(queues);
  };

  // For Debounce timeout
  useEffect(() => {
    const timeOut = setTimeout(() => {
      sendMessageToBG();
    }, 3000);
    return () => clearTimeout(timeOut);
  }, [currentStateQueues]);

  const sendMessageToBG = () => {
    if (queuesToUpdate.current.length > 0) {
      Emitter.emit(API_QUEUE_JOIN_UPDATE, {
        queueJoiningList: queuesToUpdate.current,
      });
      queuesToUpdate.current = [];
    }
  };

  useEffect(() => {
    if (webrtc.isVoiceNetworkAvailable === false) {
      const phoneQueuesList = agent?.queuesAssigned?.entities?.filter(
        (queue: AssignQueues) =>
          queue?.channel?.toLowerCase()?.includes("voice")
      );
      if (phoneQueuesList && phoneQueuesList.length > 0) {
        setDisabledQueueIds(phoneQueuesList.map((q: AssignQueues) => q.id));
      }
    } else {
      if (disabledQueueIds.length > 0) {
        console.log("VPN is back enabling phone queues");
        setDisabledQueueIds([]);
      }
    }
  }, [webrtc.isVoiceNetworkAvailable]);

  useEffect(() => {
    setFilteredSearch(
      getUniqueObjectsOnly(agent?.queuesAssigned?.entities, "id")
    );
  }, [agent.stateModifiedDate]);

  const handleSearch = (e: string) => {
    setSearchValue(e);
  };

  useEffect(() => {
    updateCurrentStateQueues();
  }, []);

  const getUniqueObjectsOnly = (arrayObjs: any, uniqueKey: any) => {
    return [
      ...new Map(
        arrayObjs?.map((item: any) => [item[uniqueKey], item])
      ).values(),
    ];
  };

  useEffect(() => {
    if (searchValue) {
      let queuesList = getUniqueObjectsOnly(
        agent?.queuesAssigned?.entities,
        "id"
      );
      const filteredItems =
        queuesList?.length > 0 &&
        queuesList.filter((queue: any) =>
          queue.name
            .toString()
            .toLowerCase()
            .includes(searchValue.toString().toLowerCase())
        );
      setFilteredSearch(filteredItems);
    }
  }, [searchValue]);

  const updateCurrentStateQueues = () => {
    if (agent?.queuesAssigned?.entities) {
      const arr = agent?.queuesAssigned?.entities.filter((elem: AgentState) => {
        return !!elem.id;
      });
      const currentQueues: any = new Set(
        arr
          .filter((d: any) => d.id && d.joined)
          .map((elem: AgentState) => elem.id)
      );
      setCurrentStateQueues(currentQueues);
    }
  };

  useEffect(() => {
    updateCurrentStateQueues();
  }, [JSON.stringify(agent?.queuesAssigned)]);

  return (
    <div className={"openStatusPanelIcon"}>
      <DialogTrigger type="popover">
        <ActionButton UNSAFE_className="queueSelectionButton">
          {!!agent?.queueSelectionAllowed &&
            agent?.queueSelectionAllowed !== "false" && (
              <div className={"selectQueueButton"}>
                <span>
                  <UserGroup /> Queues
                </span>
                <span
                  onClick={() => {
                    setIsQueueDialogOpen(true);
                  }}
                >
                  {currentStateQueues.size > 0
                    ? `${currentStateQueues.size} Selected`
                    : ""}{" "}
                  <ChevronDown />
                </span>
              </div>
            )}
        </ActionButton>
        <Dialog size="S" width={"250px"} height={"450px"}>
          <Content>
            <SearchField
              width={"100%"}
              necessityIndicator="icon"
              marginBottom={"15px"}
              UNSAFE_className="qsearchField"
              value={searchValue}
              onClear={() => {
                setSearchValue("");
                setFilteredSearch(
                  getUniqueObjectsOnly(agent?.queuesAssigned?.entities, "id")
                );
              }}
              onChange={(e) => handleSearch(e)}
            />
            <ListView
              items={
                searchValue.length > 0
                  ? filteredSearch
                  : getUniqueObjectsOnly(agent?.queuesAssigned?.entities, "id")
              }
              selectionMode="multiple"
              aria-label="queues"
              selectedKeys={currentStateQueues}
              disabledKeys={disabledQueueIds}
              height={"320px"}
              width={"100%"}
              onSelectionChange={(selected) => {
                updateSelectedQueues(selected);
              }}
              UNSAFE_className="queues-list"
              selectionStyle="checkbox"
              isQuiet
            >
              {(item: any) => <Item key={item.id}>{item.name}</Item>}
            </ListView>
          </Content>
        </Dialog>
      </DialogTrigger>
    </div>
  );
};

export default QueueSelection;
