import { BG_MEDIA_PERM_CHECK } from "../constants/constants";
import Emitter from "../emitter";
import { checkMediaPermission } from "../utils/utils";

export const registerPermissionCheckEvents = () => {
  // Media permission check events
  checkMediaPermission((res: boolean) => {
    Emitter.emit(BG_MEDIA_PERM_CHECK, {
      type: "res",
      chan: BG_MEDIA_PERM_CHECK,
      body: { wasAllowed: res },
      success: true,
    });
  });
};
