import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AGENT_AFTER_CALL_WORK_STATE_ID,
  CCP_STATES,
} from "../constants/common";
import {
  TELEMETRY_INFO,
  INTERNAL_SESSION_START,
  STOP_SESSION,
  STOP_CCP,
} from "../constants/events";
import { AppDispatch, RootState } from "../store";
import {
  initiateLogin,
  initiateLogout,
  initiateRelogin,
  login,
  resetSessionState,
  setCurrentSessionTab,
} from "../actions/session";
import storeRTK from "../store/storeRTK";
import { setCCPState } from "../actions/webrtc";
import { resetState, storeLogin } from "../actions/common";
import Emitter from "../emitter";
import { AgentState } from "../types/agent.type";
import {
  API_AGENT_CHANGE,
  SESSION_ERROR,
  START_SESSION,
} from "../constants/constants";
import {TELEMETRY_ERROR} from "../constants/events";
import { logoutChannel } from "../utils/utils";

export const loginSession = createAsyncThunk<
  void,
  RootState,
  { dispatch: AppDispatch; state: RootState }
>("session/loginSession", async (initialState, thunkApi) => {
  try {
    const oldState = storeRTK.getState();
    thunkApi.dispatch(
      login({
        loggedIn: true,
        loggedInAt:
          oldState.session.loggedIn === true
            ? oldState.session?.loggedInAt
            : undefined,
      })
    );
    thunkApi.dispatch(storeLogin(initialState));
    storeRTK.dispatch(setCurrentSessionTab({ id: "call" }));
    const state = storeRTK.getState();
    if (state?.agent?.id) {
      //retain old state if it is trying to relogin
      //If relogin due to service worker inactivity, old state from chrome store should be reset
      if (oldState.session.loggedIn) {
        const agentStateId = oldState.agent.state?.id;
        if (agentStateId && agentStateId !== AGENT_AFTER_CALL_WORK_STATE_ID) {
          Emitter.emit(API_AGENT_CHANGE, { agentState: agentStateId });
        } else {
          //if fresh login make agent available
          const agentStateId1 = state.agent?.states?.allowedStates?.filter(
            (s: AgentState) => s?.displayName === "Available"
          )[0].id;
          if (agentStateId1) {
            Emitter.emit(API_AGENT_CHANGE, { agentState: agentStateId1 });
          }
        }
      } else {
        const agentStateId = state.agent?.states?.allowedStates?.filter(
          (s: AgentState) => s?.displayName === "Available"
        )[0].id;
        if (agentStateId) {
          Emitter.emit(API_AGENT_CHANGE, { agentState: agentStateId });
        }
      }
    }
    Emitter.emit(INTERNAL_SESSION_START);
    Emitter.emit(TELEMETRY_ERROR, {
      type: "notif",
      chan: TELEMETRY_INFO,
      success: true,
      context: {
        agent: true,
      },
      body: {
        type: START_SESSION,
        chan: START_SESSION,
        message: "Agent login successful.",
      },
    });
  } catch (error) {
    console.log("Agent login error in session/loginSession", error);
    Emitter.emit(TELEMETRY_ERROR, {
      type: "notif",
      chan: TELEMETRY_INFO,
      success: false,
      body: {
        type: "session/loginSession",
        chan: SESSION_ERROR,
        message: "Agent login error in session/loginSession",
        reason: error,
      },
    });
  }
});

export const loginFailure = createAsyncThunk<
  void,
  string,
  { dispatch: AppDispatch }
>("session/loginFailure", (errorMessage, thunkApi) => {
  thunkApi.dispatch(login({ loggedIn: false }));
  thunkApi.dispatch(initiateLogin(false));
  thunkApi.dispatch(initiateRelogin(false));
  Emitter.emit(TELEMETRY_ERROR, {
    type: "notif",
    chan: TELEMETRY_INFO,
    success: false,
    context: {
      agent: true,
    },
    body: {
      type: "session/loginFailure",
      chan: SESSION_ERROR,
      message: "Agent login failed.",
      errorMessage,
    },
  });

  // Ensure error is visible to the user.
  thunkApi.dispatch(resetSessionState());
});

export const logoutSession = createAsyncThunk<
  void,
  string,
  { dispatch: AppDispatch }
>("session/logoutSession", async (reason, thunkApi) => {
  const state = storeRTK.getState();
  if (state?.agent?.id) {
    try {
      const agentStateId = state.agent?.states?.allowedStates?.filter(
        (s: AgentState) => s?.displayName === "Offline"
      )[0].id;
      if (agentStateId) {
        Emitter.emit(API_AGENT_CHANGE, { agentState: "Offline" });
      }
    } catch (e) {
      console.error("Error in setting agent offline", e);
    }
  }
  Emitter.emit(TELEMETRY_ERROR, {
    type: "notif",
    chan: TELEMETRY_INFO,
    success: true,
    body: {
      type: "session/logoutSession",
      chan: STOP_SESSION,
      message: "Agent logout successful.",
      reason,
    },
    context: {
      agent: true,
      call: true,
    },
  });

  thunkApi.dispatch(setCCPState({ ccpSessionState: CCP_STATES.CLOSED }));
  // Stop ccp session and remove iframes
  setTimeout(() => Emitter.emit(STOP_CCP), 500);
  thunkApi.dispatch(initiateLogout({ isLogoutProcessing: true }));
  thunkApi.dispatch(setCurrentSessionTab({ id: "none" }));
  thunkApi.dispatch(resetState());
});

export const logoutFailure = createAsyncThunk<
  void,
  string,
  { dispatch: AppDispatch }
>("session/logoutFailure", (reason, thunkApi) => {
  thunkApi.dispatch(initiateLogout({ isLogoutProcessing: false }));
  Emitter.emit(TELEMETRY_ERROR, {
    type: "notif",
    chan: TELEMETRY_INFO,
    success: false,
    body: {
      type: "session/logoutFailure",
      chan: SESSION_ERROR,
      message: "Agent logout failed.",
      reason: reason,
      wasClean: true,
    },
    context: {
      agent: true,
      call: true,
    },
  });

  // Reset Store state
  thunkApi.dispatch(setCCPState({ ccpSessionState: CCP_STATES.CLOSED }));
  thunkApi.dispatch(setCurrentSessionTab({ id: "none" }));
  logoutChannel.postMessage("Logout");
  thunkApi.dispatch(resetState());
});
