import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Flex, View, Picker, ActionButton, Text, Item, Divider, Checkbox } from '@adobe/react-spectrum';
import './style.css';
import { ChevronLeft, PlayIcon } from '../common/Icons';
import { sendMessage } from '../../services';
import { API_TAB_CHANGE, API_UPDATE_RING_MEDIA_DEVICE, API_UPDATE_ROD_DEVICE, STATE_UPDATE_ROD_DEVICE, BG_BROADCAST, CALL, CHAT, CASE, API_TEST_RING_PLAY } from '../../constants/constants';
// import Emitter from '../../emitter';
import { updateRingOnDifferentDevice } from '../../actions/webrtc';
import store from '../../store';
/**
 * Map Store State to Componennt Props
 * @param {*} state
 */
function mapStateToProps(state:any) {
    return {
        webrtc: state.webrtc,
    };
}

const testRingTypes = [
    {name: CALL, id: 'CALL'},
    {name: CHAT, id: 'CHAT'},
    {name: CASE, id: 'CASE'}
];

const AccountSettings = (props:any) => {
    // let [deviceId, setAudioDevice] = React.useState(null);
    const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
    const [ringOnDiffDevice, setRingOnDiffDevice] = useState(props.webrtc.ringOnDifferentDevice);
    const [devices, setDevices] = useState([]);
    const [selectedTestPlayRing, setSelectedTestPlayRing] = useState('CALL');

    const {tabs, webrtc} = props;
    const {ringtoneOutoutDeviceId, mediaDevices, ringOnDifferentDevice} = webrtc;

    const backRedirect = () => {
        let [firstTab] = tabs;
        let {id} = firstTab;
        sendMessage({
            type: 'notif',
            body: {
                id,
            },
            chan: API_TAB_CHANGE
        });
    };
   
    const handleSelectDevice = (selectedDevice:string) => {
        sendMessage({
            body: { deviceId: selectedDevice},
            chan: API_UPDATE_RING_MEDIA_DEVICE,
            type: 'req',
        });
    };
    const handleRingChbox = (choice:any) => {
        store.dispatch(updateRingOnDifferentDevice(choice));
        setRingOnDiffDevice(choice);
        sendMessage({
            type: 'req',
            chan: API_UPDATE_ROD_DEVICE,
            body: {ringOnDifferentDevice: choice}
        });
        sendMessage({
            type: 'notif',
            body: {
                type: 'req',
                chan: STATE_UPDATE_ROD_DEVICE,
                body: {ringOnDifferentDevice: choice}
            },
            chan: BG_BROADCAST,
        });
    }
    // useEffect(() => {
    //     Emitter.on(STATE_UPDATE_ROD_DEVICE, (event) => {
    //         if(event.body) {
    //             setRingOnDiffDevice(!!event.body.ringOnDifferentDevice);
    //         }
    //     });
    //     return () => {
    //         Emitter.off(STATE_UPDATE_ROD_DEVICE, (event) => {
    //             if(event.body) {
    //                 setRingOnDiffDevice(!!event.body.ringOnDifferentDevice);
    //             }
    //         });
    //     }
    // }, [])

    useEffect(() => {
        const mappedDevices = mediaDevices?.map((device:any) => {
                return  {id: device.deviceId, name: device.label, displayName: device.label, type: device.kind}; 
        }).filter((device:any) => device.name && device.id && device.type === 'audiooutput' && !device?.name.toLowerCase().includes("virtual")) || [];
        setDevices(mappedDevices);
        if(!selectedDeviceId) {
            setSelectedDeviceId((mappedDevices.find((device:any) => device?.name.toLowerCase().includes("default")) || {id: ""}).id);
        }
    }, [mediaDevices]);
    useEffect(() => {
        setRingOnDiffDevice(ringOnDifferentDevice);
    }, [ringOnDifferentDevice])
    useEffect(() => {
        if(ringtoneOutoutDeviceId && devices.length > 0) {
            if (devices.findIndex((device) => device.id === ringtoneOutoutDeviceId) > -1) {
                setSelectedDeviceId(ringtoneOutoutDeviceId);
            } else {
                if(!selectedDeviceId) {
                    setSelectedDeviceId((devices.find((device) => device?.name.toLowerCase().includes("default")) || {id: ""}).id);
                }
            }
        } else {
            if(!selectedDeviceId) {
                setSelectedDeviceId((devices.find((device) => device?.name.toLowerCase().includes("default")) || {id: ""}).id);
            }
        }
    }, [ringtoneOutoutDeviceId, devices]);
    
    const handleSoundBeep = (selectedRing:any) => {
        setSelectedTestPlayRing(selectedRing)
    }
    
    const playCallHandler = () => {
        sendMessage({
            type: 'req',
            chan: API_TEST_RING_PLAY,
            body: {
                type: selectedTestPlayRing
            }
        });
    };

    return (
        <div className="mainContent">
            <Flex direction={'column'}>
                <Flex direction={'row'} alignItems="center" marginBottom={'15px'}>
                        <ActionButton onPress={() => backRedirect()} isQuiet UNSAFE_className='acs-left-chevron-btn'>
                            <ChevronLeft fill="#6E6E6E"/>
                        </ActionButton>
                    <View>
                        <h3 className='page-main-heading' style={{marginLeft: '0px'}}>Account Settings</h3>
                    </View>
                </Flex>
                <Divider size="S" />
                <Flex direction={'row'} alignItems="center" justifyContent='space-between' margin={'10px 0px 10px 0px'}>
                    <View>
                        <Text UNSAFE_className='acount-settings-heading'>Audio Devices</Text>
                    </View>
                </Flex>
                <Flex direction={'row'} alignItems="center">
                    <Text UNSAFE_className='aic-sub-label-text'>Play ring on different device</Text>
                    <Checkbox
                        onChange={(selected) => handleRingChbox(selected)}
                        value={!!ringOnDiffDevice}
                        isSelected={!!ringOnDiffDevice}
                        isEmphasized
                        marginStart={'15px'}
                    >
                    </Checkbox>
                </Flex>
                <Flex direction={'row'} alignItems="center" justifyContent='space-between'>
                    <Text UNSAFE_className='aic-sub-label-text' >Ring On</Text>
                    <Picker
                        items={devices}
                        onSelectionChange={(selected:any) => handleSelectDevice(selected)}
                        selectedKey={selectedDeviceId}
                        defaultSelectedKey={selectedDeviceId}
                        isQuiet
                        width={'50%'}
                        UNSAFE_className='aic-sub-label-text'
                        isDisabled={!ringOnDiffDevice}
                    >
                        {(item) => <Item key={item.id} UNSAFE_className='aic-sub-label-text'>{item.name}</Item>}
                    </Picker>
                </Flex>

                <Flex direction={'row'} alignItems="center" justifyContent='space-between'>
                    <Text UNSAFE_className='aic-sub-label-text' >Test Ring Sound</Text>
                    <div className='AIC_iconButtonDiv'>
                    <Picker
                        items={testRingTypes}
                        onSelectionChange={(key) => handleSoundBeep(key)}
                        defaultSelectedKey="CALL"
                        selectedKey={selectedTestPlayRing}
                        isQuiet
                        UNSAFE_className='aic-sub-label-text'
                    >
                        {item => <Item key={item.id} UNSAFE_className='aic-sub-label-text'>{item.name}</Item>}
                    </Picker>
                        <button
                            type="button"
                            onClick={playCallHandler}
                            className={'AIC_iconButton'}
                        >
                        <PlayIcon />
                        </button>  
                    </div>
                </Flex>
            </Flex>
        </div>
    );
};

// Send Data form store to Connected Components needed
export default connect(mapStateToProps)(AccountSettings);
