import { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Divider, Flex, View } from "@adobe/react-spectrum";
import {
  CALL_STATES,
  CHAT_STATES,
  STATE_WEBCHATMESSAGE,
  CALL,
  CHAT,
  NONE,
  ALLOWED_CONV_MEDIA_TYPES,
  CASE,
  CASE_STATES,
  CCP_STATES,
  SETTINGS,
  VOICE_NETWORK_DISCONNECT,
  HELPSUPPORT,

  SHOW_API_STATUS_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SHOW_CCP_CONNECTION_ERROR,
  HIDE_CCP_CONNECTION_ERROR,
  CCP_CONNECTION_ERROR_MSG

} from "../../constants/constants";
import "./style.css";
import WrapUpPopover from "../CallPage/CallWebcase/WrapUpPopover";
import { canMakeCall } from "../../utils";
import ChatPage from "../ChatPage";
import CasePage from "../CasePage";
import { Toast } from "../common/Toast";
import AccountSettings from "../AccountSettings";
import HelpSupport from "../HelpSupport";
import storeRTK from "../../store/storeRTK";
import { setCurrentSessionTab } from "../../actions/session";
import Emitter from "../../emitter";
import TabSwitch from "../common/Tabs/TabSwitch";
import SidebarCP from "../Sidebar";
import { callTabs, caseTabs } from "../common/Data/data";
import HeaderComponent from "../Login/HeaderComponent";
import CaseAiDrawer from "../CasePage/CaseAI/CaseAiDrawer";
import { isCallbackDashboardEnabled } from "../../config";
import { RootState } from "../../store/storeConfig";

const Dashboard = () => {
  const historyState = useSelector((state: RootState) => state.history, shallowEqual);
  const call = useSelector((state: RootState) => state.call, shallowEqual);
  const callback = useSelector((state: RootState) => state.callback, shallowEqual);
  const chat = useSelector((state: RootState) => state.chat, shallowEqual);
  const session = useSelector((state: RootState) => state.session, shallowEqual);
  const cases = useSelector((state: RootState) => state.cases, shallowEqual);
  const agent = useSelector((state: RootState) => state.agent, shallowEqual);
  const webrtc = useSelector((state: RootState) => state.webrtc, shallowEqual);

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [logDownloadMsg, setLogDownloadMsg] = useState("");
  const [showLogStatus, setShowLogStatus] = useState(false);
  const [showMessageLog, setShowMessageLog] = useState(false);
  const [newMessageAlert, setNewMessageAlert] = useState(0);

  const onTabChange = useCallback((id: any) => {
    storeRTK.dispatch(setCurrentSessionTab({ id }));
    setNewMessageAlert(0);
  }, []);

  useEffect(() => {
    if (
      call.id &&
      call.self?.state === CALL_STATES.RINGING &&
      session.currentTab !== CALL
    ) {
      onTabChange(CALL);
    }
  }, [call.id, call.self?.state, session.currentTab, onTabChange]);

  useEffect(() => {
    if (
      callback.id &&
      callback.self?.callback?.state === CALL_STATES.RINGING &&
      session.currentTab !== CALL
    ) {
      onTabChange(CALL);
    }
  }, [
    callback.id,
    callback.self?.callback?.state,
    session.currentTab,
    onTabChange,
  ]);

  useEffect(() => {
    if (
      !chat?.activeChatID &&
      chat?.chats?.length > 0 &&
      session.currentTab === NONE
    ) {
      onTabChange(CHAT);
    }
  }, [
    chat?.activeChatID,
    chat?.chats?.length,
    session.currentTab,
    onTabChange,
  ]);

  useEffect(() => {
    if (
      cases?.cases?.length > 0 &&
      session.currentTab === NONE &&
      !cases?.activeCaseID
    ) {
      onTabChange(CASE);
    }
  }, [
    cases?.cases?.length,
    cases?.activeCaseID,
    session.currentTab,
    onTabChange,
  ]);

  const tabValues = useMemo(() => {
    const tabs = [];

    if (
      agent?.allowedConversationMedia?.includes(ALLOWED_CONV_MEDIA_TYPES.VOICE)
    ) {
      let color =
        webrtc.isVoiceNetworkAvailable &&
        canMakeCall(agent.availableMedia) &&
        !session.error &&
        webrtc.ccpSessionState === CCP_STATES.CONNECTED
          ? call?.id || callback?.id
            ? "yellow"
            : ""
          : "red";
      tabs.push({ id: CALL, name: "Call", color });
    }

    if (
      agent?.allowedConversationMedia?.includes(ALLOWED_CONV_MEDIA_TYPES.CHAT)
    ) {
      let color =
        session.error || webrtc.ccpSessionState === CCP_STATES.CLOSED
          ? "red"
          : chat?.activeChatID
          ? "yellow"
          : "";
      tabs.push({ id: CHAT, name: "Chat", color });
    }

    if (
      agent?.allowedConversationMedia?.includes(ALLOWED_CONV_MEDIA_TYPES.CASE)
    ) {
      let color =
        session.error || webrtc.ccpSessionState === CCP_STATES.CLOSED
          ? "red"
          : cases?.activeCaseID
          ? "yellow"
          : "";
      tabs.push({ id: CASE, name: "Case", color });
    }

    return tabs;
  }, [agent, session, call, callback, chat, cases, webrtc]);

  const onMessageClose = () => {
    setShowMessage(false);
    setMessage("");
  };

  const onErrorMessageClose = () => {
    setShowMessageLog(false);
  };

  return (
    <View UNSAFE_className="mainWrapper">
      <HeaderComponent />
      <Flex direction="row" width="100%" UNSAFE_className="aic-body">
        <View width="13.3%">
          <SidebarCP />
        </View>
        <View width="88%" height="100%">
          {session.currentTab === CALL && (
            <TabSwitch
              tabs={callTabs.filter(
                (t) => t.active === true || t.active === "true"
              )}
              history={historyState.conversations}
              isActiveCall={call?.id}
            />
          )}
        </View>
      </Flex>

      {session.currentTab === CASE && <CasePage />}
      {session.currentTab === SETTINGS && <AccountSettings tabs={tabValues} />}
      {session.currentTab === HELPSUPPORT && <HelpSupport tabs={tabValues} />}
      <CaseAiDrawer />

      {session.currentTab === NONE && (
        <>
          {navigator.onLine &&
            !webrtc.isVoiceNetworkAvailable &&
            agent?.allowedConversationMedia?.includes(
              ALLOWED_CONV_MEDIA_TYPES.VOICE
            ) && (
              <div className="mediaAvailableMessage">
                <Toast message={VOICE_NETWORK_DISCONNECT}></Toast>
              </div>
            )}
          {showMessage && (
            <div className="mediaAvailableMessage">
              <Toast
                message={message}
                variant="positive"
                duration={5000}
                closable={true}
                onClose={onMessageClose}
              ></Toast>
            </div>
          )}
        </>
      )}

      {/* ------------- chat call case commenetd for testing ----------------*/}
      {agent.states?.allowedStates &&
        session.currentTab !== CALL &&
        session.currentTab !== CHAT &&
        session.currentTab !== CASE &&
        showMessageLog && (
          <div className="mediaAvailableMessage" key={"errorMessage"}>
            <Toast
              message={logDownloadMsg}
              variant={showLogStatus ? "positive" : "negative"}
              duration={5000}
              closable={true}
              onClose={onErrorMessageClose}
            ></Toast>
          </div>
        )}

      <WrapUpPopover />
    </View>
  );
};

export default Dashboard;
