import { handleAfterCallWork } from "./agent";
import { connectSession } from './session';
import {
    handleAcceptedVoice, handleConnectedVoice, handleEndedVoice, handleIncomingVoice,
    handleRefreshVoice, handleMissedVoice, handleDestroyVoice,
    updateCallAndSend,
    handleConnectingVoice} from "./call";
import { CONTACT_EVENT_STATE_MAP } from "../../constants/constants";
import {
    handleAcceptedTask, handleConnectedTask, handleConnectingTask, 
    handleEndedTask, handleIncomingTask, handleRefreshTask, updateTaskAndSend, handleMissedTask, handleDestroyTask,
    casePlayer
} from "./task";
import {
    handleAcceptedChat, handleConnectedChat, handleConnectingChat, handleDestroyChat, handleEndedChat,
    handleIncomingChat, handleMissedChat, handleRefreshChat, updateChatAndSend
} from './chat';
import { formatCaseAttributes } from "../../utils";
import { handleAcceptedVoicemail, handleConnectedVoicemail, handleConnectingVoicemail, handleDestroyVoicemail, handleEndedVoicemail, handleIncomingVoicemail, handleMissedVoicemail, handleRefreshVoicemail, updateVoicemailAndSend } from "./voicemail";

function handleContactError(error: any) {
    console.log("Error!!!!!!!!: contact error", error);
}

function registerCallHandlers(contact: any) {
    contact.onIncoming(handleIncomingVoice);
    contact.onConnecting(handleConnectingVoice);
    contact.onAccepted(handleAcceptedVoice);
    contact.onConnected(handleConnectedVoice);
    contact.onEnded(handleEndedVoice);
    contact.onACW(handleAfterCallWork);
    contact.onRefresh(handleRefreshVoice);
    contact.onMissed(handleMissedVoice);
    contact.onDestroy(handleDestroyVoice);
    contact.onError(handleContactError);
}

function registerChatHandlers(contact: any) {
    contact.onIncoming(handleIncomingChat);
    contact.onConnecting(handleConnectingChat);
    contact.onAccepted(handleAcceptedChat);
    contact.onConnected(handleConnectedChat);
    contact.onEnded(handleEndedChat);
    // contact.onACW(handleAfterCallWork);
    contact.onRefresh(handleRefreshChat);
    contact.onMissed(handleMissedChat);
    contact.onDestroy(handleDestroyChat);
    contact.onError(handleContactError);
}

function registerTaskHandlers(contact: any) {
    const taskAttributes = formatCaseAttributes(contact.getAttributes());
    if(taskAttributes.TASK_TYPE === 'VOICEMAIL') {
        registerVoicemailHandlers(contact);
    } else {
        casePlayer.pause();
        casePlayer.play();
        updateTaskAndSend(CONTACT_EVENT_STATE_MAP.INITIAL, contact);
        contact.onIncoming(handleIncomingTask);
        contact.onConnecting(handleConnectingTask);
        contact.onAccepted(handleAcceptedTask);
        contact.onConnected(handleConnectedTask);
        contact.onEnded(handleEndedTask);
        // contact.onACW(handleAfterCallWork);
        contact.onRefresh(handleRefreshTask);
        contact.onMissed(handleMissedTask);
        contact.onDestroy(handleDestroyTask);
        contact.onError(handleContactError);
    }
}

function registerVoicemailHandlers(contact: any) {
    updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.INITIAL, contact);
    contact.onIncoming(handleIncomingVoicemail);
    contact.onConnecting(handleConnectingVoicemail);
    contact.onAccepted(handleAcceptedVoicemail);
    contact.onConnected(handleConnectedVoicemail);
    contact.onEnded(handleEndedVoicemail);
    contact.onRefresh(handleRefreshVoicemail);
    contact.onMissed(handleMissedVoicemail);
    contact.onDestroy(handleDestroyVoicemail);
    contact.onError(handleContactError);
}

/**
 * 
 * @param contact function to send initial state of contact to AIC Server
 */
function sendInitialContactAPI(contact: any) {
    const prefix = `sendInitialContactAPI: contact id =  ${contact.contactId || contact.getContactId()}`;
    // sendMessage({
    //     type: 'notif',
    //     chan: STATE_INITIAL_CONTACT,
    //     body: {contactId: contact.contactId || contact.getContactId()}
    // });
    console.log(prefix + 'Initial Contact API sent');
}
/**
 * 
 * @param contact function to subscribe to contact events
 */
export function subscribeToContactEvents(contact: any) {
    // Subscribing to Streams API Long Polling Contact events
    sendInitialContactAPI(contact);
    
    switch(contact.getType()) {
        case connect.ContactType.VOICE:
            //This is for when call was there before login or during initialization
            if (contact.getActiveInitialConnection()
                && contact.getActiveInitialConnection().getEndpoint()) {
                console.log('New contact is from: ' + contact.getActiveInitialConnection().getEndpoint().phoneNumber, contact.getActiveInitialConnection());
                updateCallAndSend(CONTACT_EVENT_STATE_MAP.INITIAL, contact);
            }
            registerCallHandlers(contact);
            break;
        case connect.ContactType.CHAT:
            try {
                if (contact.getActiveInitialConnection()
                    && contact.getActiveInitialConnection().getEndpoint()) {
                    updateChatAndSend(CONTACT_EVENT_STATE_MAP.INITIAL, contact);
                };
            } catch(error) {
                console.log("Error in chat type contact", error);
            }
            registerChatHandlers(contact);
            break;
        case connect.ContactType.TASK:
            if(contact.getActiveInitialConnection() && contact.getActiveInitialConnection().getEndpoint()) {
                registerTaskHandlers(contact);
            }
            break;
        default: 
            console.log(" subscribeToContactEvents: Inside default!!");
            break;
    }
}