import { useEffect, useState } from "react";
import {
  Heading,
  View,
  ActionButton,
  Divider,
  Flex,
  Text,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import "./index.css";
import SuggestIssueAiComponent from "./caseAiEditor";
import SummarizeIusseAiComponent from "./caseAiEditor";
import storeRTK from "../../../store/storeRTK";
import {
  getCaseRerouteData,
  suggestIssueReason,
  summarizeIssueReason,
} from "../../../api/session-api";
import {
  getRerouteCaseData,
  setCaseSummaryIssue,
  setSuggestCaseIssue,
} from "../../../actions/case";
import { IndividualTask } from "../../../amazon-connect/models/task";
import {
  setHistoryCaseSuggestIssue,
  setHistoryCaseSummaryIssue,
} from "../../../actions/history";
import { QuestionMarkIcon } from "../../common/Icons";

interface ICaseAiProps {
  caseID: string;
  isDisabled?: boolean;
}
const CaseAi = (props: ICaseAiProps) => {
  const { caseID, isDisabled } = props;
  const cases = useSelector((state: any) => state.cases);
  const history = useSelector((state: any) => state.history);
  const common = useSelector((state: any) => state.common);

  const [SuggestReasonSearchText, setSuggestReasonSearchText] = useState("");
  const [SummarizeReasonSearchText, setSummarizeReasonSearchText] =
    useState("");
  const [activeAiBox, setActiveAiBox] = useState("");
  const [isCaseChatLoading, setIsCaseChatLoading] = useState(false);

  // Checking active case id
  const currentActiveCaseId =
    cases.activeCaseID?.caseID || caseID || cases?.activeCaseID;

  // If tab 1 selected then live case data shown else if tab 2 history is selected case history data shown
  const currentCase = common.tab === 1 ? cases.cases : history.cases;

  // Get active case data
  const activeCase = currentCase.find(
    (indCase: IndividualTask) => indCase.id === currentActiveCaseId
  );

  useEffect(() => {
    if (currentActiveCaseId) {
      getCaseRerouteData(currentActiveCaseId).then((res) => {
        storeRTK.dispatch(getRerouteCaseData(currentActiveCaseId, res));
      });
    }
  }, []);

  // handle suggest issue reason function used to call suggestReason api and update the store
  function handleSuggestIssueReason(issueType: string) {
    setActiveAiBox("suggest");
    if (activeCase?.suggestCaseMessages?.length >= 1 && issueType === "new")
      return;
    setIsCaseChatLoading(true);
    suggestIssueReason(currentActiveCaseId)
      .then((res) => {
        setIsCaseChatLoading(false);
        const { status, answer } = res;
        if (status === "success") {
          let uniqueId = Math.random().toString(36).substring(7);
          storeRTK.dispatch(
            setSuggestCaseIssue(
              currentActiveCaseId,
              "",
              answer,
              true,
              false,
              false,
              false,
              "",
              uniqueId
            )
          );
          storeRTK.dispatch(
            setHistoryCaseSuggestIssue(currentActiveCaseId, "", answer)
          );
        }
      })
      .catch((error) => {
        console.log("Error while fetching api ai suggest issue reason", error);
      });
  }

  // handle chat summary function used to call summarizeIssue api and update the store
  const handleChatSummary = () => {
    setActiveAiBox("summary");
    if (activeCase && activeCase["summaryCaseMessages"]) return;
    setIsCaseChatLoading(true);
    summarizeIssueReason(currentActiveCaseId)
      .then((res) => {
        setIsCaseChatLoading(false);
        const { status, summary } = res;
        if (status === "success") {
          storeRTK.dispatch(
            setCaseSummaryIssue(currentActiveCaseId, "", summary)
          );
          storeRTK.dispatch(
            setHistoryCaseSummaryIssue(currentActiveCaseId, "", summary)
          );
        }
      })
      .catch((error) => {
        console.log("Error while fetching api ai summary issue reason", error);
      });
  };

  return (
    <Flex UNSAFE_className="case-ai" direction={"column"} height={"100%"}>
      <Heading level={3} margin={"0"} UNSAFE_className="case-ai-title">
        <QuestionMarkIcon color="#fff" />
        <Text marginStart={15}>AI Assistant</Text>
      </Heading>
      <View padding={8}>
        <View marginTop={10}>
          <ActionButton
            UNSAFE_className={` case-ai-button ${
              activeAiBox === "summary" ? "case-ai-active-box" : ""
            }`}
            onPress={handleChatSummary}
            isDisabled={isDisabled}
          >
            Generate summary of this case
          </ActionButton>
        </View>
        <View>
          <ActionButton
            UNSAFE_className={`case-ai-button ${
              activeAiBox === "suggest" ? "case-ai-active-box" : ""
            }`}
            onPress={() => handleSuggestIssueReason("new")}
            isDisabled={isDisabled}
            marginTop={6}
          >
            Suggest me a better Issue Reason
          </ActionButton>
        </View>
      </View>
      <Divider size="S" marginTop={5} />
      {activeAiBox === "suggest" ? (
        <View flexGrow={1}>
          <SuggestIssueAiComponent
            type="suggest"
            title="Suggest Better Issue Reason"
            value={SuggestReasonSearchText}
            setValue={setSuggestReasonSearchText}
            handleChange={setSuggestReasonSearchText}
            loader={isCaseChatLoading}
            currentActiveCaseId={currentActiveCaseId}
            handleSuggestIssueReason={handleSuggestIssueReason}
          />
        </View>
      ) : (
        <View flexGrow={1}>
          <SummarizeIusseAiComponent
            type="summary"
            title="Generate summary of this case"
            value={SummarizeReasonSearchText}
            setValue={setSummarizeReasonSearchText}
            handleChange={setSummarizeReasonSearchText}
            loader={isCaseChatLoading}
            currentActiveCaseId={currentActiveCaseId}
          />
        </View>
      )}
    </Flex>
  );
};

export default CaseAi;
