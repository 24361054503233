import {
  REACT_APP_AIC_USER_GUIDE_URL,
  REACT_APP_CARE_PORTAL_LOGIN_URL,
  REACT_APP_IAM_URL,
} from "../../constants/constants";
import "./Footer.css";

/**
 * Compnent Footer which will be loaded onlt on eahc page.
 */
export default function FooterComponent() {
  //   const year = new Date().getFullYear();
  return (
    <footer style={{ height: "7.78%", borderTop: "1px solid #B1B1B1" }}>
      <div className={"footerWrapper"}>
        <span>
          <a
            href={
              process.env.REACT_APP_CARE_PORTAL_LOGIN_URL ||
              REACT_APP_CARE_PORTAL_LOGIN_URL
            }
            target="_blank"
            rel="noreferrer"
          >
            Care portal login
          </a>
        </span>
        <span>
          <a
            href={process.env.REACT_APP_IAM_URL || REACT_APP_IAM_URL}
            target="_blank"
            rel="noreferrer"
          >
            iam.corp.adobe.com
          </a>
        </span>
        <span>
          <a
            href={
              process.env.REACT_APP_AIC_USER_GUIDE_URL ||
              REACT_APP_AIC_USER_GUIDE_URL
            }
            target="_blank"
            rel="noreferrer"
          >
            Help and support
          </a>
        </span>
        {/* <span>
                {APP_VERSION ? `v${APP_VERSION}` : ''}
            </span>
            <span data-testid="footerMain">
                Copyright © {year} Adobe Systems
            </span> */}
      </div>
    </footer>
  );
}
