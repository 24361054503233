export const getCCPUrl = (): string => {
  let url: string | undefined;
  if (process.env.REACT_APP_ENV === "dev") {
    url = process.env.REACT_APP_AIC_CCP_CLIENT_URL_DEV;
  } else if (process.env.REACT_APP_ENV === "qe") {
    url = process.env.REACT_APP_AIC_CCP_CLIENT_URL_QE;
  } else if (process.env.REACT_APP_ENV === "stage") {
    url = process.env.REACT_APP_AIC_CCP_CLIENT_URL_STAGE;
  } else if (process.env.REACT_APP_ENV === "prod") {
    url = process.env.REACT_APP_AIC_CCP_CLIENT_URL_PROD;
  } else if (process.env.REACT_APP_ENV === "sg-dev") {
    url = process.env.REACT_APP_AIC_CCP_CLIENT_URL_DEV_SG;
  } else {
    // Default to dev extension URL
    url = process.env.REACT_APP_AIC_CCP_CLIENT_URL_DEV;
  }

  // If url is undefined, return an empty string
  return url || "";
};

export const getAwsRegion = (): string => {
  let region: string | undefined;
  if (process.env.REACT_APP_ENV === "dev") {
    region = process.env.REACT_APP_AWS_REGION_DEV;
  } else if (process.env.REACT_APP_ENV === "qe") {
    region = process.env.REACT_APP_AWS_REGION_QE;
  } else if (process.env.REACT_APP_ENV === "stage") {
    region = process.env.REACT_APP_AWS_REGION_STAGE;
  } else if (process.env.REACT_APP_ENV === "prod") {
    region = process.env.REACT_APP_AWS_REGION_PROD;
  } else if (process.env.REACT_APP_ENV === "sg-dev") {
    region = process.env.REACT_APP_AWS_REGION_DEV_SG;
  } else {
    // Default to dev region
    region = process.env.REACT_APP_AWS_REGION_DEV;
  }

  // If region is undefined, return an empty string
  return region || "";
};

export const getAicServerUrl = () => {
  if (process.env.REACT_APP_ENV === "dev") {
    return process.env.REACT_APP_AIC_SERVER_URL_DEV;
  } else if (process.env.REACT_APP_ENV === "qe") {
    return process.env.REACT_APP_AIC_SERVER_URL_QE;
  } else if (process.env.REACT_APP_ENV === "stage") {
    return process.env.REACT_APP_AIC_SERVER_URL_STAGE;
  } else if (process.env.REACT_APP_ENV === "prod") {
    return process.env.REACT_APP_AIC_SERVER_URL_PROD;
  } else {
    // Default to dev Url
    return process.env.REACT_APP_AIC_SERVER_URL_DEV;
  }
};
export const getLogEndpoint = () => {
  if (process.env.REACT_APP_ENV === "dev") {
    return process.env.REACT_APP_AIC_LOG_ENDPOINT_URL_DEV;
  } else if (process.env.REACT_APP_ENV === "qe") {
    return process.env.REACT_APP_AIC_LOG_ENDPOINT_URL_QE;
  } else if (process.env.REACT_APP_ENV === "stage") {
    return process.env.REACT_APP_AIC_LOG_ENDPOINT_URL_STAGE;
  } else if (process.env.REACT_APP_ENV === "prod") {
    return process.env.REACT_APP_AIC_LOG_ENDPOINT_URL_PROD;
  } else {
    // Default to dev Url
    return process.env.REACT_APP_AIC_LOG_ENDPOINT_URL_DEV;
  }
}

export const isCallbackDashboardEnabled = () => {
  if (process.env.REACT_APP_ENV === "dev") {
    return process.env.REACT_APP_CALLBACK_ENABLE_DASHBOARD_DEV;
  } else if (process.env.REACT_APP_ENV === "qe") {
    return process.env.REACT_APP_CALLBACK_ENABLE_DASHBOARD_QE;
  } else if (process.env.REACT_APP_ENV === "stage") {
    return process.env.REACT_APP_CALLBACK_ENABLE_DASHBOARD_STAGE;
  } else if (process.env.REACT_APP_ENV === "prod") {
    return process.env.REACT_APP_CALLBACK_ENABLE_DASHBOARD_PROD;
  } else {
    // Default to dev Url
    return process.env.REACT_APP_CALLBACK_ENABLE_DASHBOARD_DEV;
  }
}

export const getCurrentConnectInstance = () => {
  return `/${encodeURIComponent(process.env.REACT_APP_US_ALIAS || 'dxoc-dev')}`;
}
