import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "../reducers";
import { storeLogin } from "../actions/common";
import { CCP_STATES, session } from "../controller/session";
import { START_SESSION } from "../constants/events";
import storeRTK from "./storeRTK";
import Emitter from "../emitter";
import { initiateRelogin } from "../actions/session";
import { updateCcpState } from "../controller/webrtc";

// Enhance rootReducer to handle updating the persisted state
const enhancedRootReducer = (state: RootState) => {
  // Update the state with the loaded persisted state
  storeRTK.dispatch(storeLogin(state));

  // If service worker restarts, start ccp start interval which also checks for vpn connectivity
  if (state.session.loggedIn) {
    console.log("!!!!!!!!!!! Page Refreshed Detected !!!!!!!!!!!!!!");
    // If the user is already logged in, on refresh resetting it to close because new connection will initiate from onLoggedIn
    updateCcpState({state: CCP_STATES.CLOSED});
    session.onloggedIn({
      body: state.agent || {},
      success: true,
    });
  } else if (state.session.isLoginProcessing) {
    Emitter.emit(START_SESSION);
  } 
  if(state.session.isReloginProcessing) {
    storeRTK.dispatch(initiateRelogin(false));
  }
};

// Save state to LocalStorage
export const saveStateToLocalStorage = (state: any) => {
  try {
    localStorage.setItem("reduxState", JSON.stringify(state));

    //localStorage.setItem('reduxState', serializedState);
  } catch (error) {
    console.error("Error saving state to LocalStorage:", error);
  }
};

// Load state from LocalStorage
export const loadStateFromLocalStorage = async () => {
  try {
    const data = localStorage.getItem("reduxState");
    if (data !== null) {
      const serializedState = await JSON.parse(data || "");
      return serializedState;
    }
  } catch (error) {
    console.error("Error loading state from LocalStorage:", error);
    return undefined;
  }
};

// Load persisted state from LocalStorage

loadStateFromLocalStorage().then((serializedState) => {
  if (serializedState !== undefined) {
    // Dispatch an action to update the store with the persisted state
    enhancedRootReducer(serializedState);
  }
});
// Configure Redux store with persisted state
export const store = configureStore({
  reducer: rootReducer,
  preloadedState: {},
});

// Export store
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
