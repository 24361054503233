import { ActionButton, Button, Tooltip, Flex, TooltipTrigger, View, Heading,Text } from "@adobe/react-spectrum"
import { TimerIcon } from "../common/Icons";
import { RefreshIconRotate } from "../common/Icons";
import { useEffect,useState,useRef } from "react";
const resetCountdown = 30;

const Timer = ({ getScheduledCallbackList, isRotating }: { getScheduledCallbackList: () => void, isRotating: boolean }) => {
    const [countdown, setCountdown] = useState<number>(resetCountdown);
    const countdownRef = useRef<NodeJS.Timeout | null>(null);

    const handleRefresh = () => {
        setCountdown(resetCountdown);
        getScheduledCallbackList();
    };

    useEffect(() => {
        if (countdown > 0) {
            countdownRef.current = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        } else {
            getScheduledCallbackList();
            setCountdown(resetCountdown);
        }
        return () => {
            if (countdownRef.current) {
                clearTimeout(countdownRef.current);
            }
        };
    }, [countdown]);
    
    return (
        <>
            <Heading level={2}>Callbacks</Heading>
            <Flex gap={"size-10"} alignItems={"center"}>
                <View>
                    <TimerIcon />
                </View>
                <View UNSAFE_style={{ width: "95px", paddingLeft: "5px" }}>
                    <Text>Refresh in {countdown}s</Text>
                </View>
                <View >
                    <TooltipTrigger delay={0}>
                        <ActionButton
                            UNSAFE_className="supervisor-refresh-button"
                            aria-label="Refresh"
                            onPress={handleRefresh}
                            UNSAFE_style={{ cursor: "pointer" }}
                        >
                            <RefreshIconRotate color="#0265DC" className={isRotating ? "rotate" : ""} />
                        </ActionButton>
                        <Tooltip>Refresh now</Tooltip>
                    </TooltipTrigger>
                </View>
            </Flex>
        </>
    )
}

export default Timer;
