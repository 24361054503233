import {
    CONTACT_EVENT_STATE_MAP,
    LOG_PREFIX,
} from '../../constants/constants';
import { connectSession } from '../handlers/session';
import { updateVoicemailAndSend } from '../handlers/voicemail';
import { initOutBoundCall } from './call';
import { changeAgentStateToAvailable } from '../handlers/agent';

const lp = LOG_PREFIX + "[api.voicemail.ts] ";

function getAgentContactById(id: string) {
    
    const contacts = connectSession.agent.getContacts(connect.ContactType.TASK);
        if(contacts.length > 0) {
            return contacts.find((ct:any) => ct.contactId === id);
        }
    return null;
}

export function acceptTaskContact(ev: any) {
    const logPrefix = lp + "acceptTaskContact " + ev.body.conversationId;
    console.log(logPrefix + "Accept Voicemail contact", getAgentContactById(ev.body.conversationId));
    try {
        const taskContact = getAgentContactById(ev.body.conversationId);
        if(taskContact) {
            taskContact.accept({
                success: function () {
                    console.log(logPrefix + 'Voicemail Accepted via Streams, voicemailContact = ', taskContact);
                },
                failure: function (error: any) {
                    console.log(logPrefix + "Failed to establish connection via Streams" , error);
                    updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.ENDED, ev.body.conversationId);
                    disconnectVoicemailContact(ev);
                }
            });
        } else {
            clearCurrentVoicemailContact(ev?.body?.conversationId);
            updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.ENDED, ev.body.conversationId);
            console.log(logPrefix + "No voicemail contact found with contactId = " + ev.body.conversationId);
        }
    } catch (e) {
        clearCurrentVoicemailContact(ev?.body?.conversationId);
        updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.ENDED, ev.body.conversationId);
        console.log(logPrefix + "No voicemail contact found with contactId = " + ev.body.conversationId, e);
    }
}

export function disconnectVoicemailContact(ev: any) {
    const logPrefix = lp + "disconnectVoicemailContact " + ev.body.conversationId;
    try {
        const taskContact = getAgentContactById(ev.body.conversationId);
        if(taskContact) {
            const taskAgentConn = taskContact.getAgentConnection();
            if(taskAgentConn) {
                taskAgentConn.destroy({
                    success: function () {
                        console.log(logPrefix + 'Rejected Incoming call via Streams successfully, taskContact = ', taskContact);
                        taskContact.clear({
                            success: () => {},
                            failure: (error: any) => {
                                clearCurrentVoicemailContact(ev?.body?.conversationId);
                                updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {contactId: ev.body.conversationId});
                                console.log(logPrefix + "Failed to destroy voicemail contact", error);
                            }
                        });
                    },
                    failure: function (err: any) {
                        updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {contactId: ev.body.conversationId});
                        console.log(logPrefix + 'Failed to Disconnect Voicemail via Streams', err);
                    }
                });
            } else {
                console.log(logPrefix + "Error while clearing Task: taskAgentConn not found");
                clearCurrentVoicemailContact(ev?.body?.conversationId);
                updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {contactId: ev.body.conversationId});
            }
        } else {
            console.log(logPrefix + "Error while clearing Task: taskContact not found");
            clearCurrentVoicemailContact(ev?.body?.conversationId);
            updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {contactId: ev.body.conversationId});
        }
    } catch(error) {
        console.log(logPrefix + "Error while clearing Task", error);
        clearCurrentVoicemailContact(ev?.body?.conversationId);
        updateVoicemailAndSend(CONTACT_EVENT_STATE_MAP.ENDED, {contactId: ev.body.conversationId});
    }
}

export function clearCurrentVoicemailContact(contactId: string) {
    const contact = getAgentContactById(contactId);
    if(contact) {
        // TODO handle ended task, because clear api might fail for ended task
        try {
            contact.clear();
        } catch(e) {
        }
    } else {
        //console.log("Error cannot clear contact, no contact exists");
    }
}

export function rejectVoicemailContact(ev: any) {
    changeAgentStateToAvailable();
    disconnectVoicemailContact(ev);
}

export function updateVoicemailContact(ev: any) {
    console.log("updateTaskContact function called", ev);
    if(ev.body.state === 'connected') {
        acceptTaskContact(ev);
    } else if(ev.body.state === 'disconnected') {
        disconnectVoicemailContact(ev);
    }
}
export function dialCallback(ev: any) {
    initOutBoundCall(ev);
}
