import { useEffect, useState } from "react";
import {
  View,
  Text,
  Flex,
  ActionButton,
  TooltipTrigger,
  Tooltip,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import {
  CallHangupIcon,
  ForwardPhoneCallIcon,
  MuteIcon,
  PauseIcon,
  PlayIcon,
  UnMmuteIcon,
} from "../../common/Icons";
import Emitter from "../../../emitter";
import {
  API_CALL_HANGUP,
  API_CALL_HOLD,
  API_CALL_MUTE,
  API_CALL_PLAY,
} from "../../../constants/constants";
import { RootState } from "../../../store/storeConfig";
import CallTransferDialog from "../CallTransferDialog";
import CallDialPad from "../CallDialer/CallDialpad";
import { IntervalType } from "../../../types/generic.types";
import IncomingCallIcon from "../../../assets/callIncomingIcon.svg";
import OutgoingCallIcon from "../../../assets/CallOutgoingIcon.svg";
import EyeIcon from "../../../assets/eye-icon.svg";

export const LiveCall = () => {
  const [callTime, setCallTime] = useState(0);
  let call = useSelector((state: RootState) => state.call);
  const [transferDialogState, setTransferDialogState] = useState(false);
  const [hold, setHold] = useState(true);

  useEffect(() => {
    let timerId: IntervalType = null;
    if (call?.self?.state === "ESTABLISHED") {
      const startTime = call?.self.connectedTime;
      timerId = setInterval(() => {
        setCallTime(Date.now() - startTime);
      }, 1000);
    } else {
      if (timerId !== null) {
        clearInterval(timerId);
      }
    }
    return () => {
      if (timerId !== null) {
        clearInterval(timerId);
      }
    };
  }, [call?.self?.state]);

  const formattedTime = () => {
    const hours = Math.floor(callTime / 3600000)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((callTime % 3600000) / 60000)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor((callTime % 60000) / 1000)
      .toString()
      .padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const hangupCallHandler = () => {
    Emitter.emit(API_CALL_HANGUP, { body: { conversationId: call.id } });
  };

  const muteCallHandler = () => {
    if (call.isSilentMonitor) return;
    Emitter.emit(API_CALL_MUTE, {
      body: { mute: !call?.self?.muted, conversationId: call.id },
    });
  };

  const pausePlayHandler = (type: string) => {
    if (call.isSilentMonitor) return;
    setHold(!hold);
    Emitter.emit(type === "play" ? API_CALL_PLAY : API_CALL_HOLD, {
      body: { held: hold },
    });
  };

  const handleTransferCall = () => {
    if (call.isSilentMonitor) return;
    setTransferDialogState(true);
  };

  const disableTransfer = () => {
    if (call?.isSilentMonitor || call?.allowedTransferEndpoints?.length === 0)
      return true;
    return false;
  };

  return (
    <View
      borderColor="gray-200"
      borderWidth="thin"
      backgroundColor="gray-100"
      borderRadius={"regular"}
      UNSAFE_className="call-inProgress-container"
    >
      <View
        paddingStart="12px"
        borderStartWidth="thicker"
        borderStartColor="static-green-500"
        borderRadius="regular"
        UNSAFE_className="call-inProgress-container"
      >
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="100%"
        >
          <Flex direction="column">
            <Text UNSAFE_className="call-inProgress-heading">
              {call?.customer?.attributes?.Customer_ANI ||
                call?.customer?.displayName ||
                call?.customer?.phoneNumber ||
                "-"}
            </Text>
            <Text UNSAFE_className="call-inProgress-font">
              <Flex
                direction="row"
                alignItems="center"
                justifyContent={"center"}
              >
                <Text marginX={3}>
                  {call?.self?.state !== "ESTABLISHED" ? "Dialing" : "on call"}{" "}
                </Text>
                <Text UNSAFE_className="call-inprogress-timer" marginX={2}>
                  {formattedTime()}
                </Text>
                <Text>
                  {call?.self?.direction === "OUTBOUND" &&
                    !call.isSilentMonitor && (
                      <>
                        <img
                          className="call-inProgress-icon"
                          src={OutgoingCallIcon}
                          alt="OutgoingCallIcon"
                        />
                      </>
                    )}
                  {call?.self?.direction === "INBOUND" &&
                    !call.isSilentMonitor && (
                      <img
                        className="call-inProgress-icon"
                        src={IncomingCallIcon}
                        alt="IncomingCallIcon"
                      />
                    )}
                  {call.isSilentMonitor && (
                    <img
                      className="call-inProgress-icon"
                      src={EyeIcon}
                      alt="eyeIcon"
                    />
                  )}
                </Text>
              </Flex>
            </Text>
          </Flex>
          <Flex direction={"row"}>
            <Flex
              direction={"column"}
              alignItems="center"
              UNSAFE_className="call-action-items"
            >
              <TooltipTrigger delay={0}>
                <ActionButton
                  UNSAFE_className={"iconButton"}
                  onPress={handleTransferCall}
                  isQuiet
                  UNSAFE_style={{
                    cursor: disableTransfer() ? "not-allowed" : "",backgroundColor: "#eaeaea",
                  }}
                  isDisabled={disableTransfer()}
                >
                  <ForwardPhoneCallIcon />
                </ActionButton>
                {call.isSilentMonitor && (
                  <Tooltip>Transfer call not allowed while monitoring</Tooltip>
                )}
              </TooltipTrigger>
              <Text UNSAFE_className="call-inProgress-font">Transfer</Text>
            </Flex>
            <Flex
              direction={"column"}
              alignItems="center"
              UNSAFE_className={`call-action-items ${
                call?.self?.muted ? "muted" : ""
              }`}
            >
              <TooltipTrigger delay={0}>
                <ActionButton
                  UNSAFE_className={
                    call?.self?.muted ? "iconButton active " : "iconButton"
                  }
                  UNSAFE_style={{
                    cursor: call.isSilentMonitor ? "not-allowed" : "",
                  }}
                  onPress={muteCallHandler}
                  isQuiet
                >
                  {call?.self?.muted ? <MuteIcon /> : <UnMmuteIcon />}
                </ActionButton>
                {call.isSilentMonitor && (
                  <Tooltip>Mute not allowed while monitoring</Tooltip>
                )}
              </TooltipTrigger>

              <Text UNSAFE_className="call-inProgress-font">
                {call?.self?.muted ? "Unmute" : "Mute"}
              </Text>
            </Flex>
            <Flex
              direction={"column"}
              alignItems="center"
              UNSAFE_className="call-action-items"
            >
              <TooltipTrigger delay={0}>
                <ActionButton
                  UNSAFE_className={"iconButton"}
                  onPress={
                    hold
                      ? () => pausePlayHandler("play")
                      : () => pausePlayHandler("pause")
                  }
                  UNSAFE_style={{
                    cursor: call.isSilentMonitor ? "not-allowed" : "",
                  }}
                  isQuiet
                >
                  {hold ? <PauseIcon /> : <PlayIcon />}
                </ActionButton>
                {call.isSilentMonitor && (
                  <Tooltip>Hold not allowed while monitoring</Tooltip>
                )}
              </TooltipTrigger>
              <Text UNSAFE_className="call-inProgress-font">
                {hold ? "Hold" : "Resume"}
              </Text>
            </Flex>

            <Flex
              direction={"column"}
              alignItems="center"
              UNSAFE_className="call-action-items"
            >
              <button
                type="button"
                className={"call-end-Button"}
                onClick={hangupCallHandler}
              >
                <CallHangupIcon />
              </button>
              <Text UNSAFE_className="call-inProgress-font">End</Text>
            </Flex>
          </Flex>
        </Flex>
      </View>
      {transferDialogState && (
        <CallTransferDialog
          isOpen={transferDialogState}
          onClose={() => setTransferDialogState(false)}
        />
      )}
    </View>
  );
};

const CallInProgress = () => {
  return (
    <>
      <Flex
        height={"100%"}
        width={"100%"}
        direction={"column"}
        justifyContent={"space-between"}
      >
        <View paddingX={"1rem"} paddingTop={"1rem"}>
          <LiveCall />
        </View>
        <View>
          {/* Will uncommnet when this feature required */}
          {/* <View>
            <View paddingX={20}>
              <Text UNSAFE_className="call-combobox-heading">Consult</Text>
            </View>
            <View paddingX={20}>
              <ComboBox
                UNSAFE_className="call-inProgress-combobox"
                width={"100%"}
                label="Select speed dials"
                defaultInputValue="Manoj Kumar verma (Agent)"
                isDisabled
              >
                <Item key="agent-name">Manoj Kumar verma (Agent)</Item>
              </ComboBox>
            </View>
            <Flex width={"100%"} alignItems={"center"}>
              <View
                borderBottomWidth="thin"
                borderBottomColor="gray-200"
                width={"100%"}
              ></View>
              <View UNSAFE_className="dialer-text">Or dial manually</View>
              <View
                borderBottomWidth="thin"
                borderBottomColor="gray-200"
                width={"100%"}
              ></View>
            </Flex>
          </View> */}
          <Flex
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            UNSAFE_className="dialer-container"
          >
            <View paddingTop={"1rem"} paddingBottom={"1rem"}>
              <CallDialPad />
            </View>
          </Flex>
        </View>
      </Flex>
    </>
  );
};

export default CallInProgress;
