import {
  AGENT_SET,
  AGENT_SET_CURRENT_STATE,
  AGENT_SET_QUEUES,
  AGENT_SET_STATES,
  AGENT_SET_ACW_STATE,
  RESET_AGENT_SET_ACW_STATE,
  AGENT_SET_CANNEDRESPONSES,
  UPDATE_ASSINGED_QUEUE,
  AGENT_UPDATE_STATE_FROM_AWS,
  AGENT_UPDATE_GEO_LOCATION,
  AGENT_UPDATE_COUNTRY,
} from "../constants/action-types";
import { CannedResponses } from "../types/agent.type";
/**
 *
 * @param {*} param0
 */
export function setAgentNRRStates({ allowedStates, allowedStatesIndex }: any) {
  return {
    type: AGENT_SET_STATES,
    allowedStates,
    allowedStatesIndex,
  };
}
/**
 *
 * @param {*} param0
 */
export function setAgentTRPQueues({
  allowedQueues,
  conferenceQueues,
  sivrQueues,
}: any) {
  return {
    type: AGENT_SET_QUEUES,
    queues: allowedQueues,
    conferenceQueues,
    sivrQueues,
  };
}

/**
 *
 * @param {*} param0
 */
export function setAgentState(status: string, stateModifiedDate: number) {
  return {
    type: AGENT_SET_CURRENT_STATE,
    status,
    stateModifiedDate,
  };
}

export function setAgentCurrState(status: any) {
  return {
    type: AGENT_UPDATE_STATE_FROM_AWS,
    status,
  };
}

/**
 *
 * @param {*} param0
 */
export function setAgentACWState({ acwState, acwStateModifiedDate }: any) {
  return {
    type: AGENT_SET_ACW_STATE,
    acwState,
    acwStateModifiedDate,
  };
}

/**
 *
 * @param {*} param0
 */
export function resetAgentACWState() {
  return {
    type: RESET_AGENT_SET_ACW_STATE,
  };
}

/**
 *
 * @param {*} state
 */
export function setCompleteAgentState(state: any) {
  return {
    type: AGENT_SET,
    agent: state,
  };
}

export function updateAgentCannedResponses(body: CannedResponses) {
  return {
    type: AGENT_SET_CANNEDRESPONSES,
    cannedResponse: body,
  };
}

export function updateAgentQueueAssigned(body: any) {
  return {
    type: UPDATE_ASSINGED_QUEUE,
    queuesAssigned: body,
  };
}

export function updateAgentGeoLocationAction(body: any) {
  return {
    type: AGENT_UPDATE_GEO_LOCATION,
    geoLocation: body.geoLocation,
  };
}

export function updateAgentInfoCountry(body: { country: string }) {
  return {
    type: AGENT_UPDATE_COUNTRY,
    country: body.country,
  };
}
