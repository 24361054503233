import { combineReducers } from 'redux';
import call from './call';
import agent from './agent';
import history from './history';
import features from './features';
import session from './session';
import callback from './callback';
import webrtc from './webrtc';
import chat from './chat';
import cases from './case';
import common from './common';

export const rootReducer = combineReducers({
    call,
    agent,
    history,
    features,
    session,
    callback,
    webrtc,
    chat,
    cases,
    common,
});

