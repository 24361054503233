import { useEffect } from "react";
import { ProgressCircle } from "@adobe/react-spectrum";
import storeRTK from "../../store/storeRTK.ts";
import { initiateLogin } from "../../actions/session.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";

const LoginCallback = () => {
  let isLoginProcessing = useSelector(
    (state: RootState) => state.session.isLoginProcessing
  );
  useEffect(() => {
    storeRTK.dispatch(initiateLogin(true));
    location.href = "/";
    // });
  }, [isLoginProcessing]);

  return (
    <div
      style={{
        paddingTop: "15%",
        paddingLeft: "45%",
        position: "absolute",
        zIndex: "10",
      }}
    >
      <ProgressCircle aria-label="Loading…" isIndeterminate size="L" />
    </div>
  );
};

export default LoginCallback;
