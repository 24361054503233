import React, { useState } from "react";
import Emitter from "../../emitter";
import { Flex, Item, Key, Picker, Text } from "@adobe/react-spectrum";
import storeRTK from "../../store/storeRTK";
import './index.css';
import { ConnectInstance } from "../../types/agent.type";
import { STATE_CHANGE_INSTANCE } from "../../constants/events";

const MultiInstanceChange: React.FC = (props: any) => {
  const state = storeRTK.getState();
  const instanceList  = state.agent.instanceConfig || [];
  const mappedInstanceList: {name: string, id: string}[] = instanceList.map((instance: ConnectInstance) => {
    return {
      id: instance?.instance_alias,
      name: instance?.region
    }
  });
  const [currentInstance, setCurrentInstance] = useState<Key>(state.webrtc?.currentCcpInstance || instanceList?.find((i: ConnectInstance) => i.active)?.instance_alias);

  const selectRegionChange = (selectedRegion: any) => {
    setCurrentInstance(selectedRegion);
    Emitter.emit(STATE_CHANGE_INSTANCE, { body: {currentInstanceAlias: selectedRegion} });
  }

  return (
     <Flex justifyContent={"space-between"} UNSAFE_className="multiInstanceChangePicker">
      <Picker
        label={"Amazon Connect Region"}
        labelPosition="side"
        items={mappedInstanceList || []}
        selectedKey={currentInstance}
        onSelectionChange={(selected) => selectRegionChange(selected)}
        isDisabled={mappedInstanceList.length === 1 || state.call?.id || state.session.isReloginProcessing || state.session.isOnline === false}
      >
        {(item) => (
          <Item key={item?.id}>
            {/* <Text UNSAFE_style={{ fontSize: "0.785rem" }}> */}
             {item.name}
            {/* </Text> */}
          </Item>
        )}
      </Picker>
    </Flex>
  );
};

export default MultiInstanceChange;
