import { API_AGENT_CHANGE } from "../../constants/constants";
import { TELEMETRY_ERROR } from "../../constants/events";
import Emitter from "../../emitter";
import { LOG_PREFIX } from "../constants";
import { connectSession } from "../handlers/session";
const lp = LOG_PREFIX + "[api.agent.ts]: ";

export function changeAgentState(ev: any) {
  const prefix = `${lp} changeAgentState Handler: change agent state to ${ev.agentState}`;
  try {
    const agentState = ev.agentState || ev.body.agentState;
    // Streams API call to the first Offline state availale to the Agent
    // Logging results to the console and setting the DOM button UI to the 'Offline' state
    const stateObj = connectSession.agent
      .getAgentStates()
      .filter((st: any) => st.name === agentState)[0];
    connectSession.agent.setState(
      stateObj,
      {
        success: function (response: any) {
          console.log(
            `${prefix}: Succesfully set agent status via Streams`,
            agentState
          );
          return response;
        },
        failure: function (error: any) {
          console.log(
            `${prefix} : Error recieved in change agent state handler`,
            error
          );
          Emitter.emit(TELEMETRY_ERROR, {
            type: "notif",
            chan: TELEMETRY_ERROR,
            body: {
              type: API_AGENT_CHANGE,
              info:
                `${prefix} : Error recieved in change agent state handler` +
                error,
            },
          });
          ;
        },
      },
      { enqueueNextState: true }
    );
  } catch (error) {
    console.log(`${prefix}: Error in change agent state handler`, error);
  }
}
export const registerAgentEvents = () => {
  Emitter.on(API_AGENT_CHANGE, changeAgentState);
}
